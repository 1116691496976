import { SimpleGrid, Center } from "@mantine/core";
import React from "react";
import RiskRing from "./RiskRing";
import RiskStack from "./RiskStack";

export interface RiskBreakdownProps {
  breakdown: number[];
  onSectionClicked: (riskLevel: number) => void;
}

const RiskBreakdown = ({ breakdown, onSectionClicked }: RiskBreakdownProps) => {
  return (
    <SimpleGrid cols={2} spacing="xs">
      <Center>
        <RiskRing breakdown={breakdown} onSectionClicked={onSectionClicked} />
      </Center>
      <Center>
        <RiskStack breakdown={breakdown} onSectionClicked={onSectionClicked} />
      </Center>
    </SimpleGrid>
  );
};

export default RiskBreakdown;
