import {
  ActionIcon,
  Badge,
  Card,
  Center,
  Container,
  Group,
  Image,
  Loader,
  Menu,
  SimpleGrid,
  Stack,
  Text,
  UnstyledButton,
  useMantineTheme,
} from "@mantine/core";
import { openConfirmModal } from "@mantine/modals";
import { showNotification } from "@mantine/notifications";
import {
  IconDots,
  IconPlug,
  IconPlugConnected,
  IconPlugConnectedX,
} from "@tabler/icons";
import { QueryClient, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { SalesforceApi, SlackApi } from "../../api/generated";
import { requestAccessSalesforce } from "../../misc/toolkit_salesforce";
import { requestAccessSlack } from "../../misc/toolkit_slack";
import { useAccessStore } from "../../state/store";
import {
  demoGithubInstances,
  demoInstances,
  demoMode,
  demoSlackInstances,
} from "../../tooling/demo";
import Oops from "../common/Oops";
import { ProductLogoAssets } from "../common/ProductLogoAssets";

interface MainAppProps {
  id: string;
  name: string;
  logo: any;
  comingSoon?: boolean;
  to?: string;
  organization?: string;
  organizationCodename?: string;
  organizationType?: string;
  instanceId?: string;
  connected: boolean;
  onAccessRequest?: () => void;
  onDisconnectRequest: (
    instanceId: string,
    orgName: string,
    queryClient: QueryClient
  ) => void;
}

const openSalesforceDisconnectModal = (
  instanceId: string,
  orgName: string,
  queryClient: QueryClient
) =>
  openConfirmModal({
    title: `Disconnect Salesforce (${orgName})?`,
    radius: "md",
    centered: true,
    children: (
      <Text size="sm">
        Are you sure you want to disconnect from this Salesforce instance?
        <br />
        <br />
        Upon disconnecting, Vantyr will remove all collected Salesforce data and
        accumulated integrations metrics.
      </Text>
    ),
    labels: { confirm: "Disconnect", cancel: "Cancel" },
    confirmProps: { color: "red" },
    onConfirm: () => {
      new SalesforceApi()
        .disconnect(instanceId!)
        .then(() => {
          queryClient.invalidateQueries({
            queryKey: ["available_instances_menu", "available_instances_apps"],
          });
          showNotification({
            title: `Salesforce (${orgName}) disconnected successfully`,
            message:
              "All Salesforce data from this instance has been removed from Vantyr.",
            color: "green",
          });
        })
        .catch((err) => {
          showNotification({
            title: `Salesforce (${orgName}) disconnect failed!`,
            message: err.message,
            color: "red",
          });
        });
    },
    onCancel: () => {},
  });

const openSlackDisconnectModal = (
  instanceId: string,
  orgName: string,
  queryClient: QueryClient
) =>
  openConfirmModal({
    title: `Disconnect Slack (${orgName})?`,
    radius: "md",
    centered: true,
    children: (
      <Text size="sm">
        Are you sure you want to disconnect from this Slack instance?
        <br />
        <br />
        Upon disconnecting, Vantyr will remove all collected Slack data and
        accumulated integrations metrics.
      </Text>
    ),
    labels: { confirm: "Disconnect", cancel: "Cancel" },
    confirmProps: { color: "red" },
    onConfirm: () => {
      new SlackApi()
        .slackDisconnect(instanceId!)
        .then(() => {
          queryClient.invalidateQueries({
            queryKey: ["available_instances_menu", "available_instances_apps"],
          });
          showNotification({
            title: `Slack (${orgName}) disconnected successfully`,
            message:
              "All Slack data from this instance has been removed from Vantyr.",
            color: "green",
          });
        })
        .catch((err) => {
          showNotification({
            title: `Slack (${orgName}) disconnect failed!`,
            message: err.message,
            color: "red",
          });
        });
    },
    onCancel: () => {},
  });

const appsList: MainAppProps[] = [
  {
    id: "salesforce",
    name: "Salesforce",
    logo: ProductLogoAssets.get("salesforce"),
    connected: false,
    onAccessRequest: requestAccessSalesforce,
    onDisconnectRequest: openSalesforceDisconnectModal,
  },
  {
    id: "slack",
    name: "Slack",
    logo: ProductLogoAssets.get("slack"),
    connected: false,
    onAccessRequest: requestAccessSlack,
    onDisconnectRequest: (instanceId, orgName, queryClient) =>
      openSlackDisconnectModal(instanceId, orgName, queryClient),
  },
  {
    id: "o365",
    name: "Microsoft 365",
    logo: ProductLogoAssets.get("o365"),
    comingSoon: true,
    connected: false,
    onDisconnectRequest: () => {},
  },
  {
    id: "google",
    name: "GWorkspace",
    logo: ProductLogoAssets.get("google"),
    comingSoon: true,
    connected: false,
    onDisconnectRequest: () => {},
  },
  {
    id: "github",
    name: "GitHub",
    logo: ProductLogoAssets.get("github"),
    comingSoon: true,
    connected: false,
    onDisconnectRequest: () => {},
  },
  {
    id: "jira",
    name: "Jira",
    logo: ProductLogoAssets.get("jira"),
    comingSoon: true,
    connected: false,
    onDisconnectRequest: () => {},
  },
];

const ScreenApplications = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const appsToConnect = demoMode
    ? appsList.filter(
        (app) =>
          app.id !== "salesforce" && app.id !== "slack" && app.id !== "github"
      )
    : appsList;

  const loggedInUserRole = useAccessStore((state) => state.userRole);
  const loggedInUserIsNotAdmin = loggedInUserRole === "Viewer";

  const theme = useMantineTheme();

  // useQuery to fetch connected instances
  const { isError, error, isLoading, data } = useQuery(
    "available_instances_apps",
    async () => {
      if (demoMode) {
        const demoSfInstances: MainAppProps[] = demoInstances.map(
          (instance) => {
            return {
              id: instance.instanceId,
              name: "Salesforce",
              logo: ProductLogoAssets.get("salesforce"),
              to: "/applications/salesforce/" + instance.instanceId,
              organization: instance.orgName,
              organizationCodename: instance.orgNamespacePrefix,
              organizationType: instance.orgType,
              instanceId: instance.instanceId,
              connected: instance.connected,
            };
          }
        ) as MainAppProps[];

        const demoSlInstances: MainAppProps[] = demoSlackInstances.map(
          (instance) => {
            return {
              id: instance.instanceId,
              name: "Slack",
              logo: ProductLogoAssets.get("slack"),
              to: "/applications/slack/" + instance.instanceId,
              organization: instance.enterpriseName,
              organizationCodename: instance.enterpriseId,
              organizationType: instance.billingPlan,
              instanceId: instance.instanceId,
              connected: instance.isConnected,
            };
          }
        ) as MainAppProps[];

        const demoGhInstances: MainAppProps[] = demoGithubInstances.map(
          (instance) => {
            return {
              id: instance.instanceId,
              name: "GitHub",
              logo: ProductLogoAssets.get("github"),
              to: "/applications/github/" + instance.instanceId,
              organization: instance.enterpriseName,
              organizationCodename: instance.enterpriseId,
              organizationType: instance.billingPlan,
              instanceId: instance.instanceId,
              connected: instance.isConnected,
            };
          }
        ) as MainAppProps[];

        return [...demoSfInstances, ...demoSlInstances, ...demoGhInstances];
      }

      const rspSalesforce = await new SalesforceApi().listInstances();
      const rspSlack = await new SlackApi().slackListInstances();

      const connectedInstances = rspSalesforce.data.map((instance) => {
        return {
          id: instance.instanceId,
          name: "Salesforce",
          logo: ProductLogoAssets.get("salesforce"),
          to: "/applications/salesforce/" + instance.instanceId,
          organization: instance.orgName,
          organizationCodename: instance.orgNamespacePrefix,
          organizationType: instance.orgType,
          instanceId: instance.instanceId,
          connected: instance.connected,
          onDisconnectRequest: openSalesforceDisconnectModal,
        } as MainAppProps;
      }) as MainAppProps[];

      const connectedSlackInstances = rspSlack.data.map((instance) => {
        return {
          id: instance.instanceId,
          name: "Slack",
          logo: ProductLogoAssets.get("slack"),
          to: "/applications/slack/" + instance.instanceId,
          organization: instance.enterpriseName,
          organizationCodename: instance.enterpriseDomain,
          organizationType: instance.billingPlan,
          instanceId: instance.instanceId,
          connected: instance.isConnected,
          onDisconnectRequest: openSlackDisconnectModal,
        };
      }) as MainAppProps[];

      return [...connectedInstances, ...connectedSlackInstances];
    }
  );

  // if loading
  if (isLoading) {
    return (
      <Center style={{ width: "100%", height: "100%" }}>
        <Loader color="gray" variant="dots"></Loader>
      </Center>
    );
  }

  // if error
  if (isError || error) {
    return <Oops />;
  }

  const openOrConnectToInstance = async (instance: MainAppProps) => {
    if (instance.connected) {
      navigate(instance.to!);
    } else {
      instance.onAccessRequest!();
    }
  };

  const connectedInstances = data;

  return (
    <Container m="xl" style={{ minWidth: 400, maxWidth: "80%", width: "auto" }}>
      {!connectedInstances || connectedInstances.length === 0 ? (
        <></>
      ) : (
        <>
          <Group position="left">
            <IconPlugConnected
              color="green"
              size={24}
              stroke={1}
            ></IconPlugConnected>
            <Text align="left" weight={500}>
              Monitored SaaS apps
            </Text>
          </Group>
          <SimpleGrid
            cols={8}
            spacing={80}
            verticalSpacing={40}
            style={{ tableLayout: "fixed" }}
            px="xs"
            py="xl"
            breakpoints={[
              { maxWidth: 3600, cols: 7, spacing: 80 },
              { maxWidth: 3200, cols: 6, spacing: 80 },
              { maxWidth: 2600, cols: 5, spacing: 80 },
              { maxWidth: 1800, cols: 4, spacing: 80 },
              { maxWidth: 1600, cols: 3, spacing: 30 },
              { maxWidth: 1300, cols: 2, spacing: 30 },
              { maxWidth: 1000, cols: 1, spacing: 30 },
            ]}
          >
            {connectedInstances.map((instance) => {
              return (
                <Card shadow="xl" p="lg" style={{ width: 240 }}>
                  <Card.Section inheritPadding pt="xs" px="sm">
                    <Group position="apart">
                      <Text size="sm" weight={400}>
                        {instance.name}
                      </Text>
                      <Badge color="green.7" variant="light">
                        Connected
                      </Badge>
                    </Group>
                  </Card.Section>
                  <UnstyledButton
                    onClick={() => openOrConnectToInstance(instance)}
                    style={{ width: "100%" }}
                  >
                    <Card.Section>
                      <Image
                        src={instance.logo}
                        height={120}
                        alt={instance.name}
                        fit="contain"
                        p="xl"
                      />
                    </Card.Section>
                  </UnstyledButton>
                  <Card.Section>
                    <Stack spacing={0} px="md" pb="xs">
                      <Text size="xs" weight={400} truncate>
                        {instance.organization}
                      </Text>
                      <Group position="apart">
                        <Text size="xs">{instance.organizationType}</Text>
                        <Menu
                          withinPortal
                          position="right-start"
                          shadow="xl"
                          transitionProps={{
                            transition: "pop-top-right",
                          }}
                          offset={20}
                          width={224}
                          withArrow
                        >
                          <Menu.Target>
                            <ActionIcon>
                              <IconDots size={16} />
                            </ActionIcon>
                          </Menu.Target>
                          <Menu.Dropdown>
                            <Menu.Item
                              icon={<IconPlug size={16} stroke={1} />}
                              onClick={() => openOrConnectToInstance(instance)}
                            >
                              Integrations
                            </Menu.Item>
                            <Menu.Divider />
                            <Menu.Item
                              onClick={() => {
                                instance.onDisconnectRequest(
                                  instance.instanceId!,
                                  instance.organization!,
                                  queryClient
                                );
                              }}
                              icon={<IconPlugConnectedX size={16} stroke={1} />}
                              color="red"
                              disabled={loggedInUserIsNotAdmin}
                            >
                              Disconnect from Vantyr
                            </Menu.Item>
                          </Menu.Dropdown>
                        </Menu>
                      </Group>
                    </Stack>
                  </Card.Section>
                </Card>
              );
            })}
          </SimpleGrid>
        </>
      )}
      <Group
        position="left"
        mt={connectedInstances && connectedInstances.length > 0 ? 40 : "md"}
      >
        <IconPlug color={theme.primaryColor} size={24} stroke={1}></IconPlug>
        <Text align="left" weight={500}>
          Available SaaS apps
        </Text>
      </Group>
      <SimpleGrid
        cols={8}
        spacing={80}
        verticalSpacing={40}
        style={{ tableLayout: "fixed" }}
        px="xs"
        py="xl"
        breakpoints={[
          { maxWidth: 3600, cols: 7, spacing: 80 },
          { maxWidth: 3200, cols: 6, spacing: 80 },
          { maxWidth: 2600, cols: 5, spacing: 80 },
          { maxWidth: 1800, cols: 4, spacing: 80 },
          { maxWidth: 1600, cols: 3, spacing: 30 },
          { maxWidth: 1300, cols: 2, spacing: 30 },
          { maxWidth: 1000, cols: 1, spacing: 30 },
        ]}
      >
        {appsToConnect.map((app) => {
          return (
            <Card shadow="xl" p="lg" style={{ width: 240 }}>
              <Card.Section inheritPadding py="xs" px="sm">
                <Group position="apart">
                  <Text size="sm" weight={400}>
                    {app.name}
                  </Text>
                  {app.comingSoon ? (
                    <Badge color={"gray.7"}>Coming Soon</Badge>
                  ) : (
                    <Badge color="orange.7">Connect</Badge>
                  )}
                </Group>
              </Card.Section>
              <Card.Section
                style={
                  app.comingSoon || loggedInUserIsNotAdmin
                    ? { pointerEvents: "none" }
                    : {}
                }
              >
                {!app.comingSoon ? (
                  <UnstyledButton
                    onClick={app.onAccessRequest}
                    style={{ width: "100%" }}
                    disabled={loggedInUserIsNotAdmin}
                  >
                    <Image
                      src={app.logo}
                      height={120}
                      alt={app.name}
                      fit="contain"
                      p="xl"
                      pb={50}
                    />
                  </UnstyledButton>
                ) : (
                  <Image
                    src={app.logo}
                    height={120}
                    alt={app.name}
                    fit="contain"
                    p="xl"
                    pb={50}
                  />
                )}
              </Card.Section>
            </Card>
          );
        })}
      </SimpleGrid>
    </Container>
  );
};

export default ScreenApplications;
