import { Badge, Group, Stack, Text, UnstyledButton } from "@mantine/core";
import { riskColorAlt, riskLabel } from "../../misc/toolkit_salesforce";
import { RiskBreakdownProps } from "./RiskBreakdown";

const RiskStack = ({ breakdown, onSectionClicked }: RiskBreakdownProps) => {
  return (
    <Stack justify="space-around">
      {breakdown
        .map((r, i) => (
          <Group position="right">
            <Text weight={500} size="md" color={riskColorAlt(i)}>
              {riskLabel(i)}
            </Text>
            <UnstyledButton onClick={() => onSectionClicked(i)}>
              <Badge
                color={riskColorAlt(i)}
                size="lg"
                radius="xl"
                variant="filled"
                style={{ width: "80px" }}
              >
                {r}
              </Badge>
            </UnstyledButton>
          </Group>
        ))
        .reverse()}
    </Stack>
  );
};

export default RiskStack;
