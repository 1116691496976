import {
  RingProgress,
  Stack,
  UnstyledButton,
  Title,
  Text,
} from "@mantine/core";
import React from "react";
import { riskColor } from "../../misc/toolkit_salesforce";
import { RiskBreakdownProps } from "./RiskBreakdown";

const RiskRing = ({ breakdown, onSectionClicked }: RiskBreakdownProps) => {
  const itemsTotal = breakdown.reduce((acc, c) => acc + c, 0);
  console.log(breakdown);
  return (
    <RingProgress
      size={200}
      thickness={16}
      roundCaps
      sections={breakdown
        .map((r, i) => ({
          value: (r * 100) / itemsTotal,
          color: riskColor(i),
          style: { cursor: "pointer" },
          onClick: () => onSectionClicked(i),
        }))
        .slice(1, 3)
        .filter((r) => r.value !== 0)
        .reverse()}
      label={
        <Stack spacing={0} align="center">
          <UnstyledButton onClick={() => onSectionClicked(-1)}>
            <Title order={2} align="center">
              {itemsTotal}
            </Title>
          </UnstyledButton>
          <Text size="sm" align="center" color="dimmed">
            integrations
          </Text>
        </Stack>
      }
    />
  );
};

export default RiskRing;
