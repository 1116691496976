import {
  Avatar,
  Button,
  Center,
  Container,
  createStyles,
  Group,
  Menu,
  PasswordInput,
  Select,
  Text,
  TextInput,
  ThemeIcon,
  UnstyledButton,
  UnstyledButtonProps,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { closeAllModals, openModal } from "@mantine/modals";
import { showNotification } from "@mantine/notifications";
import { IconFaceIdError, IconLogout, IconSettings } from "@tabler/icons";
import { AxiosError } from "axios";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { AccessApi, UserRecord } from "../../api/generated";
import { colorAvatar } from "../../misc/mixins_mantine";
import { useAccessStore } from "../../state/store";
import { demoMode, demoUser } from "../../tooling/demo";

const useStyles = createStyles((theme) => ({
  user: {
    color: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.black,
    padding: `${theme.spacing.xs}px`,
    borderRadius: theme.radius.md,
    transition: "background-color 100ms ease",

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[8]
          : theme.colors.gray[1],
    },

    [theme.fn.smallerThan("xs")]: {
      display: "none",
    },
  },

  userActive: {
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[8]
        : theme.colors.gray[1],
  },
}));

interface UserButtonProps extends UnstyledButtonProps {
  signOut: () => void;
}

export function UserButton({ signOut, ...others }: UserButtonProps) {
  const { classes, theme, cx } = useStyles();
  const [userMenuOpened, setUserMenuOpened] = useState(false);

  const userId = useAccessStore((state) => state.userId);

  // Modal for editing a user
  const openEditUserModal = (user: UserRecord) =>
    openModal({
      title: "Edit Vantyr user",
      centered: true,
      overlayProps: {
        color:
          theme.colorScheme === "dark"
            ? theme.colors.dark[9]
            : theme.colors.gray[2],
        opacity: 0.55,
        blur: 3,
      },
      children: <EditUserForm user={user}></EditUserForm>,
    });

  // useQuery to fetch users
  const { isError, error, isLoading, data } = useQuery(
    "userLoggedIn",
    async () => {
      // if (demoMode) {
      //   return demoUser;
      // }

      const rsp = await new AccessApi().getUser(userId!);
      return rsp.data;
    }
  );

  // if loading
  if (isLoading) {
    return <div>Loading...</div>;
  }

  // if error
  if (isError || error) {
    return (
      <Center>
        <ThemeIcon variant="light" radius="xl" color="gray">
          <IconFaceIdError stroke={1} />
        </ThemeIcon>
      </Center>
    );
  }

  // if all OK
  const name = data?.fullName || "User";
  const email = data?.email || "email";
  return (
    <Menu
      width="target"
      position="top"
      withArrow
      transitionProps={{
        transition: "pop-top-right",
      }}
      shadow="xl"
      onClose={() => setUserMenuOpened(false)}
      onOpen={() => setUserMenuOpened(true)}
    >
      <Menu.Target>
        <UnstyledButton
          className={cx(classes.user, {
            [classes.userActive]: userMenuOpened,
          })}
          style={{ width: "100%" }}
        >
          <Container fluid px="xs" py="xs" style={{ width: "100%" }}>
            <Group position="left" spacing="sm">
              <Avatar radius="xl" {...colorAvatar(name || " ")} size="md" />
              <div style={{ flex: 1, width: "140px" }}>
                <div style={{ width: "calc(100%)" }}>
                  <Text
                    sx={{ lineHeight: 1 }}
                    align="left"
                    style={{ textOverflow: "ellipsis" }}
                    truncate
                  >
                    {name}
                  </Text>
                </div>
                <div style={{ width: "calc(100%)" }}>
                  <Text
                    color="dimmed"
                    size="sm"
                    sx={{ lineHeight: 1 }}
                    align="left"
                    style={{ textOverflow: "ellipsis" }}
                    truncate
                  >
                    {email}
                  </Text>
                </div>
              </div>
            </Group>
          </Container>
        </UnstyledButton>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item
          onClick={() => {
            openEditUserModal(data!);
          }}
          icon={<IconSettings size={16} stroke={1} />}
        >
          Account settings
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item icon={<IconLogout size={16} stroke={1} />} onClick={signOut}>
          Logout
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
}

export function EditUserForm(props: { user: UserRecord }) {
  const queryClient = useQueryClient();
  const { user } = props;

  const editUserForm = useForm({
    initialValues: user,
  });

  const { isLoading, isError, mutate } = useMutation(
    "registerUser",
    async (values: UserRecord) => {
      await new AccessApi().updateUser(user.id!, values);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["users"] });
        queryClient.invalidateQueries({ queryKey: ["userLoggedIn"] });
        closeAllModals();
      },
      onError: (error) => {
        console.log(error);
        queryClient.invalidateQueries({ queryKey: ["users"] });
        queryClient.invalidateQueries({ queryKey: ["userLoggedIn"] });
        closeAllModals();
        showNotification({
          title: "Oops!",
          message: `Something went wrong! ${
            (error as unknown as AxiosError)?.message
          }`,
        });
      },
    }
  );

  if (isLoading) {
    return <div>Updating user ...</div>;
  }

  if (isError) {
    return <>Error</>;
  }

  return (
    <form
      autoComplete="off"
      onSubmit={editUserForm.onSubmit((values) => mutate(values))}
      onReset={() => closeAllModals()}
    >
      <TextInput
        label="Full name"
        placeholder="User's full name"
        required
        my="md"
        radius="xl"
        {...editUserForm.getInputProps("fullName")}
      />
      <TextInput
        label="Email"
        placeholder="User's email"
        required
        my="md"
        radius="xl"
        {...editUserForm.getInputProps("email")}
      />
      <PasswordInput
        autoComplete="new-password"
        label="Password"
        placeholder="User's password"
        description="Password must be at least 6 characters long"
        my="md"
        radius="xl"
        {...editUserForm.getInputProps("password")}
      />
      <Select
        label="Role"
        placeholder="Select a role"
        required
        data={["Admin", "Viewer"]}
        mt="md"
        mb="xl"
        description="Viewers can not manage users and connect SaaS instances."
        radius="xl"
        {...editUserForm.getInputProps("role")}
        disabled
      />
      <Group position="right">
        <Button type="reset" variant="default" color="gray" radius="xl">
          Cancel
        </Button>
        <Button type="submit" variant="outline" radius="xl">
          Update
        </Button>
      </Group>
    </form>
  );
}
