// Description: Useful functions for mixing in capabilities with Mantine

import { Card, Text } from "@mantine/core";
import { forwardRef } from "react";
import Loading from "../components/common/Loading";
import Oops from "../components/common/Oops";

const MANTINE_COLORS = [
  "orange",
  "red",
  "grape",
  "indigo",
  "teal",
  "green",
  "pink",
  "cyan",
  "violet",
  "yellow",
  "blue",
  "lime",
  "gray",
];
// "dark",

const MANTINE_COLORS_LENGTH = MANTINE_COLORS.length;

export function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }
  /* eslint-enable no-bitwise */

  return MANTINE_COLORS[Math.abs(hash) % MANTINE_COLORS_LENGTH];
}

export function colorAvatar(name: string, removed = false) {
  let surnameIndex = name.indexOf(" ") + 1;
  if (surnameIndex === 0) {
    surnameIndex = -1;
  }
  const colorShade = removed ? ".3" : "";
  const color = stringToColor(name) + colorShade;
  return {
    color: color,
    variant: "light" as "filled" | "light" | "outline" | "gradient" | undefined,
    children:
      `${name?.charAt(0).toUpperCase()}${name
        ?.charAt(surnameIndex)
        .toUpperCase()}` || "?",
  };
}

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  label: string;
  description: string;
}

export const DefaultSelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ label, description, ...others }: ItemProps, ref) => (
    <Card p={0} m={0} radius="md">
      <div ref={ref} {...others}>
        <Text size="sm">{label}</Text>
        <Text size="xs" opacity={0.65}>
          {description}
        </Text>
      </div>
    </Card>
  )
);

export const DefaultLoadingAndError = ({
  isLoading,
  isError,
  error,
}: {
  isLoading: boolean;
  isError: boolean;
  error: any;
}) => (isLoading ? <Loading /> : isError || error ? <Oops /> : null);
