import { format } from "fecha";
import { DISPLAY_DATETIME_FORMAT } from "../constants/constants";
import { SlackIntegration } from "./toolkit_slack";
import { ProductLogoAssets } from "../components/common/ProductLogoAssets";
import { IconCloudOff, IconSquareRoundedLetterX } from "@tabler/icons";
import { Image } from "@mantine/core";

export const formatBytes = (bytes: number, decimals = 1) => {
  if (!+bytes) return "-";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const bytesToMagnitude = (bytes: number) => {
  // 1 MB = 1048576 bytes
  if (bytes < 1048576) {
    return 0;
  }

  // 100 MB = 104857600 bytes
  if (bytes < 104857600) {
    return 1;
  }

  // 1 GB = 1073741824 bytes
  if (bytes < 1073741824) {
    return 2;
  }

  return 3;
};

export const periodAsText = (days: number) => {
  if (days === 1) {
    return "1 day";
  }

  if (days < 30) {
    return `${days} days`;
  }

  if (days < 365) {
    const months = Math.floor(days / 30);
    if (months === 1) {
      return "1 month";
    }
    return `${months} months`;
  }

  const years = Math.floor(days / 365);
  const months = Math.floor((days % 365) / 30);

  if (years === 1 && months === 0) {
    return "1 year";
  }

  if (months === 1) {
    return `${years} years and 1 month`;
  }

  return `${years} years and ${months} months`;
};

export const daysSinceTs = (ts: number) =>
  Math.floor((Date.now() / 1000 - ts) / (60 * 60 * 24));

export const periodSinceTs = (ts: number) => periodAsText(daysSinceTs(ts));

export const formatUnixTs = (ts: number) =>
  format(new Date(ts * 1000), DISPLAY_DATETIME_FORMAT);

export function fetchImageSrc(integration: SlackIntegration | any) {
  return (
    integration.logoUrl ||
    integration.iconUrl ||
    ProductLogoAssets.get(
      integration.instanceType ?? integration.label
        ? integration.label.toLowerCase()
        : integration.appName && integration.appName!.toLowerCase()
    )
  );
}

interface AppLogoProps {
  integration: any;
  width?: number;
  height?: number;
}

export function AppLogo({ integration, width, height }: AppLogoProps) {
  const imageSrc = fetchImageSrc(integration);
  return !imageSrc ? (
    <IconSquareRoundedLetterX stroke={0.7} color="gray" size={width} />
  ) : (
    <Image
      src={imageSrc}
      fit="contain"
      width={width}
      height={height}
      style={{ backgroundColor: "#ffffff" }}
      withPlaceholder
      placeholder={<IconCloudOff stroke={0.7} size={width} />}
    />
  );
}
