import { useQuery } from "react-query";
import { SalesforceApi, SlackApi } from "../api/generated";

import { DefaultLoadingAndError } from "./mixins_mantine";
import {
  ActionIcon,
  Center,
  CopyButton,
  Paper,
  ScrollArea,
  SimpleGrid,
  Text,
  Tooltip,
  useMantineTheme,
} from "@mantine/core";
import { IconCheck, IconCopy } from "@tabler/icons";
import { useConfigStore } from "../state/store";

const SfDataPull = () => {
  const theme = useMantineTheme();

  const dataPullMode = useConfigStore((state) => state.dataPullMode);

  const { isError, error, isLoading, data } = useQuery(
    `sf_pull`,
    async () => {
      if (!dataPullMode) {
        return;
      }
      const instanceId = (await new SalesforceApi().listInstances()).data[0]
        .instanceId;
      const sfFullRecord = (
        await new SalesforceApi().listIntegrations(instanceId!)
      ).data;

      const slackInstanceId = (await new SlackApi().slackListInstances())
        .data[0].instanceId;
      const slackFullRecord = (
        await new SlackApi().slackListIntegrations(slackInstanceId!)
      ).data;

      return [sfFullRecord, slackFullRecord];
    },
    {
      staleTime: 1000 * 1000,
      refetchInterval: 1000 * 1000,
    }
  );

  if (!dataPullMode) {
    return <></>;
  }

  if (!data) {
    return DefaultLoadingAndError({ isLoading, isError, error });
  }

  const dataPretty = [
    JSON.stringify(data[0], null, 2),
    JSON.stringify(data[1], null, 2),
  ];

  return (
    <Center style={{ width: "100%", height: "100%" }}>
      <SimpleGrid cols={2}>
        {dataPretty.map((dataPretty) => (
          <Paper
            style={{ width: "95%", height: "95%" }}
            p="xl"
            m="xl"
            shadow="xl"
          >
            <ScrollArea style={{ height: "620px" }}>
              <Paper
                style={
                  theme.colorScheme === "dark"
                    ? { backgroundColor: "#141517" }
                    : { backgroundColor: "#F8F9FA" }
                }
                p="xl"
              >
                <Text size="xs">
                  <pre>{dataPretty}</pre>
                </Text>
              </Paper>
            </ScrollArea>
            <Center mt="xl">
              <CopyButton value={dataPretty} timeout={2000}>
                {({ copied, copy }) => (
                  <Tooltip
                    label={copied ? "Copied" : "Copy"}
                    withArrow
                    position="right"
                  >
                    <ActionIcon
                      color={copied ? "teal" : "teal"}
                      onClick={copy}
                      radius="xl"
                      size="xl"
                      variant="default"
                    >
                      {copied ? (
                        <IconCheck size="2rem" stroke={1} />
                      ) : (
                        <IconCopy size="2rem" stroke={1} />
                      )}
                    </ActionIcon>
                  </Tooltip>
                )}
              </CopyButton>
            </Center>
          </Paper>
        ))}
      </SimpleGrid>
    </Center>
  );
};

export default SfDataPull;
