import { Col, Container, Grid, Group } from "@mantine/core";
import { useDebouncedState } from "@mantine/hooks";
import { useState } from "react";
import { SlackInstanceActivityRecord } from "../../api/generated";
import SearchInput from "../common/SearchInput";
import FilterSlackInstances from "./FilterSlackInstances";
import SlackInstanceSummary from "./SlackInstanceSummary";
import { TableSlackIntegrations } from "./TableIntegrationsSlack";

interface ViewSlackInstanceProps {
  instance: SlackInstanceActivityRecord;
  narrowView?: boolean;
}

const ViewSlackInstance = ({
  instance,
  narrowView,
}: ViewSlackInstanceProps) => {
  const [filterConditions, setFilterConditions] = useState<string[]>([]);
  const [searchCondition, setSearchCondition] = useDebouncedState<string>(
    "",
    200
  );

  const [activePage, setActivePage] = useState(1);

  const onSearch = (searchCondition: string) => {
    setActivePage(1);
    setSearchCondition(searchCondition);
  };

  const onFilter = (filterConditions: string[]) => {
    setActivePage(1);
    setFilterConditions(filterConditions);
  };

  return (
    <Container px="md" fluid>
      <Container pb="md" fluid>
        <Grid>
          <Col span="content">
            <SlackInstanceSummary instance={instance!} />
          </Col>
          <Col>
            <Group position="apart">
              <SearchInput
                onSearch={(searchCondition) => onSearch(searchCondition)}
              />
              <FilterSlackInstances
                onFilter={(filterConditions) => onFilter(filterConditions)}
              />
            </Group>
          </Col>
        </Grid>
      </Container>
      <TableSlackIntegrations
        slackFullRecord={instance}
        filterConditions={filterConditions}
        searchCondition={searchCondition}
        activePage={activePage}
        setActivePage={setActivePage}
        narrowView={narrowView}
      />
    </Container>
  );
};

export default ViewSlackInstance;
