import {
  IconBoxMargin,
  IconBrandSlack,
  IconCheck,
  IconExclamationMark,
  IconHome2,
  IconPlugConnected,
  IconPlugConnectedX,
  IconShieldCheck,
  IconShieldChevron,
  IconShieldX,
  IconUrgent,
} from "@tabler/icons";
import { formatUnixTs } from "../../misc/toolkit_common";
import {
  SlackIntegration,
  slackIntegrationIsByVantyr,
} from "../../misc/toolkit_slack";
import Summary from "../common/Summary";

interface SummarySlackIntegrationProps {
  integration: SlackIntegration;
  linkAction?: () => void;
}

const labelByRisk = ["Low Risk", "Medium Risk", "High Risk", "Urgent Risk"];
const colorByRisk = ["gray", "orange", "red", "pink"];
const iconByRisk = [
  IconShieldCheck,
  IconShieldChevron,
  IconShieldX,
  IconUrgent,
];

export const SummarySlackIntegrationRisk = ({
  integration,
  linkAction,
}: SummarySlackIntegrationProps) =>
  slackIntegrationIsByVantyr(integration) ? (
    <Summary
      icon={IconCheck}
      label="Risk not assessed"
      subtext="Vantyr developed integrations are not assessed for risk scoring"
      color="gray"
      size="md"
    />
  ) : (
    <Summary
      icon={iconByRisk[integration.riskLevel]}
      label={labelByRisk[integration.riskLevel]}
      subtext="Based on granted permission level"
      color={colorByRisk[integration.riskLevel]}
      size="md"
      linkLabel="See permissions"
      linkAction={linkAction}
    />
  );

export const SummarySlackIntegrationApproved = ({
  integration,
}: SummarySlackIntegrationProps) => {
  return !integration.approvedBy ? (
    <Summary
      icon={IconExclamationMark}
      label="Shadow integration"
      subtext="This app has not been approved by a Slack admin"
      color="pink.6"
      size="md"
    />
  ) : (
    <Summary
      icon={IconCheck}
      label={`${integration.appName} is approved organization-wide`}
      subtext={`Approved by ${integration.approvedBy.realName} on ${
        formatUnixTs(integration.createdOn) || " - "
      }`}
      color="green.6"
      size="md"
    />
  );
};

export const SummarySlackIntegrationActive = ({
  integration,
  linkAction,
}: SummarySlackIntegrationProps) => {
  const usersCount = integration.activeUsers.length;
  const teamsCount = integration.teams.length;
  const usersStr = usersCount === 1 ? "user" : "users";
  const teamsStr = teamsCount === 1 ? "workspace" : "workspaces";
  return integration.activeUsers.length !== 0 ? (
    <Summary
      icon={IconPlugConnected}
      label={`${usersCount} active ${usersStr}`}
      subtext={`Accross ${teamsCount} Slack ${teamsStr}`}
      color="orange"
      size="md"
      linkLabel="See Integration Events"
      linkAction={linkAction}
    />
  ) : (
    <Summary
      icon={IconPlugConnectedX}
      label="Not active in any workspace"
      subtext={`No user is actively using ${integration.appName} in any workspace`}
      color="gray"
      size="md"
    />
  );
};

export const SummarySlackIntegrationNoScopes = ({
  integration,
}: SummarySlackIntegrationProps) =>
  !integration.scopes || integration.scopes.length === 0 ? (
    <Summary
      icon={IconExclamationMark}
      label="Unknown permissions"
      subtext={`Unable to determine the permissions granted to ${integration.appName}`}
      color="pink.6"
      size="md"
    />
  ) : null;

export const SummarySlackIntegrationInfo = ({
  integration,
}: SummarySlackIntegrationProps) =>
  integration.approvedAppRecord ? (
    <Summary
      icon={
        integration.isAppDirectoryApproved
          ? IconBrandSlack
          : integration.isInternal
          ? IconHome2
          : IconBoxMargin
      }
      label={`${
        integration.isAppDirectoryApproved
          ? "Installed from Slack app directory"
          : integration.isInternal
          ? "Internal application"
          : "Third-party application"
      }`}
      subtext={
        integration.approvedAppRecord.description
          ? `Description : ${integration.approvedAppRecord.description}`
          : "No description provided"
      }
      color={
        integration.isAppDirectoryApproved
          ? "teal"
          : integration.isInternal
          ? "gray"
          : "orange"
      }
      size="md"
      linkLabel="App homepage"
      linkAction={() =>
        window.open(
          `${integration.approvedAppRecord?.appHomepageUrl}`,
          "_blank"
        )
      }
      maxWidth="35rem"
    />
  ) : null;
