export const ProductLogoAssets = new Map([
  ["github", require("../../assets/logos/github.png")],
  ["google", require("../../assets/logos/google.avif")],
  ["hubspot", require("../../assets/logos/hubspot.png")],
  ["jira", require("../../assets/logos/jira.png")],
  ["vantyr", require("../../assets/logos/vantyr.png")],
  ["monday", require("../../assets/logos/monday.png")],
  ["salesforce", require("../../assets/logos/salesforce.png")],
  ["servicenow", require("../../assets/logos/servicenow.png")],
  ["slack", require("../../assets/logos/slack.png")],
  ["trello", require("../../assets/logos/trello.png")],
  ["zoom", require("../../assets/logos/zoom.png")],
  ["mailchimp", require("../../assets/logos/mailchimp.png")],
  ["docusign", require("../../assets/logos/docusign.png")],
  ["apollo", require("../../assets/logos/apollo.avif")],
  ["Apollo", require("../../assets/logos/apollo.avif")],
  ["microsoft 365", require("../../assets/logos/o365.svg.png")],
  ["o365", require("../../assets/logos/o365.svg.png")],
  ["vantyr-light", require("../../assets/logos/vantyr-light.png")],
  ["vantyr-dark", require("../../assets/logos/vantyr-dark.png")],
  ["apprandom", require("../../assets/logos/apprandom.png")],
]);
