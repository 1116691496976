import {
  Button,
  Container,
  Group,
  Text,
  Title,
  createStyles,
  rem,
} from "@mantine/core";

const useStyles = createStyles((theme) => ({
  root: {
    paddingTop: rem(80),
    paddingBottom: rem(120),
  },

  label: {
    textAlign: "center",
    fontWeight: 900,
    fontSize: rem(220),
    lineHeight: 1,
    marginBottom: `calc(${theme.spacing.xl} * 1.5)`,
    color: theme.colors.red[6],

    [theme.fn.smallerThan("sm")]: {
      fontSize: rem(120),
    },
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    textAlign: "center",
    fontWeight: 900,
    fontSize: rem(38),
    color: theme.colorScheme === "dark" ? theme.white : theme.colors.dark[6],

    [theme.fn.smallerThan("sm")]: {
      fontSize: rem(32),
    },
  },

  description: {
    maxWidth: rem(540),
    margin: "auto",
    marginTop: theme.spacing.xl,
    marginBottom: `calc(${theme.spacing.xl} * 1.5)`,
    color: theme.colorScheme === "dark" ? theme.white : theme.colors.gray[6],
  },
}));

const Oops = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      <Container>
        <div className={classes.label}>500</div>
        <Title className={classes.title}>Oops! Something went wrong...</Title>
        <Text size="lg" align="center" className={classes.description}>
          Don&apos;t worry, our developers are already notified.
          <br /> Try refreshing the page or come back later.
        </Text>
        <Group position="center">
          <Button
            variant="default"
            size="md"
            radius="xl"
            onClick={() => window.location.reload()}
          >
            Refresh the page
          </Button>
        </Group>
      </Container>
    </div>
  );
  // return (
  //   <Center style={{ width: "100%", height: "100%" }}>
  //     <Stack align="center">
  //       <Image width={260} src={ServerDownImage} alt="Oops!" />
  //       <Space h="xs" />
  //       <Text size="lg">Oh, no! Something went wrong.</Text>
  //       <Stack spacing={0} align="center">
  //         <Text color="gray" size="md">
  //           Don't worry, we'll get that error fixed quickly.
  //         </Text>
  //         <Text color="gray" size="md">
  //           You may also refresh the page or try again later.
  //         </Text>
  //       </Stack>
  //     </Stack>
  //   </Center>
  // );
};

export default Oops;
