import { NavigateFunction } from "react-router-dom";
import {
  SlackApi,
  SlackAppGrantRecord,
  SlackApprovedAppRecord,
  SlackInstanceActivityRecord,
  SlackInstanceRecord,
  SlackTeamRecord,
  SlackUserRecord,
} from "../api/generated";

export interface SlackTeam {
  id: string;
  name: string;
}

export interface SlackAppGrantEnrichedRecord {
  appGrant: SlackAppGrantRecord;
  user: SlackUserRecord;
  team: SlackTeamRecord;
}

export interface SlackIntegration {
  apiAppId: string;
  appName: string;
  teams: SlackTeam[];
  latestAppGrantEvent: SlackAppGrantRecord;
  allAppGrants: SlackAppGrantEnrichedRecord[];
  approvedAppRecord?: SlackApprovedAppRecord;
  usage: boolean;
  activeUsers: SlackUserRecord[];
  riskScore: number;
  riskLevel: number;
  scopes: SlackScope[];
  approvedBy: SlackUserRecord;
  createdOn: number;
  iconUrl: string;
  homepageUrl: string;
  isInternal: boolean;
  isAppDirectoryApproved: boolean;
  description: string;
}

export interface SlackScope {
  name: string;
  description: string;
  isSensitive: boolean;
  tokenTypes?: string;
  asBot?: boolean;
  isAdmin?: boolean;
}

const SLACK_SCOPES: SlackScope[] = [
  {
    name: "users.profile:read",
    description: "View profile details about people in a workspace",
    isSensitive: true,
  },
  {
    name: "users:read",
    description: "View people in a workspace",
    isSensitive: true,
  },
  {
    name: "users:read.email",
    description: "View email addresses of people in a workspace",
    isSensitive: true,
  },
  {
    name: "users.profile:write",
    description: "Edit a user’s profile information and status",
    isSensitive: false,
  },
  {
    name: "users.profile:write:user",
    description: "Change the user's profile fields",
    isSensitive: false,
  },
  {
    name: "users:write",
    description: "Set presence for your slack app",
    isSensitive: false,
  },
  {
    name: "admin",
    description: "Administer a workspace",
    isSensitive: false,
    isAdmin: true,
  },
  {
    name: "admin.analytics:read",
    description: "Access analytics data about the organization",
    isSensitive: false,
    isAdmin: true,
  },
  {
    name: "admin.apps:read",
    description: "View apps and app requests in a workspace",
    isSensitive: false,
    isAdmin: true,
  },
  {
    name: "admin.apps:write",
    description: "Manage apps in a workspace",
    isSensitive: false,
    isAdmin: true,
  },
  {
    name: "admin.barriers:read",
    description: "Read information barriers in the organization",
    isSensitive: false,
    isAdmin: true,
  },
  {
    name: "admin.barriers:write",
    description: "Manage information barriers in the organization",
    isSensitive: false,
    isAdmin: true,
  },
  {
    name: "admin.conversations:read",
    description:
      "View the channel’s member list, topic, purpose and channel name",
    isAdmin: true,
    isSensitive: false,
  },
  {
    name: "admin.conversations:write",
    description:
      "Start a new conversation, modify a conversation and modify channel details",
    isAdmin: true,
    isSensitive: false,
  },
  {
    name: "admin.invites:read",
    description:
      "Gain information about invite requests in a Grid organization.",
    isAdmin: true,
    isSensitive: false,
  },
  {
    name: "admin.invites:write",
    description: "Approve or deny invite requests in a Grid organization.",
    isSensitive: false,
    isAdmin: true,
  },
  {
    name: "admin.teams:read",
    description: "Access information about a workspace",
    isSensitive: false,
    isAdmin: true,
  },
  {
    name: "admin.teams:write",
    description: "Make changes to a workspace",
    isSensitive: false,
    isAdmin: true,
  },
  {
    name: "admin.usergroups:read",
    description: "Access information about user groups",
    isSensitive: false,
    isAdmin: true,
  },
  {
    name: "admin.usergroups:write",
    description: "Make changes to your usergroups",
    isSensitive: false,
    isAdmin: true,
  },
  {
    name: "admin.users:read",
    description: "Access a workspace’s profile information",
    isSensitive: false,
    isAdmin: true,
  },
  {
    name: "admin.users:write",
    description: "Modify account information",
    isSensitive: false,
    isAdmin: true,
  },
  {
    name: "app_mentions:read",
    description:
      "View messages that directly mention @your_slack_app in conversations that the app is in",
    isSensitive: false,
  },
  {
    name: "auditlogs:read",
    description:
      "View events from all workspaces, channels and users (Enterprise Grid only)",
    isSensitive: false,
  },
  {
    name: "bot",
    description:
      "Add the ability for people to direct message or mention @your_slack_app",
    isSensitive: false,
  },
  {
    name: "calls:read",
    description: "View information about ongoing and past calls",
    isSensitive: false,
  },
  {
    name: "calls:write",
    description: "Start and manage calls in a workspace",
    isSensitive: false,
  },
  {
    name: "channels:history",
    description:
      "View messages and other content in public channels that your slack app has been added to",
    isSensitive: false,
  },
  {
    name: "channels:join",
    description: "Join public channels in a workspace",
    isSensitive: false,
  },
  {
    name: "channels:manage",
    description:
      "Manage public channels that your slack app has been added to and create new ones",
    isSensitive: false,
  },
  {
    name: "channels:read",
    description: "View basic information about public channels in a workspace",
    isSensitive: false,
  },
  {
    name: "channels:write",
    description:
      "Manage a user’s public channels and create new ones on a user’s behalf",
    isSensitive: false,
  },
  {
    name: "chat:write",
    description: "Post messages in approved channels & conversations",
    isSensitive: false,
  },
  {
    name: "chat:write.customize",
    description:
      "Send messages as @your_slack_app with a customized username and avatar",
    isSensitive: false,
  },
  {
    name: "chat:write.public",
    description: "Send messages to channels @your_slack_app isn't a member of",
    isSensitive: false,
  },
  {
    name: "chat:write:bot",
    description: "Send messages as your slack app",
    isSensitive: false,
  },
  {
    name: "chat:write:user",
    description: "Send messages on a user’s behalf",
    isSensitive: false,
  },
  {
    name: "client",
    description: "Receive all events from a workspace in real time",
    isSensitive: false,
  },
  {
    name: "commands",
    description: "Add shortcuts and/or slash commands that people can use",
    isSensitive: false,
  },
  {
    name: "conversations:history",
    description:
      "Deprecated: Retrieve conversation history for legacy workspace apps",
    isSensitive: false,
  },
  {
    name: "conversations:read",
    description:
      "Deprecated: Retrieve information on conversations for legacy workspace apps",
    isSensitive: false,
  },
  {
    name: "conversations:write",
    description:
      "Deprecated: Edit conversation attributes for legacy workspace apps",
    isSensitive: false,
  },
  {
    name: "dnd:read",
    description: "View Do Not Disturb settings for people in a workspace",
    isSensitive: false,
  },
  {
    name: "dnd:write",
    description: "Edit a user’s Do Not Disturb settings",
    isSensitive: false,
  },
  {
    name: "dnd:write:user",
    description: "Change the user's Do Not Disturb settings",
    isSensitive: false,
  },
  {
    name: "emoji:read",
    description: "View custom emoji in a workspace",
    isSensitive: false,
  },
  {
    name: "files:read",
    description:
      "View files shared in channels and conversations that your slack app has been added to",
    isSensitive: false,
  },
  {
    name: "files:write",
    description: "Upload, edit, and delete files as your slack app",
    isSensitive: false,
  },
  {
    name: "files:write:user",
    description: "Upload, edit, and delete files as your slack app",
    isSensitive: false,
  },
  {
    name: "groups:history",
    description:
      "View messages and other content in private channels that your slack app has been added to",
    isSensitive: false,
  },
  {
    name: "groups:read",
    description:
      "View basic information about private channels that your slack app has been added to",
    isSensitive: false,
  },
  {
    name: "groups:write",
    description:
      "Manage private channels that your slack app has been added to and create new ones",
    isSensitive: false,
  },
  {
    name: "identify",
    description: "View information about a user’s identity",
    isSensitive: false,
  },
  {
    name: "identity.avatar",
    description: "View a user’s Slack avatar",
    isSensitive: false,
  },
  {
    name: "identity.avatar:read:user",
    description: "View the user's profile picture",
    isSensitive: false,
  },
  {
    name: "identity.basic",
    description: "View information about a user’s identity",
    isSensitive: false,
  },
  {
    name: "identity.email",
    description: "View a user’s email address",
    isSensitive: false,
  },
  {
    name: "identity.email:read:user",
    description: "This scope is not yet described.",
    isSensitive: false,
  },
  {
    name: "identity.team",
    description: "View a user’s Slack workspace name",
    isSensitive: false,
  },
  {
    name: "identity.team:read:user",
    description: "View the workspace's name, domain, and icon",
    isSensitive: false,
  },
  {
    name: "identity:read:user",
    description: "This scope is not yet described.",
    isSensitive: false,
  },
  {
    name: "im:history",
    description:
      "View messages and other content in direct messages that your slack app has been added to",
    isSensitive: false,
  },
  {
    name: "im:read",
    description:
      "View basic information about direct messages that your slack app has been added to",
    isSensitive: false,
  },
  {
    name: "im:write",
    description: "Start direct messages with people",
    isSensitive: false,
  },
  {
    name: "incoming-webhook",
    description:
      "Create one-way webhooks to post messages to a specific channel",
    isSensitive: false,
  },
  {
    name: "links:read",
    description: "View  URLs in messages",
    isSensitive: false,
  },
  {
    name: "links:write",
    description: "Show previews of  URLs in messages",
    isSensitive: false,
  },
  {
    name: "mpim:history",
    description:
      "View messages and other content in group direct messages that your slack app has been added to",
    isSensitive: false,
  },
  {
    name: "mpim:read",
    description:
      "View basic information about group direct messages that your slack app has been added to",
    isSensitive: false,
  },
  {
    name: "mpim:write",
    description: "Start group direct messages with people",
    isSensitive: false,
  },
  {
    name: "none",
    description: "Execute methods without needing a scope",
    isSensitive: false,
  },
  {
    name: "pins:read",
    description:
      "View pinned content in channels and conversations that your slack app has been added to",
    isSensitive: false,
  },
  {
    name: "pins:write",
    description: "Add and remove pinned messages and files",
    isSensitive: false,
  },
  {
    name: "post",
    description: "Post messages to a workspace",
    isSensitive: false,
  },
  {
    name: "reactions:read",
    description:
      "View emoji reactions and their associated content in channels and conversations that your slack app has been added to",
    isSensitive: false,
  },
  {
    name: "reactions:write",
    description: "Add and edit emoji reactions",
    isSensitive: false,
  },
  {
    name: "read",
    description: "View all content in a workspace",
    isSensitive: false,
  },
  {
    name: "reminders:read",
    description: "View reminders created by your slack app",
    isSensitive: false,
  },
  {
    name: "reminders:read:user",
    description: "Access reminders created by a user or for a user",
    isSensitive: false,
  },
  {
    name: "reminders:write",
    description: "Add, remove, or mark reminders as complete",
    isSensitive: false,
  },
  {
    name: "reminders:write:user",
    description: "Add, remove, or complete reminders for the user",
    isSensitive: false,
  },
  {
    name: "remote_files:read",
    description: "View remote files added by the app in a workspace",
    isSensitive: false,
  },
  {
    name: "remote_files:share",
    description: "Share remote files on a user’s behalf",
    isSensitive: false,
  },
  {
    name: "remote_files:write",
    description: "Add, edit, and delete remote files on a user’s behalf",
    isSensitive: false,
  },
  {
    name: "search:read",
    description: "Search a workspace’s content",
    isSensitive: false,
  },
  {
    name: "stars:read",
    description: "View messages and files that your slack app has starred",
    isSensitive: false,
  },
  {
    name: "stars:write",
    description: "Add or remove stars",
    isSensitive: false,
  },
  {
    name: "team:read",
    description:
      "View the name, email domain, and icon for workspaces your slack app is connected to",
    isSensitive: false,
  },
  {
    name: "tokens.basic",
    description: "Execute methods without needing a scope",
    isSensitive: false,
  },
  {
    name: "usergroups:read",
    description: "View user groups in a workspace",
    isSensitive: false,
  },
  {
    name: "usergroups:write",
    description: "Create and manage user groups",
    isSensitive: false,
  },
  {
    name: "workflow.steps:execute",
    description: "Add steps that people can use in Workflow Builder",
    isSensitive: false,
  },
];

export type SlackScopeName = (typeof SLACK_SCOPES)[number]["name"];

const scopesMap: Map<SlackScopeName, SlackScope> = new Map(
  SLACK_SCOPES.map((scope) => [scope.name, scope])
);

export const getScope = (scopeName: SlackScopeName) => scopesMap.get(scopeName);

export const requestAccessSlack = async () =>
  new SlackApi()
    .slackRequestAccess()
    .then((rsp) => {
      console.log(rsp);
      window.location.href = rsp.data as unknown as string;
    })
    .catch((err) => {
      console.log(err);
    });

export const openOrConnectToSlack = async (
  instance: SlackInstanceRecord,
  navigate: NavigateFunction
) =>
  instance.isConnected
    ? navigate(`/applications/slack/${instance.instanceId}`)
    : requestAccessSlack();

export const slackInstanceDescription = (instance: SlackInstanceRecord) =>
  `Slack ${instance.billingPlan} @ ${instance.enterpriseName}`;

export const slackFindOldestOauthRecord = (
  instance: SlackInstanceActivityRecord
) =>
  Math.min(
    ...instance.teams!.map(
      (x) => x.appGrants!.filter((a) => a.date && a.date > 0).slice(-1)[0].date!
    )
  );

export const slackIntegrationIsByVantyr = (integration: SlackIntegration) =>
  integration.appName.toLowerCase().includes("vantyr");

export const slackGetIntegrationsFromUserRecords = (
  instance: SlackInstanceActivityRecord
): SlackIntegration[] =>
  mergeAppDuplicates(
    instance.teams?.flatMap((team) => {
      return team
        .users!.filter((user) => user.apiAppId !== null && !user.deleted)
        .map((user) => {
          const isByVantyr = user?.realName!.toLowerCase().includes("vantyr");
          const approvedAppRecord = instance.approvedApps?.find(
            (x) => x.approvedAppId === user.apiAppId
          );
          // FIXME: rewrite this one in dumb code for speed (this smartassery will be slow)
          const activeUsers = [...team.appGrants!]
            .reverse()
            .reduce(
              (acc, cur) =>
                cur!.appId! !== user!.apiAppId!
                  ? acc
                  : cur!.changeType! === "added" && !acc.includes(cur.userId!)
                  ? acc.concat(cur.userId!)
                  : cur!.changeType! === "removed"
                  ? acc.filter((x) => x !== cur.userId!)
                  : acc,
              [] as string[]
            );
          const activeUsersRecords = activeUsers.map((userId) =>
            team.users!.find((x) => x.userId === userId)
          );
          const allAppGrants = team
            .appGrants!.filter((x) => x.appId === user.apiAppId)
            .map(
              (x) =>
                ({
                  appGrant: x,
                  user:
                    team.users!.find((y) => y.userId === x.userId) ||
                    ({
                      userId: x.userId,
                      realName: x.userId === "0" ? "slack" : "unknown",
                    } as SlackUserRecord),
                  team: team,
                } as SlackAppGrantEnrichedRecord)
            );
          const latestAppGrantEvent = allAppGrants[0]?.appGrant;
          // const latestAppGrantEvent = team.appGrants?.find(
          //   (x) => x.appId === user.apiAppId
          // );
          const scopes = (
            approvedAppRecord && approvedAppRecord.scopes
              ? approvedAppRecord.scopes
              : allAppGrants
                  .find((x) => x.appGrant.scope)
                  ?.appGrant.scope?.split(",")
          )?.map((scope) => {
            return getScope(scope as SlackScopeName);
          }) as SlackScope[];
          const approvedBy = team.users?.find(
            (x) => x.userId === approvedAppRecord?.lastResolvedByActorId
          );
          // TODO: Is this ok??
          const createdOn = approvedAppRecord?.updatedOn;
          const riskScore = calcRiskScore(scopes, !approvedAppRecord);
          return {
            apiAppId: user?.apiAppId,
            appName: approvedAppRecord
              ? approvedAppRecord.name
              : user?.realName,
            teams: [{ id: team.teamId, name: team.name }],
            latestAppGrantEvent: latestAppGrantEvent,
            allAppGrants: allAppGrants,
            approvedAppRecord: approvedAppRecord,
            usage: activeUsers.length > 0,
            activeUsers: activeUsersRecords,
            riskScore: isByVantyr ? 0 : riskScore,
            riskLevel:
              riskScore > 80 ? 3 : riskScore > 60 ? 2 : riskScore > 30 ? 1 : 0,
            scopes: scopes,
            approvedBy: approvedBy,
            createdOn: createdOn,
            iconUrl: approvedAppRecord
              ? approvedAppRecord.iconUrl
              : user.imageUrl,
            homepageUrl: approvedAppRecord?.appHomepageUrl,
            description: approvedAppRecord
              ? approvedAppRecord.description
              : null,
            isInternal: approvedAppRecord?.isInternal,
            isAppDirectoryApproved: approvedAppRecord?.isAppDirectoryApproved,
          } as SlackIntegration;
        });
    }) ?? []
  );

const calcRiskScore = (
  scopes: SlackScope[],
  approvedAppRecord: boolean
): number => {
  var riskScore = 10;
  if (approvedAppRecord) {
    riskScore += 40;
  }
  if (!scopes) {
    return 50;
  }
  if (scopes.find((scope) => scope?.isAdmin)) {
    riskScore += 20;
  }
  if (scopes.find((scope) => scope?.name?.includes("write"))) {
    riskScore += 20;
  }
  if (scopes.find((scope) => scope?.name?.includes("bot"))) {
    riskScore += 20;
  }
  return riskScore;
};

const mergeAppDuplicates = (apps: SlackIntegration[]): SlackIntegration[] => {
  var seen = new Map<string, SlackIntegration>();
  return apps.filter(function (app) {
    var previous;

    // Have we seen this apiAppId before?
    if (seen.has(app.apiAppId)) {
      // Yes, grab it and add this data to it
      previous = seen.get(app.apiAppId);
      previous?.teams.push(...app.teams);
      previous?.activeUsers?.push(...app.activeUsers!);
      previous?.allAppGrants?.push(...app.allAppGrants!);

      // Don't keep this app, we've merged it into the previous one
      return false;
    }

    // Remember that we've seen it
    seen.set(app.apiAppId, app);

    // Keep this one, we'll merge any others that match into it
    return true;
  });
};
