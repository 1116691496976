import { Group, Image, Text } from "@mantine/core";
import { ProductLogoAssets } from "../common/ProductLogoAssets";
import { SlackInstanceActivityRecord } from "../../api/generated";
import { periodSinceTs } from "../../misc/toolkit_common";
import {
  slackFindOldestOauthRecord,
  slackGetIntegrationsFromUserRecords,
  slackInstanceDescription,
} from "../../misc/toolkit_slack";

interface SlackInstanceSummaryProps {
  instance: SlackInstanceActivityRecord;
}

const SlackInstanceSummary = ({ instance }: SlackInstanceSummaryProps) => {
  const integrations = slackGetIntegrationsFromUserRecords(instance);
  const integrationsCount = integrations.length;
  const oldestOauthRecordTs = slackFindOldestOauthRecord(instance);
  const observedPeriod = periodSinceTs(oldestOauthRecordTs);

  return (
    <Group position="left">
      <Image
        src={ProductLogoAssets.get("slack")}
        fit="contain"
        width={36}
        height={36}
      />
      <div>
        <Text align="left">{slackInstanceDescription(instance.instance!)}</Text>
        <Text size="xs" color="dimmed" align="left">
          <strong>{integrationsCount} integrations</strong> over the last{" "}
          <strong>{observedPeriod}</strong>.
        </Text>
      </div>
    </Group>
  );
};

export default SlackInstanceSummary;
