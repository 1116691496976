import {
  UnstyledButton,
  Group,
  HoverCard,
  Center,
  Text,
  createStyles,
} from "@mantine/core";
import {
  IconChevronUp,
  IconChevronDown,
  IconSelector,
  IconInfoCircle,
} from "@tabler/icons";

const useStyles = createStyles((theme) => ({
  tableHeader: {
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.fn.rgba(theme.colors["gray"][8], 0.2)
        : theme.colors["gray"][0],
  },

  th: {
    padding: "0 !important",
  },

  control: {
    width: "100%",
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    },
  },

  icon: {
    width: 21,
    height: 21,
  },
}));

export interface TableHeaderCellProps {
  children: React.ReactNode;
  reversed: boolean;
  sorted: boolean;
  style?: React.CSSProperties;
  description?: React.ReactNode;
  onSort(): void;
  position?: "left" | "right" | "center" | "apart";
  pl?: number;
  pr?: number;
}

export const TableHeaderCell = ({
  children,
  reversed,
  sorted,
  onSort,
  style,
  description,
  position = "left",
  pl = 10,
  pr = 10,
}: TableHeaderCellProps) => {
  const { classes } = useStyles();
  const Icon = sorted
    ? reversed
      ? IconChevronUp
      : IconChevronDown
    : IconSelector;
  return (
    <th style={style} className={classes.th}>
      <UnstyledButton
        onClick={onSort}
        className={classes.control}
        pl={pl}
        pr={pr}
      >
        <Group spacing={4} position="apart">
          <Group spacing={4}>
            <Text
              weight={500}
              size="xs"
              style={{ textOverflow: "ellipsis", overflow: "hidden" }}
              lineClamp={1}
            >
              {children}
            </Text>
            {description && (
              <HoverCard
                width={360}
                shadow="xl"
                withArrow
                openDelay={100}
                closeDelay={100}
                position="bottom"
                offset={20}
              >
                <HoverCard.Target>
                  <Center className={classes.icon}>
                    <IconInfoCircle size={14} stroke={1} />
                  </Center>
                </HoverCard.Target>
                <HoverCard.Dropdown>{description}</HoverCard.Dropdown>
              </HoverCard>
            )}
          </Group>
          <Center className={classes.icon}>
            <Icon size={14} stroke={1} />
          </Center>
        </Group>
      </UnstyledButton>
    </th>
  );
};

export interface TableHeaderProps {
  children: React.ReactNode;
}

export const TableHeader = ({ children }: TableHeaderProps) => {
  const { classes } = useStyles();
  return (
    <thead className={classes.tableHeader}>
      <tr>{children}</tr>
    </thead>
  );
};
