import { Group, Image, Text } from "@mantine/core";
import { SlackInstanceRecord } from "../../api/generated";
import { githubInstanceDescription } from "../../misc/toolkit_github";
import { ProductLogoAssets } from "../common/ProductLogoAssets";
import { demoGithubInstance } from "../../tooling/demo";

interface GithubInstanceSummaryProps {
  instance: SlackInstanceRecord;
}

const GithubInstanceSummary = ({ instance }: GithubInstanceSummaryProps) => {
  return (
    <Group position="left">
      <Image
        src={ProductLogoAssets.get("github")}
        fit="contain"
        width={36}
        height={36}
      />
      <div>
        <Text align="left">{githubInstanceDescription(instance)}</Text>
        <Text size="xs" color="dimmed" align="left">
          <strong>{demoGithubInstance.apps.length} integrations</strong> over
          the last <strong>7 months</strong>.
        </Text>
      </div>
    </Group>
  );
};

export default GithubInstanceSummary;
