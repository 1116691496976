import ReactFlow, {
  Edge,
  Node,
  NodeTypes,
  ProOptions,
  ReactFlowProvider,
} from "reactflow";

import GraphNode from "../reactflow/GraphNode";
import useForceLayout, {
  MapData,
  TotalMapData,
} from "../reactflow/useForceLayout";

import {
  ActionIcon,
  Aside,
  Avatar,
  Badge,
  Box,
  Center,
  Col,
  Collapse,
  Container,
  createStyles,
  Divider,
  Grid,
  Group,
  HoverCard,
  Loader,
  MultiSelect,
  Notification,
  RingProgress,
  ScrollArea,
  SimpleGrid,
  Space,
  Stack,
  Switch,
  Table,
  Tabs,
  Text,
  TextInput,
  Tooltip,
  UnstyledButton,
  useMantineTheme,
} from "@mantine/core";
import { useEffect, useState } from "react";
import "reactflow/dist/style.css";
import {
  SalesforceApi,
  SalesforceAppMenuItemRecord,
  SalesforceExposedFieldRecordForInstance,
  SlackApi,
} from "../../api/generated";

import {
  IconArrowRight,
  IconChevronDown,
  IconChevronUp,
  IconFocusCentered,
  IconHash,
  IconInfoCircle,
  IconPackages,
  IconPackgeExport,
  IconPlug,
  IconPlugConnected,
  IconSearch,
  IconSelector,
  IconShieldX,
  IconUserExclamation,
  IconX,
} from "@tabler/icons";

import { useDebouncedState } from "@mantine/hooks";
import { parse } from "fecha";
import { Link } from "react-router-dom";
import { useReactFlow } from "reactflow";
import { VANTYR_API_DATETIME_FORMAT } from "../../constants/constants";
import { formatBytes } from "../../misc/toolkit_common";
import {
  breakdownRisk,
  DataExposureRecordForInstance,
  FauxDataExposureRecord,
  sfInstanceLabelShort,
  SfIntegrationLogo,
} from "../../misc/toolkit_salesforce";
import { usePaginationStore } from "../../state/store";
import PaginationControl from "../common/PaginationControl";
import TableDataFlowSalesforceInstance from "../salesforce/TableDataFlowSalesforceInstance";
import TabsSalesforceIntegration from "../salesforce/TabsSalesforceIntegration";
import { AppsTree } from "../reactflow/AppsTree";
import SearchInput from "../common/SearchInput";
import FilterSalesforceInstances from "../salesforce/FilterSalesforceInstances";
import TabNarrowSalesforceIntegrations from "../salesforce/TabNarrowSalesforceIntegrations";
import { slackInstanceDescription } from "../../misc/toolkit_slack";
import { ViewIntegrationSlack } from "../slack/ViewIntegrationSlack";
import ViewSlackInstance from "../slack/ViewSlackInstance";
import {
  SalesforceInstanceSummary,
  SummarySalesforceIntegrations,
} from "../salesforce/SalesforceSummaryInstance";
import {
  demoInstanceFullRecord,
  demoInstances,
  demoInstanceSlack,
  demoMode,
} from "../../tooling/demo";
import RiskBreakdown from "../common/RiskBreakdown";

const proOptions: ProOptions = { account: "paid-pro", hideAttribution: true };
const defaultNodes: Node[] = [];
const defaultEdges: Edge[] = [];

const nodeTypes: NodeTypes = {
  circle: GraphNode,
};

type ReactFlowGraphProps = {
  strength: number;
  distance: number;
};

const badgeColorByRisk = ["gray.6", "orange.6", "red.6"];
const badgeLabelByRisk = ["Low", "Medium", "High"];

const useStyles = createStyles((theme) => ({
  rowSelected: {
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.fn.rgba(theme.colors[theme.primaryColor][7], 0.2)
        : theme.colors[theme.primaryColor][0],
  },

  tableHeader: {
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.fn.rgba(theme.colors["gray"][8], 0.2)
        : theme.colors["gray"][0],
  },

  th: {
    padding: "0 !important",
  },

  control: {
    width: "100%",
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    },
  },

  icon: {
    width: 21,
    height: 21,
  },
}));

interface ThProps {
  children: React.ReactNode;
  reversed: boolean;
  sorted: boolean;
  style?: React.CSSProperties;
  description?: React.ReactNode;
  onSort(): void;
  position?: "left" | "right" | "center" | "apart";
  pl?: number;
  pr?: number;
}

function Th({
  children,
  reversed,
  sorted,
  onSort,
  style,
  description,
  position = "left",
  pl = 10,
  pr = 10,
}: ThProps) {
  const { classes } = useStyles();
  const Icon = sorted
    ? reversed
      ? IconChevronUp
      : IconChevronDown
    : IconSelector;
  return (
    <th style={style} className={classes.th}>
      <UnstyledButton
        onClick={onSort}
        className={classes.control}
        pl={pl}
        pr={pr}
      >
        <Group spacing={4} position="apart">
          <Group spacing={4}>
            <Text
              weight={500}
              size="xs"
              style={{ textOverflow: "ellipsis", overflow: "hidden" }}
              lineClamp={1}
            >
              {children}
            </Text>
            {description && (
              <HoverCard
                width={360}
                shadow="xl"
                withArrow
                openDelay={100}
                closeDelay={100}
                position="bottom"
                offset={20}
              >
                <HoverCard.Target>
                  <Center className={classes.icon}>
                    <IconInfoCircle size={14} stroke={1} />
                  </Center>
                </HoverCard.Target>
                <HoverCard.Dropdown>{description}</HoverCard.Dropdown>
              </HoverCard>
            )}
          </Group>
          <Center className={classes.icon}>
            <Icon size={14} stroke={1} />
          </Center>
        </Group>
      </UnstyledButton>
    </th>
  );
}

function ReactFlowPro({ strength, distance }: ReactFlowGraphProps) {
  const theme = useMantineTheme();
  const [viewDataFlow, setViewDataFlow] = useState(false);
  const [activeTab, setActiveTab] = useState<string | null>("summary");
  const [activeIntegrationsTab, setActiveIntegrationsTab] = useState<
    string | null
  >("summary");
  const darkTheme = theme.colorScheme === "dark";

  function tabFontColor(thisTab: string) {
    return activeTab === thisTab ? "teal.6" : darkTheme ? "gray.5" : "gray.9";
  }

  const tabFontWeight = (thisTab: string) =>
    activeTab === thisTab ? 600 : 400;

  const [selectedCoreApps, setSelectedCoreApps] = useState<string[]>([]);

  const [integrationsFilter, setIntegrationsFilter] = useState("");

  // const defaultFilterConditions = demoMode ? [] : ["risk-3", "risk-2", "risk-1"];
  const defaultFilterConditions = ["risk-3", "risk-2"];

  const [filterConditions, setFilterConditions] = useState<string[]>(
    defaultFilterConditions
  );

  const { classes, cx } = useStyles();

  const [totalMapData, setTotalMapData] = useState<TotalMapData>({
    salesforceInstances: [],
    slackInstances: [],
  });

  const [searchCondition, setSearchCondition] = useDebouncedState<string>(
    "",
    200
  );
  const [reverseSortDirection, setReverseSortDirection] = useState(true);
  const [sortBy, setSortBy] = useState<keyof FauxDataExposureRecord | null>(
    "classification"
  );
  const [filterConditionsClassification, setFilterConditionsClassification] =
    useState<string[]>([
      "classification-PII",
      "classification-PHI",
      "classification-PCI",
    ]);
  const setSorting = (field: keyof FauxDataExposureRecord) => {
    const reversed = field === sortBy ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortBy(field);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (demoMode) {
        const demoMapData: MapData = {
          instance: demoInstances[0],
          fullData: demoInstanceFullRecord,
        };

        setTotalMapData({
          salesforceInstances: [demoMapData],
          slackInstances: [demoInstanceSlack],
        });

        return;
      }

      const salesforceApi = new SalesforceApi();
      const instances = (await salesforceApi.listInstances()).data;
      const fullMapData = await Promise.all(
        instances.map(async (instance) => {
          const apps = await salesforceApi.listIntegrations(
            instance.instanceId!
          );
          return {
            instance,
            fullData: apps.data,
          };
        })
      );
      const slackApi = new SlackApi();
      const slackInstances = (await slackApi.slackListInstances()).data;
      const slackFullMapData = await Promise.all(
        slackInstances.map(async (instance) => {
          return (await slackApi.slackListIntegrations(instance.instanceId!))
            .data;
        })
      );
      setTotalMapData({
        salesforceInstances: fullMapData,
        slackInstances: slackFullMapData,
      });
    };
    fetchData();
  }, []);

  useForceLayout({
    strength,
    distance,
    totalMapData,
    selectedCoreApps,
    integrationsFilter,
    filterConditions,
    viewDataFlow,
  });

  const [nodeSelected, setNodeSelected] = useState<Node | null>(null);

  const { fitView } = useReactFlow();

  const { pageSizeExposure, setPageSizeExposure } = usePaginationStore.get(
    "instance-exposure-aside"
  )!((state) => ({
    pageSizeExposure: state.pageSize,
    setPageSizeExposure: state.setPageSize,
  }));

  const [activePageExp, setPageExp] = useState(1);

  useEffect(() => {
    setTimeout(function () {
      fitView();
    }, 10);
  }, [
    fitView,
    nodeSelected,
    integrationsFilter,
    filterConditions,
    selectedCoreApps,
  ]);

  const mapData = totalMapData.salesforceInstances;

  if (!mapData || mapData.length === 0) {
    return (
      <>
        <Center style={{ height: "100%" }}>
          <Loader color="gray" variant="dots"></Loader>
        </Center>
      </>
    );
  }

  const integrations = mapData.reduce(
    (acc, curr) =>
      acc.concat(
        nodeSelected?.id === curr.instance.instanceId
          ? curr.fullData.integrations!
          : []
      ),
    [] as SalesforceAppMenuItemRecord[]
  );

  const instanceFullRecord = mapData.find(
    (i) => i.instance.instanceId === nodeSelected?.id
  )?.fullData;

  const mapDataIndex: number = mapData.findIndex(
    (i) => i.instance.instanceId === nodeSelected?.id
  );

  const shadowIntegrations = integrations.filter((i) => !i.createdBy).length;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const inactiveIntegrations =
    integrations.length -
    integrations.filter((i) => i.oauthTokens && i.oauthTokens!.length !== 0)
      .length;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const noDataFlowIntegrations =
    integrations.length -
    integrations.filter(
      (i) => i.queriedEntities && i.queriedEntities!.length !== 0
    ).length;

  const noDataFlowHighRiskActiveIntegrations = integrations.filter(
    (i) =>
      (!i.queriedEntities || i.queriedEntities.length !== 0) &&
      i.riskLevel === 2 &&
      i.oauthTokens &&
      i.oauthTokens!.length !== 0
  ).length;

  const highRiskFewUsersIntegrations = integrations.filter(
    (i) =>
      ([...new Set(i.oauthTokens!.map((t) => t.userName))].length || 0) < 10 &&
      i.riskLevel === 2
  ).length;

  const highRiskRecentIntegrations = integrations.filter(
    (i) =>
      i.riskLevel === 2 &&
      i.createdOn &&
      Math.abs(
        new Date().getTime() -
          parse(i!.createdOn!, VANTYR_API_DATETIME_FORMAT)!.getTime()
      ) <
        1000 * 60 * 60 * 24 * 30
  ).length;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const classifiedDataFlowIntegrations = integrations.filter(
    (i) =>
      i.classifiedFields &&
      i.classifiedFields!.length !== 0 &&
      i.classifiedFields.filter(
        (f: string) => f.includes("::PII") || f.includes("::Sensitive")
      ).length > 0
  ).length;

  const classifiedDataFlowHighRiskIntegrations = integrations.filter(
    (i) =>
      i.riskLevel === 2 &&
      i.classifiedFields &&
      i.classifiedFields!.length !== 0 &&
      i.classifiedFields.filter(
        (f: string) => f.includes("::PII") || f.includes("::Sensitive")
      ).length > 0
  ).length;

  const classifiedDataFlowShadowIntegrations = integrations.filter(
    (i) =>
      !i.createdBy &&
      i.classifiedFields &&
      i.classifiedFields!.length !== 0 &&
      i.classifiedFields.filter(
        (f: string) => f.includes("::PII") || f.includes("::Sensitive")
      ).length > 0
  ).length;

  const uniqueDataClassifications = nodeSelected?.data?.exposedFields?.map(
    (field: SalesforceExposedFieldRecordForInstance) => {
      return {
        field: field.e + " :: " + field.f,
        classification: field.s ? field.s[0] : "Unclassified",
        count: field.c,
        integrations: field.i,
      } as DataExposureRecordForInstance;
    }
  ) as DataExposureRecordForInstance[];

  const dataExposureRows = uniqueDataClassifications
    ?.filter((r) => {
      const record = r!;
      if (record.field.split("::")[1].trim().length === 0) {
        return false;
      }
      let searchOk = true;
      if (searchCondition) {
        searchOk = record.field
          ?.toLowerCase()
          .replace(" :: ", " ")
          .includes(searchCondition.toLowerCase());
      }
      if (
        filterConditionsClassification.length === 0 ||
        (filterConditionsClassification.length !== 0 &&
          (!filterConditionsClassification.find((c) =>
            c.includes("classification-")
          ) ||
            filterConditionsClassification.includes(
              `classification-${record.classification}`
            )))
      ) {
        return searchOk;
      } else {
        return false;
      }
    })
    .sort((a, b) => {
      if (!sortBy) {
        return (
          b!.classification!.localeCompare(a!.classification!, "en") *
          (reverseSortDirection ? -1 : 1)
        );
      }

      switch (sortBy) {
        case "field":
          return (
            b!.field!.localeCompare(a!.field!, "en") *
            (reverseSortDirection ? -1 : 1)
          );
        case "count":
          return (
            ((b!.count! || 0) - (a!.count! || 0)) *
            (reverseSortDirection ? -1 : 1)
          );
        case "classification":
          return (
            b!.classification!.localeCompare(a!.classification!, "en") *
            (reverseSortDirection ? -1 : 1)
          );

        default:
          return (
            b!.classification!.localeCompare(a!.classification!, "en") *
            (reverseSortDirection ? -1 : 1)
          );
      }
    });

  const rowsFilteredLengthExp = dataExposureRows?.length;

  const pageSizeExposureAdjusted =
    pageSizeExposure !== 0 ? pageSizeExposure! : rowsFilteredLengthExp;

  const rowsToDisplayExp = dataExposureRows
    ?.slice(
      (activePageExp - 1) * pageSizeExposureAdjusted,
      activePageExp * pageSizeExposureAdjusted
    )
    .map((r) => {
      const record = r!;
      return (
        <tr key={record.field} style={{ height: "30px" }}>
          <td style={{ textAlign: "left", paddingLeft: 10 }}>
            <div style={{ width: "calc(100% - 20px)" }}>
              <Text
                ml="xs"
                size="xs"
                style={{ textOverflow: "ellipsis", overflow: "hidden" }}
                lineClamp={4}
                // weight={400}
              >
                {record.field.replace(" :: ", " ")}
              </Text>
            </div>
          </td>

          <td style={{ textAlign: "left", paddingLeft: 10, paddingRight: 20 }}>
            <Badge
              radius="xl"
              size="md"
              color={
                record.classification === "PHI"
                  ? "green.6"
                  : record.classification === "PII"
                  ? "pink.6"
                  : record.classification === "Sensitive"
                  ? "grape.6"
                  : record.classification === "PCI"
                  ? "red.6"
                  : "gray.6"
              }
            >
              {record.classification}
            </Badge>
          </td>

          <td align="left" style={{ paddingLeft: 10 }}>
            <Text
              size="xs"
              // weight={400}
              // style={{ cursor: "pointer", width: 60 }}
            >
              {record.count !== -1 ? record.count : "N/A"}
            </Text>
          </td>

          <td align="left" style={{ paddingLeft: 10 }}>
            <Text
              size="xs"
              // weight={400}
              // style={{ cursor: "pointer", width: 60 }}
            >
              {record.integrations.join(", ")}
            </Text>
          </td>
        </tr>
      );
    });

  const piiCount =
    !mapData[mapDataIndex] || !mapData[mapDataIndex].fullData
      ? 0
      : mapData[mapDataIndex].fullData.classifiedFields?.reduce((acc, cur) => {
          return acc + (cur.split("::")[2].includes("PII") ? 1 : 0);
        }, 0);

  const phiCount =
    !mapData[mapDataIndex] || !mapData[mapDataIndex].fullData
      ? 0
      : mapData[mapDataIndex].fullData!.classifiedFields?.reduce((acc, cur) => {
          return acc + (cur.split("::")[2].includes("PHI") ? 1 : 0);
        }, 0);

  const pciCount =
    !mapData[mapDataIndex] || !mapData[mapDataIndex].fullData
      ? 0
      : mapData[mapDataIndex].fullData!.classifiedFields?.reduce((acc, cur) => {
          return acc + (cur.split("::")[2].includes("PCI") ? 1 : 0);
        }, 0);

  const sensitiveCount =
    !mapData[mapDataIndex] || !mapData[mapDataIndex].fullData
      ? 0
      : mapData[mapDataIndex].fullData!.classifiedFields?.reduce((acc, cur) => {
          return acc + (cur.split("::")[2].includes("Sensitive") ? 1 : 0);
        }, 0);

  return (
    <>
      <Grid>
        <Col span="content">
          <Stack>
            <Group>
              <SearchInput
                onSearch={(searchCondition) =>
                  setIntegrationsFilter(searchCondition)
                }
              />
              <Switch
                labelPosition="right"
                label="Data flow"
                size="sm"
                radius="xl"
                color="indigo"
                checked={viewDataFlow}
                pl={4}
                onChange={(event) =>
                  setViewDataFlow(event.currentTarget.checked)
                }
              />
            </Group>
            <FilterSalesforceInstances
              onFilter={(filterConditions) =>
                setFilterConditions(filterConditions)
              }
              filterConditions={filterConditions}
            />
          </Stack>
        </Col>
        <Col span="auto">
          <Box></Box>
        </Col>
        <Col span="content">
          <Collapse in={nodeSelected !== null}>
            <Stack align="flex-end" justify="flex-start">
              <ActionIcon
                onClick={() => setNodeSelected(null)}
                color={theme.white}
                variant="default"
                mx={-22}
                size="xl"
              >
                <IconArrowRight size={20} stroke={1} />
              </ActionIcon>
            </Stack>
          </Collapse>
        </Col>
      </Grid>
      <ReactFlow
        nodeTypes={nodeTypes}
        minZoom={-Infinity}
        maxZoom={Infinity}
        defaultNodes={defaultNodes}
        defaultEdges={defaultEdges}
        proOptions={proOptions}
        fitView
        fitViewOptions={{ padding: 100 }}
        elevateEdgesOnSelect
        nodesDraggable={false}
        nodesConnectable={false}
        onPaneClick={() => {
          setActiveIntegrationsTab(null);
          setNodeSelected(null);
        }}
        onNodeClick={(e, node) => {
          setActiveIntegrationsTab("summary");
          node.id !== "vantyr" && setNodeSelected(node);
        }}
        onEdgeClick={(e, edge) => {
          setActiveIntegrationsTab("data-movement");
          edge.targetNode!.id !== "vantyr" && setNodeSelected(edge.targetNode!);
        }}
        defaultEdgeOptions={{ interactionWidth: 20 }}
      ></ReactFlow>
      {!nodeSelected ? null : (
        <Collapse in={nodeSelected != null}>
          <Aside
            zIndex={0}
            width={{ base: 700 }}
            p="lg"
            style={{
              borderLeftWidth: 0,
              width: 700,
              boxShadow:
                "rgba(0, 0, 0, 0.05) 0px 1px 3px 0px, rgba(0, 0, 0, 0.05) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px",
            }}
          >
            <Aside.Section>
              {!nodeSelected?.data?.slackIntegrationRecord &&
                !nodeSelected?.data?.slackInstanceRecord && (
                  <Group px="md">
                    {/* <ActionIcon onClick={() => setNodeSelected(null)}>
                      <IconArrowRight size={16} />
                    </ActionIcon> */}

                    {!nodeSelected?.data?.instanceType ? (
                      nodeSelected?.data?.slackIntegrationRecord ? (
                        <></>
                      ) : nodeSelected.id === "vantyr" ? (
                        <></>
                      ) : (
                        <>
                          <SfIntegrationLogo
                            integration={nodeSelected.data}
                            width={24}
                            height={24}
                          />
                          <Text size="md" pt={0}>
                            <strong>{nodeSelected?.data.label}</strong>{" "}
                            integration with{" "}
                            <strong>
                              {sfInstanceLabelShort(
                                nodeSelected.data.instanceRecord
                              )}
                            </strong>
                          </Text>
                        </>
                      )
                    ) : nodeSelected.data.instanceType === "salesforce" ? (
                      <SalesforceInstanceSummary
                        instance={nodeSelected.data.instanceRecord}
                      />
                    ) : (
                      <Text size="md">
                        {slackInstanceDescription(
                          nodeSelected.data.slackInstanceRecord.instance
                        )}
                      </Text>
                    )}
                  </Group>
                )}
            </Aside.Section>
            <Aside.Section grow component={ScrollArea}>
              {!nodeSelected?.data?.instanceType ? (
                nodeSelected?.data?.slackIntegrationRecord ? (
                  <>
                    {nodeSelected?.data?.slackIntegrationRecord && (
                      <ViewIntegrationSlack
                        integration={nodeSelected.data.slackIntegrationRecord}
                        instance={nodeSelected.data.slackInstanceRecord}
                      />
                    )}
                  </>
                ) : nodeSelected.id === "vantyr" ? (
                  <></>
                ) : (
                  <Container my="xl" p={0} px="md">
                    <TabsSalesforceIntegration
                      nodeData={nodeSelected.data as AppsTree}
                      startingActiveTab={activeIntegrationsTab!}
                      setActiveTab={setActiveIntegrationsTab}
                      instance={nodeSelected.data.instanceRecord}
                    ></TabsSalesforceIntegration>
                  </Container>
                )
              ) : nodeSelected?.data?.instanceType === "slack" ? (
                <>
                  {nodeSelected?.data?.slackInstanceRecord && (
                    <ViewSlackInstance
                      instance={nodeSelected.data.slackInstanceRecord}
                      narrowView={true}
                    />
                  )}
                  {/* <Container p="md">
                    <Tabs
                      variant="pills"
                      color={darkTheme ? "dark.6" : "teal.0"}
                      value={activeTab}
                      onTabChange={setActiveTab}
                    >
                      <Tabs.List grow>
                        <Tabs.Tab
                          value="summary"
                          icon={
                            <ActionIcon
                              variant="transparent"
                              color={tabFontColor("summary")}
                            >
                              <IconFocusCentered size="1.5rem" stroke={1} />
                            </ActionIcon>
                          }
                        >
                          <Text color={tabFontColor("summary")}>Summary</Text>
                        </Tabs.Tab>
                        <Tabs.Tab
                          value="integrations"
                          icon={
                            <ActionIcon
                              variant="transparent"
                              color={tabFontColor("integrations")}
                            >
                              <IconPlug size="1.5rem" stroke={1} />
                            </ActionIcon>
                          }
                        >
                          <Text color={tabFontColor("integrations")}>
                            Integrations
                          </Text>
                        </Tabs.Tab>
                      </Tabs.List>
                      <Tabs.Panel value="summary" pt="xl">
                        <></>
                      </Tabs.Panel>
                      <Tabs.Panel value="integrations" pt="xl">
                      </Tabs.Panel>
                    </Tabs>
                  </Container> */}
                </>
              ) : (
                <Container p="md">
                  <Tabs
                    variant="pills"
                    color={darkTheme ? "dark.6" : "teal.0"}
                    radius="xl"
                    value={activeTab}
                    onTabChange={setActiveTab}
                  >
                    <Tabs.List grow>
                      <Tabs.Tab
                        py={6}
                        pl={16}
                        pr={22}
                        m={0}
                        value="summary"
                        icon={
                          <ActionIcon
                            variant="transparent"
                            color={tabFontColor("summary")}
                          >
                            <IconFocusCentered size="1.5rem" stroke={1} />
                          </ActionIcon>
                        }
                      >
                        <Text
                          p={0}
                          m={0}
                          color={tabFontColor("summary")}
                          weight={tabFontWeight("summary")}
                        >
                          Summary
                        </Text>
                      </Tabs.Tab>
                      <Tabs.Tab
                        py={6}
                        pl={16}
                        pr={22}
                        m={0}
                        value="integrations"
                        icon={
                          <ActionIcon
                            variant="transparent"
                            color={tabFontColor("integrations")}
                          >
                            <IconPlug size="1.5rem" stroke={1} />
                          </ActionIcon>
                        }
                      >
                        <Text
                          p={0}
                          m={0}
                          color={tabFontColor("integrations")}
                          weight={tabFontWeight("integrations")}
                        >
                          Integrations
                        </Text>
                      </Tabs.Tab>
                      <Tabs.Tab
                        py={6}
                        pl={16}
                        pr={22}
                        m={0}
                        value="data-exposure"
                        icon={
                          <ActionIcon
                            variant="transparent"
                            color={tabFontColor("data-exposure")}
                          >
                            <IconPackages size="1.5rem" stroke={1} />
                          </ActionIcon>
                        }
                      >
                        <Text
                          p={0}
                          m={0}
                          color={tabFontColor("data-exposure")}
                          weight={tabFontWeight("data-exposure")}
                        >
                          Data Exposure
                        </Text>
                      </Tabs.Tab>
                      <Tabs.Tab
                        py={6}
                        pl={16}
                        pr={22}
                        m={0}
                        value="data-movement"
                        icon={
                          <ActionIcon
                            variant="transparent"
                            color={tabFontColor("data-movement")}
                          >
                            <IconPackgeExport size="1.5rem" stroke={1} />
                          </ActionIcon>
                        }
                      >
                        <Text
                          p={0}
                          m={0}
                          color={tabFontColor("data-movement")}
                          weight={tabFontWeight("data-movement")}
                        >
                          Data Flow
                        </Text>
                      </Tabs.Tab>
                    </Tabs.List>

                    <Tabs.Panel value="summary" pt="xs">
                      <Stack spacing="xl" my="xl">
                        <RiskBreakdown
                          breakdown={breakdownRisk(instanceFullRecord!)}
                          onSectionClicked={(riskLevel) =>
                            riskLevel === -1
                              ? setFilterConditions([])
                              : setFilterConditions([`risk-${riskLevel}`])
                          }
                        />
                        <Box></Box>
                        <SimpleGrid cols={1} spacing="xl" verticalSpacing="xs">
                          {classifiedDataFlowShadowIntegrations > 0 && (
                            <UnstyledButton
                              pb="md"
                              onClick={() =>
                                setFilterConditions([
                                  "shadow-yes",
                                  "usage-classified",
                                ])
                              }
                            >
                              <Notification
                                title={
                                  <Group spacing={8}>
                                    {classifiedDataFlowShadowIntegrations >
                                    1 ? (
                                      <Text
                                        weight={400}
                                      >{`${classifiedDataFlowShadowIntegrations} shadow integrations are pulling sensitive data`}</Text>
                                    ) : (
                                      <Text
                                        weight={400}
                                      >{`${classifiedDataFlowShadowIntegrations} shadow integration is pulling sensitive data`}</Text>
                                    )}
                                    <HoverCard
                                      width="300px"
                                      position="top"
                                      withArrow
                                      shadow="md"
                                    >
                                      <HoverCard.Dropdown
                                        sx={{ pointerEvents: "none" }}
                                      >
                                        <Text size="sm">
                                          Shadow integrations have not been
                                          approved / installed by a Salesforce
                                          administrator. The permissions granted
                                          to such integrations are not known.
                                        </Text>
                                      </HoverCard.Dropdown>
                                      <HoverCard.Target>
                                        <Center
                                          style={{ height: 21, width: 21 }}
                                        >
                                          <IconInfoCircle
                                            size={14}
                                            stroke={1}
                                          />
                                        </Center>
                                      </HoverCard.Target>
                                    </HoverCard>
                                  </Group>
                                }
                                color="pink.6"
                                withCloseButton={false}
                                icon={
                                  <Avatar color="pink.6" radius="xl" size="md">
                                    <IconShieldX stroke={1} />
                                  </Avatar>
                                }
                              ></Notification>
                            </UnstyledButton>
                          )}
                          {shadowIntegrations > 0 && (
                            <UnstyledButton
                              pb="md"
                              onClick={() =>
                                setFilterConditions([
                                  "shadow-yes",
                                  "sessions-active",
                                ])
                              }
                            >
                              <Notification
                                title={
                                  <Group spacing={8}>
                                    {shadowIntegrations > 1 ? (
                                      <Text
                                        weight={400}
                                      >{`${shadowIntegrations} unapproved shadow integrations have active connections`}</Text>
                                    ) : (
                                      <Text
                                        weight={400}
                                      >{`${shadowIntegrations} unapproved shadow integration has active connections`}</Text>
                                    )}
                                    <HoverCard
                                      width="300px"
                                      position="top"
                                      withArrow
                                      shadow="md"
                                    >
                                      <HoverCard.Dropdown
                                        sx={{ pointerEvents: "none" }}
                                      >
                                        <Text size="sm">
                                          Shadow integrations have not been
                                          approved / installed by a Salesforce
                                          administrator. The permissions granted
                                          to such integrations are not known.
                                        </Text>
                                      </HoverCard.Dropdown>
                                      <HoverCard.Target>
                                        <Center
                                          style={{ height: 21, width: 21 }}
                                        >
                                          <IconInfoCircle
                                            size={14}
                                            stroke={1}
                                          />
                                        </Center>
                                      </HoverCard.Target>
                                    </HoverCard>
                                  </Group>
                                }
                                color="pink.6"
                                withCloseButton={false}
                                icon={
                                  <Avatar color="pink.6" radius="xl" size="md">
                                    <IconShieldX stroke={1} />
                                  </Avatar>
                                }
                              ></Notification>
                            </UnstyledButton>
                          )}
                          {classifiedDataFlowHighRiskIntegrations > 0 && (
                            <UnstyledButton
                              pb="md"
                              onClick={() =>
                                setFilterConditions([
                                  "usage-classified",
                                  "risk-2",
                                ])
                              }
                            >
                              <Notification
                                title={
                                  <Group spacing={8}>
                                    {classifiedDataFlowHighRiskIntegrations >
                                    1 ? (
                                      <Text
                                        weight={400}
                                      >{`${classifiedDataFlowHighRiskIntegrations} high risk integrations are pulling sensitive data`}</Text>
                                    ) : (
                                      <Text
                                        weight={400}
                                      >{`${classifiedDataFlowHighRiskIntegrations} high risk integration is pulling sensitive data`}</Text>
                                    )}
                                    <HoverCard
                                      width="300px"
                                      position="top"
                                      withArrow
                                      shadow="md"
                                    >
                                      <HoverCard.Dropdown
                                        sx={{ pointerEvents: "none" }}
                                      >
                                        <Text size="sm">
                                          Vantyr monitors what data types each
                                          integration is pulling from your
                                          Salesforce instance, and classifies
                                          them based on Salesforce data-type
                                          metadata and an internal Vantyr
                                          knowledge base.
                                        </Text>
                                      </HoverCard.Dropdown>
                                      <HoverCard.Target>
                                        <Center
                                          style={{ height: 21, width: 21 }}
                                        >
                                          <IconInfoCircle
                                            size={14}
                                            stroke={1}
                                          />
                                        </Center>
                                      </HoverCard.Target>
                                    </HoverCard>
                                  </Group>
                                }
                                color="indigo.6"
                                withCloseButton={false}
                                icon={
                                  <Avatar
                                    color="indigo.6"
                                    radius="xl"
                                    size="md"
                                  >
                                    <IconPackgeExport stroke={1} />
                                  </Avatar>
                                }
                              ></Notification>
                            </UnstyledButton>
                          )}
                          {noDataFlowHighRiskActiveIntegrations > 0 && (
                            <UnstyledButton
                              pb="md"
                              onClick={() =>
                                setFilterConditions([
                                  "usage-none",
                                  "risk-2",
                                  "sessions-active",
                                ])
                              }
                            >
                              <Notification
                                title={
                                  <Group spacing={8}>
                                    {noDataFlowHighRiskActiveIntegrations >
                                    1 ? (
                                      <Text
                                        weight={400}
                                      >{`${noDataFlowHighRiskActiveIntegrations} unused high risk integrations have active connections to Salesforce `}</Text>
                                    ) : (
                                      <Text
                                        weight={400}
                                      >{`${noDataFlowHighRiskActiveIntegrations} unused high risk integration has active connections to Salesforce`}</Text>
                                    )}
                                    <HoverCard
                                      width="300px"
                                      position="top"
                                      withArrow
                                      shadow="md"
                                    >
                                      <HoverCard.Dropdown
                                        sx={{ pointerEvents: "none" }}
                                      >
                                        <Text size="sm">
                                          Vantyr has not detected any activity
                                          for these integrations in the last{" "}
                                          {mapData[mapDataIndex].fullData
                                            .observedPeriodInDays! > 1
                                            ? `${mapData[mapDataIndex].fullData.observedPeriodInDays} days`
                                            : "1 day"}
                                          . This could be due to the integration
                                          not being used, or the integration not
                                          being configured correctly.
                                        </Text>
                                      </HoverCard.Dropdown>
                                      <HoverCard.Target>
                                        <Center
                                          style={{ height: 21, width: 21 }}
                                        >
                                          <IconInfoCircle
                                            size={14}
                                            stroke={1}
                                          />
                                        </Center>
                                      </HoverCard.Target>
                                    </HoverCard>
                                  </Group>
                                }
                                color="gray.6"
                                withCloseButton={false}
                                icon={
                                  <Avatar
                                    color="orange.6"
                                    radius="xl"
                                    size="md"
                                  >
                                    <IconPlug stroke={1} />
                                  </Avatar>
                                }
                              ></Notification>
                            </UnstyledButton>
                          )}
                          {highRiskFewUsersIntegrations > 0 && (
                            <UnstyledButton
                              pb="md"
                              onClick={() => setFilterConditions(["risk-2"])}
                            >
                              <Notification
                                title={
                                  <Group spacing={8}>
                                    <Text
                                      weight={400}
                                    >{`${highRiskFewUsersIntegrations} high risk integrations have less than 10 users`}</Text>
                                    <HoverCard
                                      width="400px"
                                      position="top"
                                      withArrow
                                      shadow="md"
                                    >
                                      <HoverCard.Dropdown
                                        sx={{
                                          pointerEvents: "none",
                                          // backgroundColor: "#E8E8E8",
                                        }}
                                      >
                                        <Text size="sm">
                                          Vantyr’s risk score algorithm factors
                                          in the following variables:
                                          <br />
                                          <ul
                                            style={{
                                              listStyle: "inside",
                                              paddingLeft: 0,
                                            }}
                                          >
                                            <li>permissions/scopes granted</li>
                                            <li>
                                              real and potential data exposure
                                            </li>
                                            <li>
                                              users, their roles, and access
                                              levels
                                            </li>
                                            <li>
                                              frequency and nature of API calls
                                              made by the app
                                            </li>
                                            <li>
                                              approval by a Salesforce admin
                                            </li>
                                          </ul>
                                        </Text>
                                      </HoverCard.Dropdown>
                                      <HoverCard.Target>
                                        <Center
                                          style={{ height: 21, width: 21 }}
                                        >
                                          <IconInfoCircle
                                            size={14}
                                            stroke={1}
                                          />
                                        </Center>
                                      </HoverCard.Target>
                                    </HoverCard>
                                  </Group>
                                }
                                color="gray.6"
                                withCloseButton={false}
                                icon={
                                  <Avatar color="red.6" radius="xl" size="md">
                                    <IconUserExclamation stroke={1} />
                                  </Avatar>
                                }
                              ></Notification>
                            </UnstyledButton>
                          )}
                          {highRiskRecentIntegrations > 0 && (
                            <UnstyledButton
                              pb="md"
                              onClick={() => setFilterConditions(["risk-2"])}
                            >
                              <Notification
                                title={
                                  <Group spacing={8}>
                                    <Text
                                      weight={400}
                                    >{`${highRiskRecentIntegrations} high risk integrations connected in last 30 days`}</Text>
                                    <HoverCard
                                      width="400px"
                                      position="top"
                                      withArrow
                                      shadow="md"
                                    >
                                      <HoverCard.Dropdown
                                        sx={{
                                          pointerEvents: "none",
                                          // backgroundColor: "#E8E8E8",
                                        }}
                                      >
                                        <Text size="sm">
                                          Vantyr’s risk score algorithm factors
                                          in the following variables:
                                          <br />
                                          <ul
                                            style={{
                                              listStyle: "inside",
                                              paddingLeft: 0,
                                            }}
                                          >
                                            <li>permissions/scopes granted</li>
                                            <li>
                                              real and potential data exposure
                                            </li>
                                            <li>
                                              users, their roles, and access
                                              levels
                                            </li>
                                            <li>
                                              frequency and nature of API calls
                                              made by the app
                                            </li>
                                            <li>
                                              approval by a Salesforce admin
                                            </li>
                                          </ul>
                                        </Text>
                                      </HoverCard.Dropdown>
                                      <HoverCard.Target>
                                        <Center
                                          style={{ height: 21, width: 21 }}
                                        >
                                          <IconInfoCircle
                                            size={14}
                                            stroke={1}
                                          />
                                        </Center>
                                      </HoverCard.Target>
                                    </HoverCard>
                                  </Group>
                                }
                                color="gray.6"
                                withCloseButton={false}
                                icon={
                                  <Avatar color="red.6" radius="xl" size="md">
                                    <IconPlugConnected stroke={1} />
                                  </Avatar>
                                }
                              ></Notification>
                            </UnstyledButton>
                          )}
                        </SimpleGrid>
                      </Stack>
                    </Tabs.Panel>
                    <Tabs.Panel value="integrations" pt="xs">
                      <Stack spacing="xl" my="xl">
                        <SummarySalesforceIntegrations
                          fullRecord={instanceFullRecord!}
                          selection={[]}
                        />
                        {integrations?.length !== 0 && (
                          <TabNarrowSalesforceIntegrations
                            instanceFullRecord={instanceFullRecord!}
                          />
                        )}
                      </Stack>
                    </Tabs.Panel>
                    <Tabs.Panel value="data-exposure" pt="xs">
                      <Stack spacing="xl" my="xl">
                        <Group>
                          <Avatar color="indigo.6" radius="xl" size="md">
                            <IconPackages stroke={1} />
                          </Avatar>
                          <Text size="sm">
                            <strong>
                              Up to{" "}
                              {nodeSelected?.data?.exposedFieldsCountClassifiedTotal?.toLocaleString()}{" "}
                              classified data points exposed in total
                            </strong>
                            <br></br>
                            Exposed data points:{" "}
                            {nodeSelected?.data?.exposedFieldsCountPII! > 0 &&
                              `${nodeSelected?.data?.exposedFieldsCountPII.toLocaleString()} PII`}
                            {nodeSelected?.data?.exposedFieldsCountPII! > 0 &&
                              nodeSelected?.data?.exposedFieldsCountPHI! +
                                nodeSelected?.data
                                  ?.exposedFieldsCountSensitive! +
                                nodeSelected?.data?.exposedFieldsCountPCI! >
                                0 &&
                              ", "}
                            {nodeSelected?.data?.exposedFieldsCountPHI! > 0 &&
                              `${nodeSelected?.data?.exposedFieldsCountPHI.toLocaleString()} PHI`}
                            {nodeSelected?.data?.exposedFieldsCountPHI! > 0 &&
                              nodeSelected?.data?.exposedFieldsCountSensitive! +
                                nodeSelected?.data?.exposedFieldsCountPCI! >
                                0 &&
                              ", "}
                            {nodeSelected?.data?.exposedFieldsCountPCI! > 0 &&
                              `${nodeSelected?.data?.exposedFieldsCountPCI.toLocaleString()} PCI`}
                            {nodeSelected?.data?.exposedFieldsCountPCI! > 0 &&
                              nodeSelected?.data?.exposedFieldsCountSensitive! >
                                0 &&
                              ", "}
                            {nodeSelected?.data?.exposedFieldsCountSensitive! >
                              0 &&
                              `${nodeSelected?.data?.exposedFieldsCountSensitive.toLocaleString()} Business Sensitive`}{" "}
                            and{" "}
                            {nodeSelected?.data?.exposedFieldsCountUnclassified?.toLocaleString()}{" "}
                            unclassified
                          </Text>
                        </Group>

                        <Group position="apart">
                          <TextInput
                            placeholder="Search"
                            style={{ width: "260px" }}
                            onChange={(event) => {
                              setSearchCondition(event.currentTarget.value);
                              setPageExp(1);
                            }}
                            radius="xl"
                            icon={<IconSearch size={16} stroke={1} />}
                          />
                          <MultiSelect
                            style={{
                              minWidth: "260px",
                              maxWidth: "360px",
                              // maxHeight: "40px",
                            }}
                            data={[
                              {
                                value: "classification-PHI",
                                label: "PHI",
                                group: "Classification",
                              },
                              {
                                value: "classification-PII",
                                label: "PII",
                                group: "Classification",
                              },
                              {
                                value: "classification-Sensitive",
                                label: "Sensitive",
                                group: "Classification",
                              },
                              {
                                value: "classification-PCI",
                                label: "PCI",
                                group: "Classification",
                              },
                              {
                                value: "classification-Unclassified",
                                label: "Unclassified",
                                group: "Classification",
                              },
                            ]}
                            placeholder="Search"
                            defaultValue={[
                              "classification-PII",
                              "classification-PHI",
                              "classification-PCI",
                            ]}
                            // clearButtonLabel="Clear selection"
                            clearable
                            maxDropdownHeight={600}
                            onChange={(value) => {
                              setFilterConditionsClassification(value);
                              setPageExp(1);
                            }}
                            radius="xl"
                            icon={<IconHash size={16} stroke={1} />}
                          />
                        </Group>

                        <Table
                          verticalSpacing="xs"
                          horizontalSpacing={0}
                          fontSize="xs"
                          style={{ tableLayout: "fixed" }}
                          m={0}
                          p={0}
                        >
                          <thead>
                            <tr className={cx({ [classes.tableHeader]: true })}>
                              <Th
                                sorted={sortBy === "field"}
                                reversed={reverseSortDirection}
                                onSort={() => setSorting("field")}
                                style={{ textAlign: "left", minWidth: 180 }}
                                pl={20}
                              >
                                Data Field
                              </Th>

                              <Th
                                sorted={sortBy === "classification"}
                                reversed={reverseSortDirection}
                                onSort={() => setSorting("classification")}
                                style={{ textAlign: "left", width: 100 }}
                              >
                                Classifier
                              </Th>

                              <Th
                                sorted={sortBy === "count"}
                                reversed={reverseSortDirection}
                                onSort={() => setSorting("count")}
                                style={{ textAlign: "left", width: 100 }}
                              >
                                Count
                              </Th>

                              <Th
                                sorted={sortBy === "count"}
                                reversed={reverseSortDirection}
                                onSort={() => setSorting("count")}
                                style={{ textAlign: "left", width: 200 }}
                              >
                                Integrations
                              </Th>
                            </tr>
                          </thead>
                          <tbody>{rowsToDisplayExp}</tbody>
                        </Table>
                        <Divider m={0} p={0} />
                        <Container
                          pl="xl"
                          pr="xs"
                          fluid
                          style={{ widows: "100%" }}
                          m={0}
                        >
                          <PaginationControl
                            activePage={activePageExp}
                            setActivePage={setPageExp}
                            pageSize={pageSizeExposure}
                            setPageSize={setPageSizeExposure}
                            rowsNumber={rowsFilteredLengthExp}
                          />
                        </Container>
                      </Stack>
                    </Tabs.Panel>
                    <Tabs.Panel value="data-movement" pt="xs">
                      <Stack spacing="xl" my="xl">
                        <Container
                          style={{ width: "100%", textAlign: "left" }}
                          fluid
                          p={0}
                        >
                          <Group align="left">
                            {!mapData[mapDataIndex].fullData!.queriedEntities ||
                            mapData[mapDataIndex].fullData!.queriedEntities!
                              .length === 0 ? (
                              <Group>
                                <Avatar color="gray.6" radius="xl" size="md">
                                  <IconX stroke={1} />
                                </Avatar>
                                <Text size="sm">
                                  <strong>No data flow</strong>
                                  <br></br>No data movement in the observed
                                  period
                                </Text>
                              </Group>
                            ) : (
                              <Group position="apart">
                                <Group>
                                  <Avatar
                                    color="indigo.6"
                                    radius="xl"
                                    size="md"
                                  >
                                    <IconPackgeExport stroke={1} />
                                  </Avatar>
                                  <Text size="sm">
                                    <strong>
                                      {formatBytes(
                                        mapData[
                                          mapDataIndex
                                        ].fullData!.integrations!.reduce(
                                          (acc, cur) => {
                                            return (
                                              acc +
                                              (!cur.usageVolumeBytes
                                                ? 0
                                                : cur.usageVolumeBytes)
                                            );
                                          },
                                          0
                                        )
                                      )}{" "}
                                      total data pulled
                                    </strong>
                                    <br></br>
                                    Vantyr detected{" "}
                                    {piiCount! > 0 && `${piiCount} PII`}
                                    {piiCount! > 0 &&
                                      phiCount! + sensitiveCount! + pciCount! >
                                        0 &&
                                      ", "}
                                    {phiCount! > 0 && `${phiCount} PHI`}
                                    {phiCount! > 0 &&
                                      sensitiveCount! + pciCount! > 0 &&
                                      ", "}
                                    {pciCount! > 0 && `${pciCount} PCI`}
                                    {pciCount! > 0 &&
                                      sensitiveCount! > 0 &&
                                      ", "}
                                    {sensitiveCount! > 0 &&
                                      `${sensitiveCount} Business Sensitive`}{" "}
                                    data types pulled by{" "}
                                    {mapData[
                                      mapDataIndex
                                    ].fullData?.integrations?.reduce(
                                      (acc, cur) => {
                                        return (
                                          acc +
                                          (cur.usageVolumeBytes &&
                                          cur.usageVolumeBytes > 0 &&
                                          cur.classifiedFields!.some(
                                            (f: string) =>
                                              f.includes("::PII") ||
                                              f.includes("::Sensitive") ||
                                              f.includes("::PCI") ||
                                              f.includes("::PHI")
                                          )
                                            ? 1
                                            : 0)
                                        );
                                      },
                                      0
                                    )}{" "}
                                    integrations
                                  </Text>
                                </Group>
                              </Group>
                            )}
                          </Group>
                          <TableDataFlowSalesforceInstance
                            instance={mapData[mapDataIndex].fullData}
                          ></TableDataFlowSalesforceInstance>
                        </Container>
                      </Stack>
                    </Tabs.Panel>
                  </Tabs>
                </Container>
              )}
            </Aside.Section>
          </Aside>
        </Collapse>
      )}
    </>
  );
}

function ScreenIntegrationsGraph(props: ReactFlowGraphProps) {
  return (
    <ReactFlowProvider>
      <ReactFlowPro {...props} />
    </ReactFlowProvider>
  );
}

export default ScreenIntegrationsGraph;
