import { Group } from "@mantine/core";
import { useDebouncedState } from "@mantine/hooks";
import React, { useState } from "react";
import { SalesforceIntegrationsAndActivityRecord } from "../../api/generated";
import SearchInput from "../common/SearchInput";
import FilterSalesforceInstances from "./FilterSalesforceInstances";
import { TableSalesforceIntegrations } from "./TableSalesforceIntegrations";

interface TabNarrowSalesforceIntegrationsProps {
  instanceFullRecord: SalesforceIntegrationsAndActivityRecord;
}

const TabNarrowSalesforceIntegrations = ({
  instanceFullRecord,
}: TabNarrowSalesforceIntegrationsProps) => {
  const [filterConditions, setFilterConditions] = useState<string[]>([]);
  const [searchCondition, setSearchCondition] = useDebouncedState<string>(
    "",
    200
  );

  const [activePage, setActivePage] = useState(1);

  const onSearch = (searchCondition: string) => {
    setActivePage(1);
    setSearchCondition(searchCondition);
  };

  const onFilter = (filterConditions: string[]) => {
    setActivePage(1);
    setFilterConditions(filterConditions);
  };

  const [selection, setSelection] = useState([] as string[]);

  return (
    <>
      {/* <SalesforceInstanceSummary sfFullRecord={instanceFullRecord} /> */}
      <Group position="apart">
        <SearchInput
          onSearch={(searchCondition) => onSearch(searchCondition)}
        />
        <FilterSalesforceInstances
          onFilter={(filterConditions) => onFilter(filterConditions)}
          filterConditions={filterConditions}
        />
      </Group>
      <TableSalesforceIntegrations
        sfFullRecord={instanceFullRecord!}
        filterConditions={filterConditions}
        searchCondition={searchCondition}
        activePage={activePage}
        setActivePage={setActivePage}
        selection={selection}
        setSelection={setSelection}
        narrowView={true}
      />
    </>
  );
};

export default TabNarrowSalesforceIntegrations;
