import {
  Anchor,
  Avatar,
  Col,
  Grid,
  MANTINE_SIZES,
  MantineColor,
  Menu,
  Stack,
  Text,
  UnstyledButton,
} from "@mantine/core";
import { TablerIcon } from "@tabler/icons";

interface SummaryProps {
  icon: TablerIcon;
  image?: JSX.Element;
  label: string;
  subtext: string;
  color: MantineColor;
  size: "xs" | "sm" | "md" | "lg" | "xl";
  maxWidth?: string;
  linkLabel?: string;
  linkAction?: () => void;
  menu?: JSX.Element;
}

const Summary = (props: SummaryProps) => {
  const textSize =
    props.size === "xs"
      ? "xs"
      : MANTINE_SIZES[MANTINE_SIZES.indexOf(props.size) - 1];
  return (
    <Grid>
      <Col span="content">
        {props.image && (
          <Stack
            style={{
              maxWidth: props.maxWidth || "100%",
              width: "auto",
              height: "auto",
            }}
          >
            {props.image}
          </Stack>
        )}
        {props.icon && (
          <Menu
            width="200px"
            position="right-start"
            withArrow
            transitionProps={{
              transition: "pop-top-right",
            }}
            shadow="xl"
          >
            <Menu.Target>
              <UnstyledButton style={{ width: "100%" }}>
                <Avatar
                  color={props.color}
                  radius="xl"
                  size={props.size}
                  variant={props.menu ? "outline" : "light"}
                >
                  <props.icon stroke={1} />
                </Avatar>
              </UnstyledButton>
            </Menu.Target>
            {props.menu}
          </Menu>
        )}
      </Col>
      <Col span="content" style={{ width: props.maxWidth || "auto" }}>
        <Text
          size={textSize}
          lineClamp={6}
          truncate
          style={{
            textOverflow: "ellipsis",
            overflow: "hidden",
          }}
        >
          <strong>{props.label}</strong>
          <br></br>
          {props.subtext}
          {props.linkLabel && props.linkAction && " → "}
          {props.linkLabel && props.linkAction && (
            <Anchor span onClick={props.linkAction}>
              {props.linkLabel}
            </Anchor>
          )}
        </Text>
      </Col>
    </Grid>
  );
};

export default Summary;
