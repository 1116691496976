import {
  Anchor,
  Button,
  Container,
  Paper,
  PasswordInput,
  Space,
  Stack,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import { Link, Navigate } from "react-router-dom";
import { AccessApi } from "../../api/generated";
import { useAccessStore } from "../../state/store";

export function ScreenLogin() {
  const signIn = useAccessStore((state) => state.signIn);
  const signedIn = useAccessStore((state) => state.signedIn);

  interface LoginParams {
    email: string;
    password: string;
  }

  const tryLogin = async ({ email, password }: LoginParams) => {
    console.log(email);
    console.log(password);

    console.log("Logging in...");
    const accessApi = new AccessApi();
    accessApi.login({ email: email, password: password }).then(
      (rsp) => {
        console.log("Login successful");
        signIn(rsp.data);
        if (rsp.data.userRecord?.hasAcceptedTerms)
          window.location.pathname = "/applications";
        else window.location.pathname = "/terms";
      },
      (err) => {
        console.log("Login failed");
        showNotification({
          title: "Login failed",
          message: "Please check your email and password",
          color: "red",
        });
      }
    );
  };

  const loginForm = useForm({
    initialValues: {
      email: "",
      password: "",
    },
  });

  return signedIn ? (
    <Navigate to="/applications" />
  ) : (
    <Container size={420} my={40} style={{ height: "100%" }}>
      <Stack justify="space-between" style={{ height: "93%" }}>
        <Stack spacing={0}>
          <Title
            align="center"
            sx={(theme) => ({
              fontFamily: `Greycliff CF, ${theme.fontFamily}`,
              fontWeight: 900,
            })}
          >
            Welcome to Vantyr!
          </Title>
          <Paper shadow="xl" p={30} pb={36} mt={30}>
            <form onSubmit={loginForm.onSubmit((values) => tryLogin(values))}>
              <TextInput
                label="Email"
                placeholder="Your email"
                required
                radius="xl"
                {...loginForm.getInputProps("email")}
              />
              <PasswordInput
                label="Password"
                placeholder="Your password"
                required
                mt="md"
                radius="xl"
                {...loginForm.getInputProps("password")}
              />
              <Space h="sm" />
              <Button fullWidth mt="xl" type="submit" variant="outline" radius="xl">
                Sign in
              </Button>
            </form>
          </Paper>
        </Stack>
        <Text color="dimmed" size="sm" align="center" mt={5}>
          <Anchor component={Link} to="/terms" size="sm">
            Terms of Service
          </Anchor>
        </Text>
      </Stack>
    </Container>
  );
}
