import {
  Badge,
  Center,
  Container,
  createStyles,
  Divider,
  Group,
  HoverCard,
  MultiSelect,
  Table,
  Text,
  TextInput,
  UnstyledButton,
} from "@mantine/core";
import { useDebouncedState } from "@mantine/hooks";
import {
  IconChevronDown,
  IconChevronUp,
  IconHash,
  IconInfoCircle,
  IconSearch,
  IconSelector,
} from "@tabler/icons";
import React, { useState } from "react";
import { SalesforceAppMenuItemRecord } from "../../api/generated";
import { FauxDataExposureRecord } from "../../misc/toolkit_salesforce";
import { usePaginationStore } from "../../state/store";
import PaginationControl from "../common/PaginationControl";

const useStyles = createStyles((theme) => ({
  rowSelected: {
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.fn.rgba(theme.colors[theme.primaryColor][7], 0.2)
        : theme.colors[theme.primaryColor][0],
  },

  tableHeader: {
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.fn.rgba(theme.colors["gray"][8], 0.2)
        : theme.colors["gray"][0],
  },

  th: {
    padding: "0 !important",
  },

  control: {
    width: "100%",
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    },
  },

  icon: {
    width: 21,
    height: 21,
  },
}));

interface ThProps {
  children: React.ReactNode;
  reversed: boolean;
  sorted: boolean;
  style?: React.CSSProperties;
  description?: React.ReactNode;
  onSort(): void;
  position?: "left" | "right" | "center" | "apart";
  pl?: number;
  pr?: number;
}

function Th({
  children,
  reversed,
  sorted,
  onSort,
  style,
  description,
  position = "left",
  pl = 10,
  pr = 10,
}: ThProps) {
  const { classes } = useStyles();
  const Icon = sorted
    ? reversed
      ? IconChevronUp
      : IconChevronDown
    : IconSelector;
  return (
    <th style={style} className={classes.th}>
      <UnstyledButton
        onClick={onSort}
        className={classes.control}
        pl={pl}
        pr={pr}
      >
        <Group spacing={4} position="apart">
          <Group spacing={4}>
            <Text
              weight={500}
              size="xs"
              style={{ textOverflow: "ellipsis", overflow: "hidden" }}
              lineClamp={1}
            >
              {children}
            </Text>
            {description && (
              <HoverCard
                width={360}
                shadow="xl"
                withArrow
                openDelay={100}
                closeDelay={100}
                position="bottom"
                offset={20}
              >
                <HoverCard.Target>
                  <Center className={classes.icon}>
                    <IconInfoCircle size={14} stroke={1} />
                  </Center>
                </HoverCard.Target>
                <HoverCard.Dropdown>{description}</HoverCard.Dropdown>
              </HoverCard>
            )}
          </Group>
          <Center className={classes.icon}>
            <Icon size={14} stroke={1} />
          </Center>
        </Group>
      </UnstyledButton>
    </th>
  );
}

interface TableDataExposureSalesforceIntegrationProps {
  data: SalesforceAppMenuItemRecord;
}

const TableDataExposureSalesforceIntegration = (
  props: TableDataExposureSalesforceIntegrationProps
) => {
  const { data } = props;

  const { classes, cx } = useStyles();

  const [sortBy, setSortBy] = useState<keyof FauxDataExposureRecord | null>(
    "classification"
  );
  const [reverseSortDirection, setReverseSortDirection] = useState(true);

  const [filterConditions, setFilterConditions] = useState<string[]>([
    "classification-PII",
    "classification-PHI",
    "classification-PCI",
  ]);
  const [searchCondition, setSearchCondition] = useDebouncedState<string>(
    "",
    200
  );

  const setSorting = (field: keyof FauxDataExposureRecord) => {
    const reversed = field === sortBy ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortBy(field);
  };

  // let searchConditionNormalized: string | null = null;
  // if (searchCondition) {
  //   // allow for "Entity Field" search by replacing spaces with "::"
  //   // optimize search speed by converting searchCondition to lowercase only once
  //   searchConditionNormalized = searchCondition
  //     .toLowerCase()
  //     .replace(" ", "::");
  // }

  const { pageSize, setPageSize } = usePaginationStore.get(
    "integration-exposure"
  )!((state) => ({
    pageSize: state.pageSize,
    setPageSize: state.setPageSize,
  }));

  const [activePage, setPage] = useState(1);

  const uniqueDataClassifications = data?.exposedFields!.map((field) => {
    return {
      field: field.e + " :: " + field.f,
      classification: field.s ? field.s[0] : "Unclassified",
      count: field.c,
    } as FauxDataExposureRecord;
  });

  const dataExposureRows = uniqueDataClassifications!
    .filter((r) => {
      const record = r!;
      if (record.field.split("::")[1].trim().length === 0) {
        return false;
      }
      let searchOk = true;
      if (searchCondition) {
        searchOk =
          record.field
            ?.toLowerCase()
            .replace(" :: ", " ")
            .includes(searchCondition.toLowerCase()) || false;
      }
      if (
        filterConditions.length === 0 ||
        (filterConditions.length !== 0 &&
          (!filterConditions.find((c) => c.includes("classification-")) ||
            filterConditions.includes(
              `classification-${record.classification}`
            )))
      ) {
        return searchOk;
      } else {
        return false;
      }
    })
    .sort((a, b) => {
      if (!sortBy) {
        return (
          ((b!.count! || 0) - (a!.count! || 0)) *
          (reverseSortDirection ? 1 : -1)
        );
      }

      switch (sortBy) {
        case "field":
          return (
            b!.field!.localeCompare(a!.field!, "en") *
            (reverseSortDirection ? -1 : 1)
          );
        case "count":
          return (
            ((b!.count! || 0) - (a!.count! || 0)) *
            (reverseSortDirection ? 1 : -1)
          );
        case "classification":
          return (
            b!.classification!.localeCompare(a!.classification!, "en") *
            (reverseSortDirection ? -1 : 1)
          );

        default:
          return (
            b!.classification!.localeCompare(a!.classification!, "en") *
            (reverseSortDirection ? -1 : 1)
          );
      }
    });

  const dataExposureRowsFilteredLength = dataExposureRows.length;

  const pageSizeAdjusted =
    pageSize !== 0 ? pageSize! : dataExposureRowsFilteredLength;

  const dataExposureRowsToDisplay = dataExposureRows
    .slice((activePage - 1) * pageSizeAdjusted, activePage * pageSizeAdjusted)
    .map((r) => {
      const record = r!;
      return (
        <tr key={record.field} style={{ height: "30px" }}>
          <td style={{ textAlign: "left", paddingLeft: 10 }}>
            <div style={{ width: "calc(100% - 20px)" }}>
              <Text
                ml="xs"
                size="xs"
                style={{ textOverflow: "ellipsis", overflow: "hidden" }}
                lineClamp={4}
                // weight={400}
              >
                {record.field.replace(" :: ", " ")}
              </Text>
            </div>
          </td>

          <td style={{ textAlign: "left", paddingLeft: 10, paddingRight: 20 }}>
            <Badge
              radius="xl"
              size="md"
              color={
                record.classification === "PHI"
                  ? "green.6"
                  : record.classification === "PII"
                  ? "pink.6"
                  : record.classification === "Sensitive"
                  ? "grape.6"
                  : record.classification === "PCI"
                  ? "red.6"
                  : "gray.6"
              }
            >
              {record.classification}
            </Badge>
          </td>

          <td align="left" style={{ paddingLeft: 10 }}>
            <Text
              size="xs"
              // weight={400}
              // style={{ cursor: "pointer", width: 60 }}
            >
              {record.count !== -1 ? record.count : "N/A"}
            </Text>
          </td>
        </tr>
      );
    });

  const piiCount = data!.classifiedFields?.reduce((acc, cur) => {
    return cur ? acc + (cur.split("::")[2].includes("PII") ? 1 : 0) : acc;
  }, 0);

  const phiCount = data!.classifiedFields?.reduce((acc, cur) => {
    return cur ? acc + (cur.split("::")[2].includes("PHI") ? 1 : 0) : acc;
  }, 0);

  const pciCount = data!.classifiedFields?.reduce((acc, cur) => {
    return cur ? acc + (cur.split("::")[2].includes("PCI") ? 1 : 0) : acc;
  }, 0);

  const sensitiveCount = data!.classifiedFields?.reduce((acc, cur) => {
    return cur ? acc + (cur.split("::")[2].includes("Sensitive") ? 1 : 0) : acc;
  }, 0);

  return (
    <>
      {data!.exposedFieldsCountClassifiedTotal! <= 0 ? null : (
        <Container p={0} fluid>
          <Group py="xl" position="apart">
            <TextInput
              placeholder="Search"
              style={{ width: "260px" }}
              onChange={(event) => {
                setPage(1);
                setSearchCondition(event.currentTarget.value);
              }}
              radius="xl"
              icon={<IconSearch size={16} stroke={1} />}
            />
            <MultiSelect
              style={{
                minWidth: "260px",
                maxWidth: "360px",
                // maxHeight: "40px",
              }}
              data={[
                {
                  value: "classification-PHI",
                  label: "PHI",
                  group: "Classification",
                },
                {
                  value: "classification-PII",
                  label: "PII",
                  group: "Classification",
                },
                {
                  value: "classification-Sensitive",
                  label: "Sensitive",
                  group: "Classification",
                },
                {
                  value: "classification-PCI",
                  label: "PCI",
                  group: "Classification",
                },
                {
                  value: "classification-Unclassified",
                  label: "Unclassified",
                  group: "Classification",
                },
              ]}
              placeholder="Search"
              defaultValue={[
                "classification-PII",
                "classification-PHI",
                "classification-PCI",
              ]}
              // clearButtonLabel="Clear selection"
              clearable
              maxDropdownHeight={600}
              onChange={(value) => {
                setPage(1);
                setFilterConditions(value);
              }}
              radius="xl"
              icon={<IconHash size={16} stroke={1} />}
            />
          </Group>
          <Table
            verticalSpacing="xs"
            horizontalSpacing={0}
            fontSize="xs"
            style={{ tableLayout: "fixed" }}
          >
            <thead>
              <tr className={cx({ [classes.tableHeader]: true })}>
                <Th
                  sorted={sortBy === "field"}
                  reversed={reverseSortDirection}
                  onSort={() => setSorting("field")}
                  style={{ textAlign: "left", minWidth: 180 }}
                  pl={20}
                >
                  Data Field
                </Th>
                <Th
                  sorted={sortBy === "classification"}
                  reversed={reverseSortDirection}
                  onSort={() => setSorting("classification")}
                  style={{ textAlign: "left", width: 200 }}
                >
                  Classification
                </Th>
                <Th
                  sorted={sortBy === "count"}
                  reversed={reverseSortDirection}
                  onSort={() => setSorting("count")}
                  style={{ textAlign: "left", width: 200 }}
                >
                  Count
                </Th>
              </tr>
            </thead>
            <tbody>{dataExposureRowsToDisplay}</tbody>
          </Table>
          <Divider />
          <Container pl="xl" pr="xs" pt="lg" fluid>
            <PaginationControl
              activePage={activePage}
              setActivePage={setPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              rowsNumber={dataExposureRowsFilteredLength}
            />
          </Container>
        </Container>
      )}
    </>
  );
};

export default TableDataExposureSalesforceIntegration;
