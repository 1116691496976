import {
  AppShell,
  Center,
  ColorScheme,
  ColorSchemeProvider,
  MantineProvider,
  Paper,
  Text,
} from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";
import {
  Notifications,
  cleanNotifications,
  cleanNotificationsQueue,
  showNotification,
} from "@mantine/notifications";
import { IconX } from "@tabler/icons";
import axios, { AxiosHeaders, InternalAxiosRequestConfig } from "axios";
import { useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import {
  Navigate,
  Outlet,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import "./App.css";
import AppHeader from "./components/common/AppHeader";
import AppNavbar from "./components/common/AppNavbar";
import ScreenApplications from "./components/screens/ScreenApplications";
import ScreenDashboard from "./components/screens/ScreenDashboard";
import { ScreenIntegration } from "./components/screens/ScreenIntegration";
import { ScreenIntegrationSlack } from "./components/screens/ScreenIntegrationSlack";
import ScreenIntegrationsGraph from "./components/screens/ScreenIntegrationsGraph";
import { ScreenLogin } from "./components/screens/ScreenLogin";
import ScreenSalesforceInstance from "./components/screens/ScreenSalesforceInstance";
import ScreenSlackInstance from "./components/screens/ScreenSlackInstance";
import ScreenTeam from "./components/screens/ScreenTeam";
import { ScreenTerms } from "./components/screens/ScreenTerms";
import { useAccessStore, useConfigStore } from "./state/store";
import Oops from "./components/common/Oops";
import ScreenTest from "./components/screens/ScreenTest";
import SfDataPull from "./misc/toolkit_demo";
import ScreenGithubInstance from "./components/screens/ScreenGithubInstance";

const queryClient = new QueryClient();

function App() {
  const toggleColorScheme = useConfigStore((state) => state.toggleColorScheme);
  const colorScheme: ColorScheme = useConfigStore((state) => state.colorScheme);

  const { signedIn, accessToken, signOut, hasAcceptedTerms } = useAccessStore(
    (state) => ({
      signedIn: state.signedIn,
      accessToken: state.accessToken,
      signOut: state.signOut,
      hasAcceptedTerms: state.hasAcceptedTerms,
    })
  );

  useEffect(() => {
    accessToken &&
      axios.interceptors.request.use(
        (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
          (config.headers as AxiosHeaders).set(
            "Authorization",
            `Bearer ${accessToken}`
          );
          return config;
        }
      );
    axios.defaults.timeout = 600000;
  }, [accessToken]);

  useEffect(() => {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (signedIn && error.response && error.response.status === 401) {
          signOut();
          cleanNotifications();
          cleanNotificationsQueue();
          showNotification({
            title: "You've been signed out!",
            message: "Your session has expired.",
            icon: <IconX size="1.1rem" />,
            color: "red",
          });
        }
        return Promise.reject(error);
      }
    );
  }, [signOut, signedIn]);

  function PrivateOutlet() {
    return signedIn ? (
      hasAcceptedTerms ? (
        <Outlet />
      ) : (
        <Navigate to="/terms" />
      )
    ) : (
      <Navigate to="/authenticate" />
    );
  }

  console.log("App.tsx: signedIn: ", signedIn);

  return (
    <div className="App">
      <Router>
        <QueryClientProvider client={queryClient}>
          <ColorSchemeProvider
            colorScheme={colorScheme}
            toggleColorScheme={toggleColorScheme}
          >
            <MantineProvider
              theme={{
                colorScheme,
                // fontFamily: "'Poppins', sans-serif",
                fontFamily: "'Inter', sans-serif",
                primaryColor: "teal",
                primaryShade: 7,
                fontSizes: {
                  xs: "12px",
                  sm: "13px",
                  md: "15px",
                  lg: "17px",
                  xl: "19px",
                },
                spacing: {
                  xs: "11px",
                  sm: "13px",
                  md: "16px",
                  lg: "20px",
                  xl: "24px",
                },
                defaultRadius: "md",
              }}
              withGlobalStyles
              withNormalizeCSS
            >
              <Notifications />
              <ModalsProvider>
                <Paper>
                  <AppShell
                    padding="md"
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    navbar={signedIn ? <AppNavbar></AppNavbar> : <></>}
                    header={signedIn ? <AppHeader></AppHeader> : <></>}
                    styles={(theme) => ({
                      main: {
                        backgroundColor:
                          theme.colorScheme === "dark"
                            ? theme.colors.dark[8]
                            : theme.colors.gray[0],
                      },
                    })}
                  >
                    <Routes>
                      <Route path="/authenticate" element={<ScreenLogin />} />
                      <Route path="/terms" element={<ScreenTerms />} />
                      <Route path="/" element={<PrivateOutlet />}>
                        <Route
                          path="/"
                          // element={signedIn ? <ScreenApplications /> : <ScreenLogin />}
                          element={<ScreenApplications />}
                        />
                        <Route
                          path="/applications"
                          element={<ScreenApplications />}
                        />
                        <Route
                          path="/dashboard"
                          element={<ScreenDashboard />}
                        />
                        <Route path="/demopull" element={<SfDataPull />} />
                        <Route
                          path="/applications/salesforce/:instanceId"
                          element={<ScreenSalesforceInstance />}
                        />
                        <Route
                          path="/applications/salesforce/:instanceId/integrations"
                          element={
                            <Navigate to="/applications/salesforce/:instanceId" />
                          }
                        />
                        <Route
                          path="/applications/slack/:instanceId"
                          element={<ScreenSlackInstance />}
                        />
                        <Route
                          path="/applications/github/:instanceId"
                          element={<ScreenGithubInstance />}
                        />
                        <Route
                          path="/applications/slack/:instanceId/integrations"
                          element={
                            <Navigate to="/applications/slack/:instanceId" />
                          }
                        />
                        <Route
                          path="/applications/salesforce/:instanceId/integrations/:integrationId"
                          element={<ScreenIntegration />}
                        />
                        <Route
                          path="/applications/slack/:instanceId/integrations/:integrationId"
                          element={<ScreenIntegrationSlack />}
                        />
                        <Route
                          path="/integrations-map"
                          element={
                            <ScreenIntegrationsGraph
                              strength={-300}
                              distance={100}
                            />
                            // <ScreenIntegrationsGraph strength={-400} distance={140} />
                          }
                        />
                        <Route path="/team" element={<ScreenTeam />} />
                        <Route path="/test" element={<ScreenTest />} />
                        <Route path="/oops" element={<Oops />} />
                        <Route
                          path="*"
                          element={
                            <Center>
                              <Text>There's nothing here: 404!</Text>
                            </Center>
                          }
                        />
                      </Route>
                    </Routes>
                  </AppShell>
                </Paper>
              </ModalsProvider>
            </MantineProvider>
          </ColorSchemeProvider>
        </QueryClientProvider>
      </Router>
    </div>
  );
}

export default App;
