import {
  SalesforceConnectedAppOauthScopeRecord,
  SalesforceExposedFieldRecordForInstance,
  SalesforceInstanceRecord,
  SalesforceOauthTokenRecord,
  SlackInstanceActivityRecord,
} from "../../api/generated";
import { SlackIntegration } from "../../misc/toolkit_slack";
import { demoGithubInstance, demoGithubInstances } from "../../tooling/demo";

export interface AppsTree {
  label: string;
  riskLevel: number;
  id: string;
  logoUrl?: string;
  children?: AppsTree[];
  oauthTokens?: SalesforceOauthTokenRecord[];
  oauthScopes?: SalesforceConnectedAppOauthScopeRecord[];
  type?: string;
  createdBy?: string;
  createdOn?: string;
  infoUrl?: string;
  isUsingAdminAuthOnly?: boolean;
  queriedEntities?: string[];
  classifiedFields?: string[];
  usageVolumeBytes?: number;
  viewDataFlow?: boolean;
  instanceType?:
    | "slack"
    | "salesforce"
    | "m365"
    | "github"
    | "heroku"
    | "travis-ci"
    | "vantyr";
  instanceRecord?: SalesforceInstanceRecord;
  exposedFields?: SalesforceExposedFieldRecordForInstance[];
  exposedFieldsCountClassifiedTotal?: number;
  exposedFieldsCountPII?: number;
  exposedFieldsCountPHI?: number;
  exposedFieldsCountPCI?: number;
  exposedFieldsCountSensitive?: number;
  exposedFieldsCountUnclassified?: number;
  slackInstanceRecord?: SlackInstanceActivityRecord;
  slackIntegrationRecord?: SlackIntegration;
}

export const appsMapRoot: AppsTree = {
  label: "Vantyr",
  riskLevel: 0,
  id: "vantyr",
  children: [] as AppsTree[],
};

export const mockApps: AppsTree[] = [
  {
    label: "GitHub",
    riskLevel: 0,
    id: "github",
    children: demoGithubInstance.apps.map((app) => {
      return {
        id: app.id,
        label: app.label,
        riskLevel: app.riskLevel,
        logoUrl: app.logoUrl,
      } as AppsTree;
    }),
  },
];
