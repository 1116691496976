import { MultiSelect } from "@mantine/core";
import { IconHash } from "@tabler/icons";

interface FilterSlackInstancesProps {
  onFilter: (filterConditions: string[]) => void;
  defaultFilterConditions?: string[];
}

const FilterSlackInstances = ({
  onFilter,
  defaultFilterConditions = [],
}: FilterSlackInstancesProps) => {
  return (
    <MultiSelect
      style={{
        minWidth: "200px",
        maxWidth: "320px",
      }}
      data={[
        { value: "risk-3", label: "Urgent", group: "Risk level" },
        { value: "risk-2", label: "High", group: "Risk level" },
        {
          value: "risk-1",
          label: "Medium",
          group: "Risk level",
        },
        { value: "risk-0", label: "Low", group: "Risk level" },

        {
          value: "usage-none",
          label: "Not in use",
          group: "Usage",
        },
        {
          value: "usage-some",
          label: "In use",
          group: "Usage",
        },

        {
          value: "sessions-active",
          label: "Active",
          group: "User sessions",
        },
        {
          value: "sessions-inactive",
          label: "Inactive",
          group: "User sessions",
        },

        {
          value: "shadow-yes",
          label: "Not approved",
          group: "Approved by Slack admin",
        },
        {
          value: "shadow-no",
          label: "Approved",
          group: "Approved by Slack admin",
        },
      ]}
      placeholder="Filter"
      defaultValue={defaultFilterConditions}
      // clearButtonLabel="Clear selection"
      clearable
      maxDropdownHeight={600}
      onChange={(filterConditions) => {
        onFilter(filterConditions);
      }}
      transitionProps={{
        transition: "pop-top-left",
        timingFunction: "ease",
      }}
      radius="xl"
      icon={<IconHash size={16} stroke={1} />}
    />
  );
};

export default FilterSlackInstances;
