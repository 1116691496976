import { NavLink, Text, ThemeIcon, UnstyledButton } from "@mantine/core";
import {
  Icon360,
  IconActivity,
  IconApps,
  IconChartDots3,
  IconCornerDownRight,
  IconUsers,
  TablerIcon,
} from "@tabler/icons";
import { useQuery } from "react-query";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { SalesforceApi, SlackApi } from "../../api/generated";
import {
  openOrConnectToSalesforce,
  sfInstanceDescriptionShort,
} from "../../misc/toolkit_salesforce";
import { openOrConnectToSlack } from "../../misc/toolkit_slack";
import { useAppsStore } from "../../state/store";
import {
  demoGithubInstances,
  demoInstances,
  demoMode,
  demoSlackInstances,
} from "../../tooling/demo";

interface NavLinkMenuItem {
  label: string;
  icon: TablerIcon;
  link: string;
  omitWhenClean?: boolean;
  disabled?: boolean;
}

interface NavLinkAppMenuItem {
  instanceId: string;
  label: string;
  shortDescription: string;
  onClick: () => void;
}

const menus: NavLinkMenuItem[] = [
  {
    label: "Dashboard",
    icon: IconActivity,
    link: "/dashboard",
    disabled: true,
  },
  { label: "Applications", icon: IconApps, link: "/applications" },
  {
    label: "Integrations Map",
    icon: IconChartDots3,
    link: "/integrations-map",
    omitWhenClean: true,
  },
  { label: "Sentry Explorer", icon: Icon360, link: "/sentry", disabled: true },
  { label: "Team", icon: IconUsers, link: "/team" },
];

const AppNavLinks = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const instanceSelected = useAppsStore((state) => state.instanceSelected);
  const slackInstanceSelected = useAppsStore(
    (state) => state.slackInstanceSelected
  );
  const githubInstanceSelected = useAppsStore(
    (state) => state.githubInstanceSelected
  );

  const { data } = useQuery("available_instances_menu", async () => {
    if (demoMode) {
      const salesforceInstances: NavLinkAppMenuItem[] = demoInstances.map(
        (instance) => {
          return {
            instanceId: instance.instanceId!,
            label: "Salesforce",
            shortDescription: sfInstanceDescriptionShort(instance),
            onClick: () => openOrConnectToSalesforce(instance, navigate),
          };
        }
      );

      const slackInstances: NavLinkAppMenuItem[] = demoSlackInstances.map(
        (instance) => {
          return {
            instanceId: instance.instanceId!,
            label: "Slack",
            shortDescription: instance.enterpriseName!,
            onClick: () => openOrConnectToSlack(instance, navigate),
          };
        }
      );

      const githubInstances: NavLinkAppMenuItem[] = demoGithubInstances.map(
        (instance) => {
          return {
            instanceId: instance.instanceId!,
            label: "GitHub",
            shortDescription: instance.enterpriseName!,
            onClick: () =>
              navigate(`/applications/github/${instance.instanceId}`),
          };
        }
      );

      return [...salesforceInstances, ...slackInstances, ...githubInstances];
    }

    const rspSalesforce = (await new SalesforceApi().listInstances()).data;
    const rspSlack = (await new SlackApi().slackListInstances()).data;

    const salesforceInstances: NavLinkAppMenuItem[] = rspSalesforce.map(
      (instance) => {
        return {
          instanceId: instance.instanceId!,
          label: "Salesforce",
          shortDescription: sfInstanceDescriptionShort(instance),
          onClick: () => openOrConnectToSalesforce(instance, navigate),
        };
      }
    );

    const slackInstances: NavLinkAppMenuItem[] = rspSlack.map((instance) => {
      return {
        instanceId: instance.instanceId!,
        label: "Slack",
        shortDescription: instance.enterpriseName!,
        onClick: () => openOrConnectToSlack(instance, navigate),
      };
    });

    return [...salesforceInstances, ...slackInstances];
  });

  const isClean = !(data && data.length > 0);

  const appMenus = data!;

  return (
    <>
      {menus.map((navlink) => {
        if (navlink.omitWhenClean && isClean) {
          return null;
        }

        const isSelected = location.pathname === navlink.link!;

        return (
          <>
            <NavLink
              key={navlink.label}
              variant="light"
              pl="xl"
              py="md"
              label={
                <Text px="xs" weight={isSelected ? 600 : 500}>
                  {navlink.label}
                </Text>
              }
              icon={
                <ThemeIcon variant="light" color={isSelected ? "teal" : "gray"}>
                  <navlink.icon stroke={1} />
                </ThemeIcon>
              }
              component={Link}
              to={navlink.link!}
              active={isSelected}
              disabled={navlink.disabled}
            ></NavLink>
            {navlink.label === "Applications" &&
              appMenus &&
              appMenus.length > 0 && (
                <>
                  {appMenus.map((instance) => {
                    const isSelected =
                      instanceSelected?.instanceId === instance.instanceId ||
                      slackInstanceSelected?.instanceId ===
                        instance.instanceId ||
                      githubInstanceSelected?.instanceId ===
                        instance.instanceId;

                    return (
                      <UnstyledButton
                        onClick={instance.onClick}
                        style={{ width: "100%" }}
                      >
                        <NavLink
                          key={instance.instanceId}
                          variant="light"
                          pl="55px"
                          py="10px"
                          label={
                            <Text px="xs" weight={isSelected ? 600 : 500}>
                              {instance.label}
                            </Text>
                          }
                          description={
                            <Text px="xs" weight={500}>
                              {instance.shortDescription}
                            </Text>
                          }
                          icon={<IconCornerDownRight stroke={1} color="gray" />}
                          active={isSelected}
                        ></NavLink>
                      </UnstyledButton>
                    );
                  })}
                </>
              )}
          </>
        );
      })}
    </>
  );
};

export default AppNavLinks;
