import { ActionIcon, Tabs, Text, useMantineTheme } from "@mantine/core";
import { TablerIcon } from "@tabler/icons";

export interface PillTabConfig {
  value: string;
  icon: TablerIcon;
  label: string;
}

interface PillTabsProps {
  children?: React.ReactNode;
  tabs: PillTabConfig[];
  activeTab: string;
  setActiveTab: (tab: string) => void;
  grow?: boolean;
  narrowView?: boolean;
}

export const PillTabs = ({
  children,
  tabs,
  activeTab,
  setActiveTab,
  grow,
  narrowView,
}: PillTabsProps) => {
  const theme = useMantineTheme();
  const darkTheme = theme.colorScheme === "dark";

  const tabFontColor = (thisTab: string) =>
    activeTab === thisTab ? "teal.6" : darkTheme ? "gray.5" : "gray.9";

  const tabFontWeight = (thisTab: string) =>
    activeTab === thisTab ? 600 : 400;

  return (
    <Tabs
      variant="pills"
      color={darkTheme ? "dark.6" : "teal.0"}
      radius="xl"
      value={activeTab}
      onTabChange={setActiveTab}
    >
      <Tabs.List grow={grow}>
        {tabs.map((tab) => (
          <Tabs.Tab
            py={6}
            pl={narrowView ? 10 : 20}
            pr={narrowView ? 14 : 28}
            m={0}
            value={tab.value}
            icon={
              <ActionIcon variant="transparent" color={tabFontColor(tab.value)}>
                <tab.icon size="1.5rem" stroke={1} />
              </ActionIcon>
            }
          >
            <Text
              p={0}
              m={0}
              color={tabFontColor(tab.value)}
              weight={tabFontWeight(tab.value)}
            >
              {tab.label}
            </Text>
          </Tabs.Tab>
        ))}
      </Tabs.List>
      {children}
    </Tabs>
  );
};

export default PillTabs;
