import { Navbar } from "@mantine/core";
import { useAccessStore } from "../../state/store";
import AppNavLinks from "./AppNavLinks";
import { UserButton } from "./UserButton";

const AppNavbar = () => {
  const signOut = useAccessStore((state) => state.signOut);

  const logout = () => {
    signOut();
    window.location.pathname = "/";
  };

  return (
    <Navbar
      width={{ sm: 250 }}
      style={{
        borderRightWidth: 0,
        boxShadow:
          "rgba(0, 0, 0, 0.05) 0px 1px 3px 0px, rgba(0, 0, 0, 0.05) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px",
      }}
      px="0"
      pt="xl"
      mx="0"
    >
      {/* <Navbar.Section grow component={ScrollArea}> */}
      <Navbar.Section grow>
        <AppNavLinks></AppNavLinks>
      </Navbar.Section>

      <Navbar.Section pb="xs" m="sm">
        <UserButton signOut={logout} />
      </Navbar.Section>
    </Navbar>
  );
};

export default AppNavbar;
