import { ColorScheme } from "@mantine/core";
import { StateCreator, create } from "zustand";
import { persist, PersistOptions } from "zustand/middleware";
import {
  SalesforceInstanceRecord,
  SlackInstanceRecord,
  UserLoginResultRecord,
} from "../api/generated";

const tables = [
  "integration-exposure",
  "integration-exposure-aside",
  "integration-flow",
  "integration-flow-aside",
  "instance-integrations",
  "instance-integrations-aside",
  "instance-exposure",
  "instance-exposure-aside",
  "instance-flow",
  "instance-flow-aside",
  "slack-instance-integrations",
  "github-instance-integrations",
] as const;

export type VantyrTable = (typeof tables)[number];

type PaginationStore = {
  pageSize: number;
  setPageSize: (item: number) => void;
};

export const usePaginationStore = new Map(
  tables.map((table) => [
    table,
    create<PaginationStore>()(
      persist(
        (set) => ({
          pageSize: 25,
          setPageSize: (item: number) => set({ pageSize: item }),
        }),
        {
          name: `pagination_${table}`,
        }
      )
    ),
  ])
);

type TabStore = {
  salesforceInstanceTab: string;
  setSalesforceInstanceTab: (tab: string) => void;
  slackInstanceTab: string;
  setSlackInstanceTab: (tab: string) => void;
};

type TabPersist = (
  config: StateCreator<TabStore>,
  options: PersistOptions<TabStore>
) => StateCreator<TabStore>;

export const useTabStore = create<TabStore>(
  (persist as unknown as TabPersist)(
    (set) => ({
      salesforceInstanceTab: "summary",
      setSalesforceInstanceTab: (tab: string) =>
        set({ salesforceInstanceTab: tab }),
      slackInstanceTab: "summary",
      setSlackInstanceTab: (tab: string) => set({ slackInstanceTab: tab }),
    }),
    { name: "tab_store" }
  )
);

type ConfigStore = {
  colorScheme: ColorScheme;
  toggleColorScheme: () => void;
  dataPullMode: boolean;
  toggleDataPullMode: () => void;
};

type ConfigPersist = (
  config: StateCreator<ConfigStore>,
  options: PersistOptions<ConfigStore>
) => StateCreator<ConfigStore>;

export const useConfigStore = create<ConfigStore>(
  (persist as unknown as ConfigPersist)(
    (set) => ({
      colorScheme: "light",
      toggleColorScheme: () =>
        set((state: { colorScheme: ColorScheme }) => ({
          colorScheme: state.colorScheme === "dark" ? "light" : "dark",
        })),
      dataPullMode: false,
      toggleDataPullMode: () =>
        set((state: { dataPullMode: boolean }) => ({
          dataPullMode: !state.dataPullMode,
        })),
    }),
    { name: "user_config" }
  )
);

type AccessStore = {
  signedIn: boolean;
  accessToken: string | null;
  userId: string | null;
  userRole: string | null;
  hasAcceptedTerms: boolean;
  signIn: (loginResultRecord: UserLoginResultRecord) => void;
  signOut: () => void;
  acceptTerms: () => void;
};

type SignInPersist = (
  config: StateCreator<AccessStore>,
  options: PersistOptions<AccessStore>
) => StateCreator<AccessStore>;

export const useAccessStore = create<AccessStore>(
  (persist as unknown as SignInPersist)(
    (set) => ({
      signedIn: false,
      accessToken: null,
      userId: null,
      userRole: null,
      hasAcceptedTerms: false,
      signIn: (loginResultRecord: UserLoginResultRecord) => {
        set(() => ({
          signedIn: true,
          accessToken: loginResultRecord.tokenString,
          userId: loginResultRecord.userRecord?.id,
          userRole: loginResultRecord.userRecord?.role,
          hasAcceptedTerms: loginResultRecord.userRecord?.hasAcceptedTerms,
        }));
      },
      signOut: () => {
        set(() => ({
          signedIn: false,
          accessToken: null,
          userId: null,
          userRole: null,
          hasAcceptedTerms: false,
        }));
      },
      acceptTerms: () => {
        set(() => ({
          hasAcceptedTerms: true,
        }));
      },
    }),
    { name: "user_access" }
  )
);

type AppsStore = {
  integrationSelected: string | null;
  setIntegrationSelected: (integrationName: string | null) => void;
  instanceSelected: SalesforceInstanceRecord | null;
  setInstanceSelected: (instance: SalesforceInstanceRecord | null) => void;
  slackInstanceSelected: SlackInstanceRecord | null;
  setSlackInstanceSelected: (instance: SlackInstanceRecord | null) => void;
  githubInstanceSelected: SlackInstanceRecord | null;
  setGithubInstanceSelected: (instance: SlackInstanceRecord | null) => void;
};

export const useAppsStore = create<AppsStore>((set) => ({
  integrationSelected: null,
  setIntegrationSelected(integrationName: string | null) {
    set(() => ({
      integrationSelected: integrationName,
    }));
  },
  instanceSelected: null,
  setInstanceSelected(instance: SalesforceInstanceRecord | null) {
    set(() => ({
      instanceSelected: instance,
    }));
  },
  slackInstanceSelected: null,
  setSlackInstanceSelected(instance: SlackInstanceRecord | null) {
    set(() => ({
      slackInstanceSelected: instance,
    }));
  },
  githubInstanceSelected: null,
  setGithubInstanceSelected(instance: SlackInstanceRecord | null) {
    console.log(
      "setting github instance: ",
      instance?.enterpriseName || "null"
    );
    set(() => ({
      githubInstanceSelected: instance,
    }));
  },
}));
