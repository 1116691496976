// Description: Useful functions for dealing with Salesforce data and APIs

import { Image } from "@mantine/core";
import { IconCloudOff, IconSquareRoundedLetterX } from "@tabler/icons";
import {
  SalesforceApi,
  SalesforceInstanceRecord,
  SalesforceIntegrationsAndActivityRecord,
} from "../api/generated";
import { ProductLogoAssets } from "../components/common/ProductLogoAssets";
import { NavigateFunction } from "react-router-dom";
import { daysSinceTs } from "./toolkit_common";
import { parse } from "fecha";
import { VANTYR_API_DATETIME_FORMAT } from "../constants/constants";

export interface FauxDataExposureRecord {
  field: string;
  classification: "PHI" | "PII" | "PCI" | "Sensitive" | "Unclassified";
  count: number;
}

export interface DataExposureRecordForInstance {
  field: string;
  classification: "PHI" | "PII" | "PCI" | "Sensitive" | "Unclassified";
  count: number;
  integrations: string[];
}

function fetchSfIntegrationImageSrc(integration: any) {
  return (
    integration.iconUrl ||
    integration.logoUrl ||
    ProductLogoAssets.get(
      integration.instanceType ?? integration.label?.toLowerCase() ?? ""
    )
    // ProductLogoAssets.get("apprandom")
  );
}

interface SfIntegrationLogoProps {
  integration: any;
  width?: number;
  height?: number;
}

export function SfIntegrationLogo({
  integration,
  width,
  height,
}: SfIntegrationLogoProps) {
  const imageSrc = fetchSfIntegrationImageSrc(integration);
  return !imageSrc ? (
    <IconSquareRoundedLetterX stroke={0.7} color="gray" size={width} />
  ) : (
    <Image
      src={imageSrc}
      fit="contain"
      width={width}
      height={height}
      style={{ backgroundColor: "#ffffff" }}
      withPlaceholder
      placeholder={<IconCloudOff stroke={0.7} size={width} />}
    />
  );
}

export function sfInstanceLabelShort(instance: SalesforceInstanceRecord) {
  return `Salesforce ${instance.orgType
    ?.match(/(\b\S)?/g)
    ?.join("")
    .toUpperCase()} @ ${instance.orgName}`;
}

export function sfInstanceDescriptionShort(instance: SalesforceInstanceRecord) {
  return `${instance.orgType
    ?.match(/(\b\S)?/g)
    ?.join("")
    .toUpperCase()} @ ${instance.orgName}`;
}

export function sfInstanceLabel(instance: SalesforceInstanceRecord) {
  return `Salesforce ${instance.orgType} @ ${instance.orgName}`;
}

export function isSalesforceInstanceRecord(
  object: unknown
): object is SalesforceInstanceRecord {
  return Object.prototype.hasOwnProperty.call(object, "orgNamespacePrefix");
}

export const requestAccessSalesforce = async () => {
  new SalesforceApi()
    .salesforceRequestAccess()
    .then((rsp) => {
      window.location.href = rsp.data as unknown as string;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const openOrConnectToSalesforce = async (
  instance: SalesforceInstanceRecord,
  navigate: NavigateFunction
) => {
  if (instance.connected) {
    navigate(`/applications/salesforce/${instance.instanceId}`);
  } else {
    requestAccessSalesforce();
  }
};

export const daysSinceSfTs = (ts: string) =>
  daysSinceTs(
    (parse(ts, VANTYR_API_DATETIME_FORMAT)?.getTime() || Date.now()) / 1000
  );

export const breakdownRisk = (
  instanceRecord: SalesforceIntegrationsAndActivityRecord
): number[] => {
  const breakdown = [0, 0, 0, 0];
  instanceRecord.integrations!.forEach((i) => {
    breakdown[i.riskLevel!] += 1;
  });
  return breakdown;
};

const RISK_COLORS = ["gray.7", "yellow.7", "red.7", "pink.7"];
const RISK_COLORS_ALT = ["gray.5", "yellow.7", "red.7", "pink.7"];
const RISK_LABELS = ["Low", "Medium", "High", "Urgent"];

export const riskColor = (riskLevel: number) => RISK_COLORS[riskLevel];
export const riskColorAlt = (riskLevel: number) => RISK_COLORS_ALT[riskLevel];
export const riskLabel = (riskLevel: number) => RISK_LABELS[riskLevel];
