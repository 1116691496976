import {
  forceLink,
  forceManyBody,
  forceSimulation,
  forceX,
  forceY,
  SimulationLinkDatum,
  SimulationNodeDatum,
} from "d3-force";
import { hierarchy, HierarchyNode } from "d3-hierarchy";
import { useEffect } from "react";
import { Node, useReactFlow } from "reactflow";
import {
  SalesforceInstanceRecord,
  SalesforceIntegrationsAndActivityRecord,
  SlackInstanceActivityRecord,
} from "../../api/generated";
import { sfInstanceLabelShort } from "../../misc/toolkit_salesforce";
import { appsMapRoot, AppsTree, mockApps } from "./AppsTree";
import {
  slackGetIntegrationsFromUserRecords,
  slackInstanceDescription,
  slackIntegrationIsByVantyr,
} from "../../misc/toolkit_slack";
import { demoMode } from "../../tooling/demo";

type SimNode = HierarchyNode<{
  id: string;
  label: string;
  riskLevel: number;
  logoUrl?: string;
}> &
  SimulationNodeDatum;

function getNode(n: SimNode) {
  return {
    id: n.data.id,
    position: { x: n.x, y: n.y },
    data: n.data,
    type: "circle",
  } as Node;
}

function getEdge(e: any, viewDataFlow: boolean) {
  const source = e.source.data.id;
  const target = e.target.data.id;
  const targetNode = e.target;
  return {
    id: `${source}=>${target}`,
    source,
    target,
    type: "straight",
    style: {
      stroke:
        viewDataFlow && e.target.data.usageVolumeBytes > 0
          ? "#4c6ef5"
          : "#cacbcc",
      strokeWidth:
        viewDataFlow && e.target.data.usageVolumeBytes === 0 ? 0.7 : 1,
    },
    interactionWidth: 20,
    targetNode,
  };
}

export interface MapData {
  instance: SalesforceInstanceRecord;
  fullData: SalesforceIntegrationsAndActivityRecord;
}

export interface TotalMapData {
  salesforceInstances: MapData[];
  slackInstances: SlackInstanceActivityRecord[];
}

type UseForceLayoutOptions = {
  strength: number;
  distance: number;
  totalMapData: TotalMapData;
  selectedCoreApps: string[];
  integrationsFilter: string;
  filterConditions: string[];
  viewDataFlow: boolean;
};

function useForceLayout({
  strength = -400,
  distance = 150,
  totalMapData,
  selectedCoreApps,
  integrationsFilter,
  filterConditions,
  viewDataFlow,
}: UseForceLayoutOptions) {
  let allApps: AppsTree = JSON.parse(JSON.stringify(appsMapRoot));

  if (demoMode) {
    allApps.children!.push(
      ...mockApps.map((app) => {
        return {
          label: app.label,
          riskLevel: app.riskLevel,
          id: app!.id!,
          children: app.children!.map((child) => {
            return {
              label: child.label,
              riskLevel: child.riskLevel,
              id: child!.id!,
              logoUrl: child.logoUrl,
            } as AppsTree;
          }),
        };
      })
    );
  }

  allApps.children!.push(
    ...totalMapData.salesforceInstances.map((data) => {
      return {
        id: data.instance.instanceId,
        label: sfInstanceLabelShort(data.instance),
        riskLevel: 0,
        instanceType: "salesforce",
        instanceRecord: data.instance,
        exposedFields: data.fullData.exposedFields,
        exposedFieldsCountClassifiedTotal:
          data.fullData.exposedFieldsCountClassifiedTotal,
        exposedFieldsCountPII: data.fullData.exposedFieldsCountPII,
        exposedFieldsCountPHI: data.fullData.exposedFieldsCountPHI,
        exposedFieldsCountPCI: data.fullData.exposedFieldsCountPCI,
        exposedFieldsCountSensitive: data.fullData.exposedFieldsCountSensitive,
        exposedFieldsCountUnclassified:
          data.fullData.exposedFieldsCountUnclassified,
        children: data.fullData
          .integrations!.filter((app) => app.label !== "Vantyr")
          .map((sfApp) => {
            return {
              id: sfApp.id,
              label: sfApp.label,
              riskLevel: sfApp.riskLevel,
              logoUrl: sfApp.logoUrl,
              oauthTokens: sfApp.oauthTokens,
              oauthScopes: sfApp.oauthScopes,
              type: sfApp.type,
              createdBy: sfApp.createdBy,
              createdOn: sfApp.createdOn,
              isUsingAdminAuthOnly: sfApp.isUsingAdminAuthOnly,
              infoUrl: sfApp.infoUrl,
              usageVolumeBytes: sfApp.usageVolumeBytes,
              classifiedFields: sfApp.classifiedFields,
              instanceRecord: data.instance,
            } as AppsTree;
          }),
      } as AppsTree;
    })
  );

  allApps.children?.push(
    ...totalMapData.slackInstances.map((slackInstance) => {
      return {
        id: slackInstance.instance?.instanceId!,
        label: slackInstanceDescription(slackInstance.instance!),
        riskLevel: 0,
        instanceType: "slack",
        slackInstanceRecord: slackInstance,
        children: slackGetIntegrationsFromUserRecords(slackInstance)
          .filter((x) => !slackIntegrationIsByVantyr(x))
          .map((slackApp) => {
            return {
              id: slackApp.apiAppId,
              label: slackApp.appName,
              riskLevel: slackApp.riskLevel,
              logoUrl: slackApp.iconUrl,
              slackIntegrationRecord: slackApp,
              slackInstanceRecord: slackInstance,
            };
          }),
      } as AppsTree;
    })
  );

  allApps.children =
    selectedCoreApps.length === 0
      ? allApps.children
      : allApps.children?.filter((app) => selectedCoreApps.includes(app.label));

  let filteredApps: AppsTree = allApps;

  filteredApps.children?.forEach((app) => {
    app.children = app.children?.filter((child) => {
      // if (!child.oauthTokens) {
      //   return child.label
      //     .toLowerCase()
      //     .includes(integrationsFilter.toLowerCase());
      // }

      const integrationUsage =
        child.usageVolumeBytes && child.usageVolumeBytes > 0
          ? child.classifiedFields!.filter(
              (f: string) => f.includes("::PII") || f.includes("::Sensitive")
            ).length > 0
            ? "usage-classified"
            : "usage-unclassified"
          : "usage-none";

      const integrationActive =
        child.oauthTokens?.filter((t) => t.userIsActive).length !== 0
          ? "sessions-active"
          : "sessions-inactive";

      const integrationShadow = !child.createdBy ? "shadow-yes" : "shadow-no";

      if (
        filterConditions.length === 0 ||
        (filterConditions.length !== 0 &&
          (!filterConditions.find((c) => c.includes("risk-")) ||
            filterConditions.includes(`risk-${child.riskLevel}`)) &&
          (!filterConditions.find((c) => c.includes("usage-")) ||
            filterConditions.includes(integrationUsage)) &&
          (!filterConditions.find((c) => c.includes("sessions-")) ||
            filterConditions.includes(integrationActive)) &&
          (!filterConditions.find((c) => c.includes("shadow-")) ||
            filterConditions.includes(integrationShadow)))
      ) {
        return child.label
          .toLowerCase()
          .includes(integrationsFilter.toLowerCase());
      } else {
        return false;
      }
    });
  });

  console.log("ALL APPS post filter: ", filteredApps);

  if (filteredApps.children && filteredApps.children.length === 1) {
    filteredApps = filteredApps.children[0];
  }

  filteredApps.children?.forEach((app) => {
    app.viewDataFlow = viewDataFlow;
  });

  // if (demoMode) {
  //   try {
  //     console.log("filteredApps: ", filteredApps);
  //     let hierarchizedApps = JSON.parse(
  //       JSON.stringify(filteredApps)
  //     );
  //     console.log("hierarchizedApps: ", hierarchizedApps);
  //     const lowRiskApps = filteredApps.children?.filter((app) => app.riskLevel === 0);
  //     const mediumRiskApps = filteredApps.children?.filter((app) => app.riskLevel === 1);
  //     const highRiskApps = filteredApps.children?.filter((app) => app.riskLevel === 2);
  //     const urgentRiskApps = filteredApps.children?.filter((app) => app.riskLevel === 3);
  //     hierarchizedApps.children = [
  //       {
  //         id: "risk-0",
  //         label: "Low Risk",
  //         riskLevel: 0,
  //         children: lowRiskApps,
  //       },
  //       {
  //         id: "risk-1",
  //         label: "Medium Risk",
  //         riskLevel: 1,
  //         children: mediumRiskApps,
  //       },
  //       {
  //         id: "risk-2",
  //         label: "High Risk",
  //         riskLevel: 2,
  //         children: highRiskApps,
  //       },
  //       urgentRiskApps && urgentRiskApps.length > 0 && {
  //         id: "risk-3",
  //         label: "Urgent Risk",
  //         riskLevel: 3,
  //         children: urgentRiskApps,
  //       },
  //     ];
  //     filteredApps = JSON.parse(JSON.stringify(hierarchizedApps));
  //     console.log("hierarchizedApps2: ", hierarchizedApps);
  //   } catch (e) {
  //     console.log("Error hierarchizing apps", e);
  //   }
  // }

  const root = hierarchy(filteredApps);
  const links = root.links();
  const nodes = root.descendants();
  const { setNodes, setEdges, fitView } = useReactFlow();

  useEffect(() => {
    console.log("forceSimulation", nodes);
    const simulation = forceSimulation(nodes as SimulationNodeDatum[])
      .force(
        "link",
        forceLink(links as SimulationLinkDatum<SimulationNodeDatum>[])
          .id((d: any) => d.id)
          .distance(distance)
          .strength(0.3)
      )
      .force("charge", forceManyBody().strength(strength))
      .force("x", forceX())
      .force("y", forceY());

    simulation.stop();
    simulation.tick(500);

    setNodes(nodes.map(getNode));
    setEdges(
      links.map((e) => {
        return getEdge(e, viewDataFlow);
      })
    );
    // fitView();
  }, [
    strength,
    distance,
    setNodes,
    setEdges,
    selectedCoreApps,
    integrationsFilter,
    filterConditions,
    fitView,
    links,
    nodes,
    viewDataFlow,
  ]);
}

export default useForceLayout;
