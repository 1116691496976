import { MultiSelect } from "@mantine/core";
import { IconHash } from "@tabler/icons";

interface FilterSalesforceInstancesProps {
  onFilter: (filterConditions: string[]) => void;
  filterConditions?: string[];
}

const FilterSalesforceInstances = ({
  onFilter,
  filterConditions = [],
}: FilterSalesforceInstancesProps) => {
  return (
    <MultiSelect
      style={{
        minWidth: "200px",
        maxWidth: "400px",
      }}
      data={[
        { value: "risk-3", label: "Urgent", group: "Risk level" },
        { value: "risk-2", label: "High", group: "Risk level" },
        {
          value: "risk-1",
          label: "Medium",
          group: "Risk level",
        },
        { value: "risk-0", label: "Low", group: "Risk level" },

        {
          value: "usage-none",
          label: "No data flow",
          group: "Data flow",
        },
        {
          value: "usage-classified",
          label: "Classified data flow",
          group: "Data flow",
        },
        {
          value: "usage-unclassified",
          label: "Unclassified data flow",
          group: "Data flow",
        },

        {
          value: "sessions-active",
          label: "Active",
          group: "User sessions",
        },
        {
          value: "sessions-inactive",
          label: "Inactive",
          group: "User sessions",
        },

        {
          value: "shadow-yes",
          label: "Shadow",
          group: "Approved by Salesforce admin",
        },
        {
          value: "shadow-no",
          label: "Approved",
          group: "Approved by Salesforce admin",
        },
      ]}
      placeholder="Filter"
      value={filterConditions}
      clearable
      maxDropdownHeight={600}
      onChange={(filterConditions) => {
        onFilter(filterConditions);
      }}
      transitionProps={{
        timingFunction: "ease",
        transition: "pop-top-left",
      }}
      radius="xl"
      icon={<IconHash size={16} stroke={1} />}
    />
  );
};

export default FilterSalesforceInstances;
