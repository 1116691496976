import { Center, Loader, Stack, Text } from "@mantine/core";

interface LoadingProps {
  message?: string;
}

const Loading = ({ message }: LoadingProps) => {
  return (
    <Center style={{ width: "100%", height: "100%" }}>
      <Stack align="center">
        <Text size="sm" my="md">
          {message || "Loading, please stand by ..."}
        </Text>
        <Loader color="gray" variant="dots"></Loader>
      </Stack>
    </Center>
  );
};

export default Loading;
