import { Menu, UnstyledButton, Avatar, ActionIcon, Text } from "@mantine/core";
import {
  IconCheck,
  IconDots,
  IconPlaylistX,
  IconUserX,
  IconX,
} from "@tabler/icons";
import React from "react";
import {
  SalesforceApi,
  SalesforceAppMenuItemRecord,
  SalesforceOauthTokenRecord,
} from "../../api/generated";
import { closeAllModals, openConfirmModal } from "@mantine/modals";
import { daysSinceSfTs } from "../../misc/toolkit_salesforce";
import { useQueryClient } from "react-query";
import { showNotification } from "@mantine/notifications";
import { useAccessStore } from "../../state/store";

interface MenuIntegrationActionsProps {
  integration: SalesforceAppMenuItemRecord;
  instanceId: string;
}

const MenuIntegrationActions = (props: MenuIntegrationActionsProps) => {
  const queryClient = useQueryClient();

  const openIntegrationApproveModal = (
    integration: SalesforceAppMenuItemRecord
  ) =>
    openConfirmModal({
      title: `Approve ${integration.label}?`,
      radius: "md",
      closeButtonProps: { radius: "xl" },
      cancelProps: { radius: "xl" },
      centered: true,
      children: (
        <Text size="sm">
          This action will install <strong>{integration.name}</strong> in your
          Salesforce instance. You will be able to view additional details about
          the integration, including author data and the permissions it
          requires.
          <br />
          <br />
          This also enables Vantyr to more precisely assess the data exposure
          and risk associated with <strong>{integration.name}</strong> and it's
          usage.
        </Text>
      ),
      labels: { confirm: "Approve", cancel: "Cancel" },
      confirmProps: { color: "green", radius: "xl" },
      onConfirm: () => {
        new SalesforceApi()
          .salesforceInstallApps(props.instanceId!, {
            integrationIds: [integration.id!],
          })
          .then((result) => {
            queryClient.invalidateQueries({
              queryKey: [`integrations_${props.instanceId}`],
            });
            closeAllModals();
            if (result.status === 200) {
              showNotification({
                title: "Integrations approved!",
                message: `${integration.label} has been successfully approved. `,
                color: "green",
              });
            } else {
              showNotification({
                title: "Oops!",
                message: `Something went wrong! The integration may not have been installed.`,
              });
            }
          });
      },
      onCancel: () => {},
    });

  const openRemoveTokensOfDeactivatedUsersModal = (
    tokens: SalesforceOauthTokenRecord[]
  ) =>
    openConfirmModal({
      title: `Revoke  ${tokens.length} sessions of deactivated users ?`,
      radius: "md",
      closeButtonProps: { radius: "xl" },
      cancelProps: { radius: "xl" },
      centered: true,
      children: (
        <Text size="sm">
          Deactivated users should not be able to access your Salesforce via
          these open sessions. It is recommended to remove them as a
          housekeeping measure.
        </Text>
      ),
      labels: { confirm: "Revoke", cancel: "Cancel" },
      confirmProps: { color: "red", radius: "xl" },
      onConfirm: () => {
        new SalesforceApi()
          .salesforceRevokeTokens(props.instanceId, {
            deleteTokens: tokens.map((token) => token.deleteToken!),
          })
          .then(() => {
            queryClient.invalidateQueries({
              queryKey: [`integration_${integration.id}`],
            });
            // setSalesforceConnected(false);
            showNotification({
              title: "Access tokens revoked successfully",
              message: `${tokens.length} access tokens have been successfully revoked. `,
              color: "green",
            });
          })
          .catch((err) => {
            showNotification({
              title: "Access token revocation may have partially failed.",
              message: err.message,
              color: "red",
            });
          });
      },
      onCancel: () => {},
    });

  const openRemoveUnusedTokensModal = (tokens: SalesforceOauthTokenRecord[]) =>
    openConfirmModal({
      title: `Revoke  ${tokens.length} unused sessions ?`,
      radius: "md",
      closeButtonProps: { radius: "xl" },
      cancelProps: { radius: "xl" },
      centered: true,
      children: (
        <Text size="sm">
          These sessions have not been used for more than a year. It is highly
          recommended to remove them as a housekeeping measure, as they remain
          open and can still be used by anyone who has access to the tokens.
        </Text>
      ),
      labels: { confirm: "Revoke", cancel: "Cancel" },
      confirmProps: { color: "red", radius: "xl" },
      onConfirm: () => {
        new SalesforceApi()
          .salesforceRevokeTokens(props.instanceId, {
            deleteTokens: tokens.map((token) => token.deleteToken!),
          })
          .then(() => {
            queryClient.invalidateQueries({
              queryKey: [`integration_${integration.id}`],
            });
            showNotification({
              title: "Access tokens revoked successfully",
              message: `${tokens.length} access tokens have been successfully revoked. `,
              color: "green",
            });
          })
          .catch((err) => {
            showNotification({
              title: "Access token revocation may have partially failed.",
              message: err.message,
              color: "red",
            });
          });
      },
      onCancel: () => {},
    });

  const openRemoveAllTokensModal = (tokens: SalesforceOauthTokenRecord[]) =>
    openConfirmModal({
      title: `Revoke all ${tokens.length} open session ?`,
      radius: "md",
      closeButtonProps: { radius: "xl" },
      cancelProps: { radius: "xl" },
      centered: true,
      children: (
        <Text size="sm">
          This will deactivate the access tokens and prevent the integration
          from accessing Salesforce data on behalf of all issuing users. Other
          tokens for other apps, issued to the same users will not be affected.
        </Text>
      ),
      labels: { confirm: "Revoke", cancel: "Cancel" },
      confirmProps: { color: "red", radius: "xl" },
      onConfirm: () => {
        new SalesforceApi()
          .salesforceRevokeTokens(props.instanceId, {
            deleteTokens: tokens.map((token) => token.deleteToken!),
          })
          .then(() => {
            queryClient.invalidateQueries({
              queryKey: [`integration_${integration.id}`],
            });
            // setSalesforceConnected(false);
            showNotification({
              title: "Access tokens revoked successfully",
              message: `${tokens.length} access tokens have been successfully revoked. `,
              color: "green",
            });
          })
          .catch((err) => {
            showNotification({
              title: "Access token revocation may have partially failed.",
              message: err.message,
              color: "red",
            });
          });
      },
      onCancel: () => {},
    });

  const { integration } = props;

  const isShadow = integration.createdOn === null;

  const tokensOfDeactivatedUsers = integration.oauthTokens?.filter(
    (token) => !token.userIsActive
  );

  const hasDeactivatedUserSessions = tokensOfDeactivatedUsers?.length! > 0;

  const hasSessions = integration.oauthTokens?.length! > 0;

  const tokensOfInactiveUsers = integration.oauthTokens?.filter(
    (token) => daysSinceSfTs(token.lastUsedOn!) > 365
  );

  const hasInactiveSessions = tokensOfInactiveUsers?.length! > 0;

  const noMenuNeeded = !isShadow && !hasDeactivatedUserSessions && !hasSessions;

  const loggedInUserRole = useAccessStore((state) => state.userRole);
  const loggedInUserIsNotAdmin = loggedInUserRole === "Viewer";

  return (
    <Menu
      width="300px"
      position="bottom-end"
      withArrow
      transitionProps={{
        transition: "pop-top-right",
        duration: 150,
      }}
      shadow="xl"
    >
      <Menu.Target>
        <ActionIcon color="teal" radius="xl" size="lg" variant="outline">
          <IconDots stroke={1} />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>
        {isShadow && (
          <Menu.Item
            disabled={loggedInUserIsNotAdmin}
            onClick={() => {
              openIntegrationApproveModal(integration);
            }}
            icon={<IconCheck size={16} stroke={1} color="green" />}
          >
            Approve {integration.label}
          </Menu.Item>
        )}
        {hasDeactivatedUserSessions && (
          <Menu.Item
            disabled={loggedInUserIsNotAdmin}
            icon={<IconUserX size={16} stroke={1} color="red" />}
            onClick={() =>
              openRemoveTokensOfDeactivatedUsersModal(tokensOfDeactivatedUsers!)
            }
          >
            {tokensOfDeactivatedUsers?.length === 1 ? (
              <span>Revoke session of 1 deactivated user</span>
            ) : (
              <span>
                Revoke sessions of {tokensOfDeactivatedUsers?.length}{" "}
                deactivated users
              </span>
            )}
          </Menu.Item>
        )}
        {hasSessions && (
          <Menu.Item
            disabled={loggedInUserIsNotAdmin}
            icon={<IconPlaylistX size={16} stroke={1} color="red" />}
            onClick={() => openRemoveAllTokensModal(integration.oauthTokens!)}
          >
            {integration.oauthTokens?.length === 1 ? (
              <span>Revoke 1 active session</span>
            ) : (
              <span>
                Revoke all {integration.oauthTokens?.length} open sessions
              </span>
            )}
          </Menu.Item>
        )}
        {hasInactiveSessions && (
          <Menu.Item
            disabled={loggedInUserIsNotAdmin}
            icon={<IconX size={16} stroke={1} color="red" />}
            onClick={() => openRemoveUnusedTokensModal(tokensOfInactiveUsers!)}
          >
            {tokensOfInactiveUsers?.length === 1 ? (
              <span>Revoke 1 unused session (1y+)</span>
            ) : (
              <span>
                Revoke {tokensOfInactiveUsers?.length} unused sessions (1y+)
              </span>
            )}
          </Menu.Item>
        )}
      </Menu.Dropdown>
    </Menu>
  );
};

export default MenuIntegrationActions;
