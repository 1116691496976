import { Anchor, Breadcrumbs, Text } from "@mantine/core";
import { Link, useLocation } from "react-router-dom";
import { sfInstanceDescriptionShort } from "../../misc/toolkit_salesforce";
import { useAppsStore } from "../../state/store";
import { slackInstanceDescription } from "../../misc/toolkit_slack";

const NavBreadcrumbs = () => {
  let location = useLocation();

  const integrationSelected = useAppsStore(
    (state) => state.integrationSelected
  );

  const setIntegrationSelected = useAppsStore(
    (state) => state.setIntegrationSelected
  );

  const instanceSelected = useAppsStore((state) => state.instanceSelected);
  const slackInstanceSelected = useAppsStore(
    (state) => state.slackInstanceSelected
  );

  const githubInstanceSelected = useAppsStore(
    (state) => state.githubInstanceSelected
  );

  const setInstanceSelected = useAppsStore(
    (state) => state.setInstanceSelected
  );

  const setSlackInstanceSelected = useAppsStore(
    (state) => state.setSlackInstanceSelected
  );

  const setGithubInstanceSelected = useAppsStore(
    (state) => state.setGithubInstanceSelected
  );

  let integrationSplit = location.pathname.split("/integrations/");
  let integrationId = integrationSplit.length > 1 ? integrationSplit[1] : null;
  let instanceSplit = location.pathname.split("/applications/salesforce/");
  let instanceId =
    instanceSplit.length > 1 ? instanceSplit[1].split("/")[0] : null;

  let slackInstanceSlip = location.pathname.split("/applications/slack/");
  let slackInstanceId =
    slackInstanceSlip.length > 1 ? slackInstanceSlip[1].split("/")[0] : null;

  let githubInstanceSlip = location.pathname.split("/applications/github/");
  let githubInstanceId =
    githubInstanceSlip.length > 1 ? githubInstanceSlip[1].split("/")[0] : null;

  !integrationId && setIntegrationSelected("...");

  !instanceId && setInstanceSelected(null);

  !slackInstanceId && setSlackInstanceSelected(null);

  !githubInstanceId && setGithubInstanceSelected(null);

  const instanceIdFromUrl = instanceId || slackInstanceId || githubInstanceId;

  return (
    <>
      <Breadcrumbs p={0} separator={<Text size="sm">{">"}</Text>}>
        {location.pathname
          .substring(1)
          .split("/")
          .filter(
            (breadcrumb) =>
              breadcrumb !== "integrations" && breadcrumb !== "salesforce"
          )
          .map((breadcrumb, index) => {
            return (
              <Anchor
                component={Link}
                to={location.pathname.split(breadcrumb)[0].concat(breadcrumb)}
                key={index}
                size="sm"
                style={
                  integrationId && breadcrumb === integrationId
                    ? { textTransform: "none" }
                    : { textTransform: "capitalize" }
                }
              >
                {integrationId && breadcrumb === integrationId
                  ? integrationSelected
                  : instanceIdFromUrl && breadcrumb === instanceIdFromUrl
                  ? instanceSelected
                    ? sfInstanceDescriptionShort(instanceSelected)
                    : slackInstanceSelected
                    ? slackInstanceDescription(slackInstanceSelected)
                    : githubInstanceSelected
                    ? "GitHub Enterprise @ " +
                      githubInstanceSelected.enterpriseName
                    : "..."
                  : breadcrumb.replaceAll("-", " ")}
              </Anchor>
            );
          })}
      </Breadcrumbs>
    </>
  );
};

export default NavBreadcrumbs;
