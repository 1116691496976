import {
  SalesforceInstanceRecord,
  SalesforceIntegrationsAndActivityRecord,
  SlackInstanceActivityRecord,
  SlackInstanceRecord,
  UserRecord,
} from "../api/generated";

import sfData from "./demodata/demoSalesforce.json";
import slackData from "./demodata/demoSlack.json";

// export const demoMode: boolean = false;

const localhostDemo = false; // change this if you want to test demo mode locally
const host = window.location.hostname;
const demoHost = "demo.vantyr.com";
const localhost = "localhost";

console.log(host);

export const demoMode: boolean =
  host === demoHost || (host === localhost && localhostDemo);

export const demoInstances: SalesforceInstanceRecord[] = [
  {
    instanceId: "d68f358f-b1e2-4edf-9d31-fe06777b583a",
    instanceUrl: "https://acmecorp.my.salesforce.com",
    orgNamespacePrefix: "acmecorp",
    orgName: "Acme Corp",
    orgType: "Ultimate Edition",
    connected: true,
  },
];

export const demoSlackInstances: SlackInstanceRecord[] = [
  {
    instanceId: "f3fe41f2-72af-4e59-8dd4-3d50f7773569",
    billingPlan: "compliance",
    emailDomain: "acme.com",
    enterpriseDomain: "acme.com",
    enterpriseName: "Acme Corp",
    enterpriseIconUrl: "https://acme.com/logo.png",
    enterpriseId: "123TFMMYKQU8",
    enterpriseUrl: "https://acme.slack.com",
    isConnected: true,
    isDiscoverable: true,
    isVerified: true,
    locale: "en-US",
  },
];

export const demoGithubInstances: SlackInstanceRecord[] = [
  {
    instanceId: "f38100f2-11af-4k19-8dd4-3d50f7773569",
    billingPlan: "Enterprise",
    emailDomain: "acme.com",
    enterpriseDomain: "acme.com",
    enterpriseName: "Acme Corp",
    enterpriseIconUrl: "https://acme.com/logo.png",
    enterpriseId: "demo",
    enterpriseUrl: "https://acme.slack.com",
    isConnected: true,
    isDiscoverable: true,
    isVerified: true,
    locale: "en-US",
  },
];

export const demoUser: UserRecord = {
  id: "123",
  fullName: "John Dover",
  email: "john@acme.com",
  hasAcceptedTerms: true,
  role: "Admin",
};

export const demoUsers: UserRecord[] = [
  {
    id: "123",
    fullName: "John Dover",
    email: "john@acme.com",
    hasAcceptedTerms: true,
    role: "Admin",
  },
  {
    id: "456",
    fullName: "Jane Appleseed",
    email: "jane@acme.com",
    hasAcceptedTerms: true,
    role: "Admin",
  },
  {
    id: "789",
    fullName: "Gregory House",
    email: "gregory@acme.com",
    hasAcceptedTerms: false,
    role: "Viewer",
  },
];

export const demoInstanceFullRecord: SalesforceIntegrationsAndActivityRecord =
  sfData;

export const demoInstanceSlack: SlackInstanceActivityRecord =
  slackData as SlackInstanceActivityRecord;

export interface GithubDemoRecord {
  instance: SlackInstanceRecord;
  apps: GithubDemoAppRecord[];
}

export interface GithubDemoAppRecord {
  logoUrl?: string;
  label: string;
  id: string;
  riskLevel: number;
  users: number;
  access: string[];
  repos: string[];
  installedBy?: string;
  installedOn?: string;
}

export const inflateSfUsers = (activeUsersCount: number): number => {
  activeUsersCount =
    activeUsersCount === 30
      ? activeUsersCount
      : activeUsersCount >= 20
      ? activeUsersCount + 242
      : activeUsersCount >= 9
      ? activeUsersCount + 171
      : activeUsersCount >= 4
      ? activeUsersCount + 52
      : activeUsersCount >= 2
      ? activeUsersCount + 15
      : activeUsersCount >= 1
      ? activeUsersCount + 2
      : activeUsersCount;
  return activeUsersCount;
};

export const demoGithubInstance: GithubDemoRecord = {
  instance: demoGithubInstances[0],
  apps: [
    {
      label: "MyGet",
      riskLevel: 2,
      id: "myget",
      logoUrl:
        "https://gdm-catalog-fmapi-prod.imgix.net/ProductLogo/f086c6c0-b498-4c6d-b73a-3d88d4b25df1.png?auto=format,compress&size=150",
      users: 121,
      access: ["read", "write"],
      repos: ["backend", "frontend"],
    },
    {
      label: "Mergify",
      riskLevel: 3,
      id: "mergify",
      logoUrl: "https://avatars.githubusercontent.com/u/37838584?s=280&v=4",
      users: 62,
      access: ["read", "admin"],
      repos: ["backend", "frontend", "api"],
      installedBy: "George Ratcliff",
      installedOn: "Feb 8, 2023",
    },
    {
      label: "LGTM",
      riskLevel: 0,
      id: "lgtm",
      logoUrl: "https://avatars.githubusercontent.com/u/19589930?s=280&v=4",
      users: 98,
      access: ["read"],
      repos: ["frontend"],
      installedBy: "Alan Oujewi",
      installedOn: "Jan 18, 2023",
    },
    {
      label: "Chef",
      riskLevel: 2,
      id: "chef",
      logoUrl: "https://avatars.githubusercontent.com/u/3617830?s=280&v=4",
      users: 206,
      access: ["read", "write"],
      repos: ["backend", "frontend", "edge"],
      installedBy: "Alan Oujewi",
      installedOn: "Feb 1, 2023",
    },
    {
      label: "Bit.dev",
      riskLevel: 1,
      id: "bit.dev",
      logoUrl:
        "https://static.bit.dev/bit-logo.svg?_t=1617720000000&auto=format&fit=crop&h=32&ixlib=react-9.0.2&ixid=eyJhcHBfaWQiOjEyMDd9&w=32",
      users: 76,
      access: ["read"],
      repos: ["backend", "frontend"],
      installedBy: "Alan Oujewi",
      installedOn: "Feb 7, 2023",
    },
    {
      label: "Disbug",
      riskLevel: 0,
      id: "disbug",
      logoUrl:
        "https://scontent.fadb2-2.fna.fbcdn.net/v/t39.30808-6/300667467_445230294290824_9101695649449889707_n.jpg?_nc_cat=105&ccb=1-7&_nc_sid=09cbfe&_nc_ohc=347c-8VTK6sAX9HfCzC&_nc_ht=scontent.fadb2-2.fna&oh=00_AfCI7IoRVP9L6FNAbzadM5pRmC02Y8CdtOwgh2ZjFxwrKg&oe=64488114",
      users: 39,
      access: ["read"],
      repos: ["backend", "frontend", "edge"],
      installedBy: "Greggory Elis",
      installedOn: "Dec 1, 2022",
    },
    {
      label: "GitKraken",
      riskLevel: 1,
      id: "gitkraken",
      logoUrl:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSxS8R5O5DbCkiwJJxtLXWEcU3kb4jplX3oYzcTVJw&s",
      users: 81,
      access: ["read", "write"],
      repos: ["backend", "frontend", "edge"],
      installedBy: "George Ratcliff",
      installedOn: "Apr 18, 2023",
    },
    {
      label: "Codetree",
      riskLevel: 1,
      id: "codetree",
      logoUrl:
        "https://images.g2crowd.com/uploads/product/image/large_detail/large_detail_f9c6fc4525c73e4b7476e80d9cc6168f/codetree.png",
      users: 118,
      access: ["read"],
      repos: ["backend", "frontend", "edge"],
      installedBy: "George Ratcliff",
      installedOn: "Apr 22, 2023",
    },
    {
      label: "Jira",
      riskLevel: 1,
      id: "jira",
      users: 198,
      access: ["read", "write"],
      repos: ["backend", "frontend", "edge"],
      installedBy: "George Ratcliff",
      installedOn: "Nov 26, 2022",
    },
    {
      label: "Atom",
      riskLevel: 0,
      id: "atom",
      logoUrl:
        "https://upload.wikimedia.org/wikipedia/commons/thumb/8/80/Atom_editor_logo.svg/1200px-Atom_editor_logo.svg.png",
      users: 26,
      access: ["read"],
      repos: ["backend", "frontend", "edge"],
      installedBy: "George Ratcliff",
      installedOn: "Apr 21, 2023",
    },
    {
      label: "Heroku",
      riskLevel: 0,
      id: "heroku",
      logoUrl: "https://www.herokucdn.com/favicons/favicon.ico",
      users: 8,
      access: ["read", "write"],
      repos: ["backend", "frontend", "edge"],
      installedBy: "George Ratcliff",
      installedOn: "Apr 18, 2023",
    },
    {
      label: "Travis CI",
      riskLevel: 1,
      id: "travis-ci",
      logoUrl: "https://travis-ci.com/favicon.ico",
      users: 5,
      access: ["read", "write"],
      repos: ["backend", "frontend", "edge"],
      installedBy: "George Ratcliff",
      installedOn: "Apr 18, 2023",
    },
    {
      label: "Circle CI",
      riskLevel: 3,
      id: "circle-ci",
      logoUrl:
        "https://upload.wikimedia.org/wikipedia/commons/8/82/Circleci-icon-logo.svg",
      users: 287,
      access: ["read", "write", "admin"],
      repos: ["backend", "frontend", "edge"],
    },
  ],
};
