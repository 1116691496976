import { ActionIcon, useMantineColorScheme } from "@mantine/core";
import { IconMoonStars, IconSun } from "@tabler/icons";

function ThemeToggleButton() {
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const dark = colorScheme === "dark";

  return (
    <ActionIcon
      onClick={() => toggleColorScheme()}
      size="lg"
      radius="xl"
      sx={(theme) => ({
        backgroundColor: dark ? theme.colors.dark[6] : theme.colors.gray[0],
        color: dark ? theme.colors.orange[4] : theme.colors.gray[6],
      })}
    >
      {colorScheme === "dark" ? (
        <IconSun size={20} stroke={1} />
      ) : (
        <IconMoonStars size={20} stroke={1} />
      )}
    </ActionIcon>
  );
}

export default ThemeToggleButton;
