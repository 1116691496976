import { Center, Stack, Text } from "@mantine/core";
import Oops from "../common/Oops";
import { IconPrompt } from "@tabler/icons";

const ScreenDashboard = () => {
  return (
    <Center style={{ width: "100%", height: "100%" }}>
      {/* <Oops /> */}
      <Stack align="center">
        <IconPrompt size={64} stroke={1} color="gray" />
        <Text color="gray.6">
          ... under construction, expect updates soon ...
        </Text>
      </Stack>
    </Center>
  );
};

export default ScreenDashboard;
