import {
  ActionIcon,
  Anchor,
  Avatar,
  Badge,
  Button,
  Center,
  Col,
  Container,
  createStyles,
  Divider,
  Grid,
  Group,
  HoverCard,
  List,
  Loader,
  MultiSelect,
  Paper,
  ScrollArea,
  SimpleGrid,
  Space,
  Stack,
  Table,
  Tabs,
  Text,
  TextInput,
  ThemeIcon,
  UnstyledButton,
} from "@mantine/core";
import { useDebouncedState } from "@mantine/hooks";
import { openConfirmModal } from "@mantine/modals";
import { showNotification } from "@mantine/notifications";
import {
  IconArrowLeft,
  IconCheck,
  IconChevronDown,
  IconChevronUp,
  IconCircleCheck,
  IconDots,
  IconExclamationMark,
  IconFocusCentered,
  IconHash,
  IconInfoCircle,
  IconPackages,
  IconPackgeExport,
  IconPlugConnected,
  IconPlugConnectedX,
  IconSearch,
  IconSelector,
  IconShieldCheck,
  IconShieldChevron,
  IconShieldLock,
  IconShieldX,
  IconX,
} from "@tabler/icons";
import { format, parse } from "fecha";
import { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { SalesforceApi, SalesforceOauthTokenRecord } from "../../api/generated";
import {
  DISPLAY_DATETIME_FORMAT,
  VANTYR_API_DATETIME_FORMAT,
} from "../../constants/constants";
import { colorAvatar } from "../../misc/mixins_mantine";
import { formatBytes } from "../../misc/toolkit_common";
import {
  FauxDataExposureRecord,
  SfIntegrationLogo,
} from "../../misc/toolkit_salesforce";
import { useAppsStore, usePaginationStore } from "../../state/store";
import PaginationControl from "../common/PaginationControl";
import TableDataFlowSalesforceIntegration from "../salesforce/TableDataFlowSalesforceIntegration";
import PillTabs, { PillTabConfig } from "../common/PillTabs";
import { demoInstanceFullRecord, demoMode } from "../../tooling/demo";
import MenuIntegrationActions from "../salesforce/MenuIntegrationActions";

const tabs = [
  {
    label: "Summary",
    value: "summary",
    icon: IconFocusCentered,
  },
  {
    label: "Data Exposure",
    value: "data-exposure",
    icon: IconPackages,
  },
  {
    label: "Data Movement",
    value: "data-movement",
    icon: IconPackgeExport,
  },
  {
    label: "Sessions",
    value: "sessions",
    icon: IconPlugConnected,
  },
  {
    label: "Permissions",
    value: "scopes",
    icon: IconShieldLock,
  },
  // {
  //   label: "Details",
  //   value: "details",
  //   icon: IconInfoCircle,
  // },
] as PillTabConfig[];

const badgeColorByRisk = ["gray.6", "orange.6", "red.6"];
const badgeLabelByRisk = ["Low risk", "Medium risk", "High risk"];

const useStyles = createStyles((theme) => ({
  rowSelected: {
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.fn.rgba(theme.colors[theme.primaryColor][7], 0.2)
        : theme.colors[theme.primaryColor][0],
  },

  tableHeader: {
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.fn.rgba(theme.colors["gray"][8], 0.2)
        : theme.colors["gray"][0],
  },

  th: {
    padding: "0 !important",
  },

  control: {
    width: "100%",
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    },
  },

  icon: {
    width: 21,
    height: 21,
  },
}));

interface ThProps {
  children: React.ReactNode;
  reversed: boolean;
  sorted: boolean;
  style?: React.CSSProperties;
  description?: React.ReactNode;
  onSort(): void;
  position?: "left" | "right" | "center" | "apart";
  pl?: number;
  pr?: number;
}

function Th({
  children,
  reversed,
  sorted,
  onSort,
  style,
  description,
  position = "left",
  pl = 10,
  pr = 10,
}: ThProps) {
  const { classes } = useStyles();
  const Icon = sorted
    ? reversed
      ? IconChevronUp
      : IconChevronDown
    : IconSelector;
  return (
    <th style={style} className={classes.th}>
      <UnstyledButton
        onClick={onSort}
        className={classes.control}
        pl={pl}
        pr={pr}
      >
        <Group spacing={4} position="apart">
          <Group spacing={4}>
            <Text
              weight={500}
              size="xs"
              style={{ textOverflow: "ellipsis", overflow: "hidden" }}
              lineClamp={1}
            >
              {children}
            </Text>
            {description && (
              <HoverCard
                width={360}
                shadow="xl"
                withArrow
                openDelay={100}
                closeDelay={100}
                position="bottom"
                offset={20}
              >
                <HoverCard.Target>
                  <Center className={classes.icon}>
                    <IconInfoCircle size={14} stroke={1} />
                  </Center>
                </HoverCard.Target>
                <HoverCard.Dropdown>{description}</HoverCard.Dropdown>
              </HoverCard>
            )}
          </Group>
          <Center className={classes.icon}>
            <Icon size={14} stroke={1} />
          </Center>
        </Group>
      </UnstyledButton>
    </th>
  );
}

export function ScreenIntegration() {
  const { instanceId, integrationId } = useParams();

  // log the instance id and integration id
  console.log(instanceId, integrationId);

  const [sortBy, setSortBy] = useState<keyof FauxDataExposureRecord | null>(
    "count"
  );
  const [reverseSortDirection, setReverseSortDirection] = useState(true);

  const [filterConditions, setFilterConditions] = useState<string[]>([
    "classification-PII",
    "classification-PHI",
    "classification-PCI",
  ]);
  const [searchCondition, setSearchCondition] = useDebouncedState<string>(
    "",
    200
  );

  const setSorting = (field: keyof FauxDataExposureRecord) => {
    const reversed = field === sortBy ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortBy(field);
  };

  const [activeTab, setActiveTab] = useSearchParams({});

  const { classes, cx } = useStyles();

  const [tokenFilter, setTokenFilter] = useState<string>("");
  const queryClient = useQueryClient();

  const setIntegrationSelected = useAppsStore(
    (state) => state.setIntegrationSelected
  );

  const { pageSize, setPageSize } = usePaginationStore.get(
    "integration-exposure"
  )!((state) => ({
    pageSize: state.pageSize,
    setPageSize: state.setPageSize,
  }));

  const [activePage, setPage] = useState(1);

  // useQuery to fetch integration data
  const { isError, error, isLoading, data, isLoadingError, isRefetchError } =
    useQuery(
      `integration_${integrationId}`,
      async () => {
        if (demoMode) {
          return demoInstanceFullRecord.integrations?.find(
            (i) => i.id === integrationId
          );
        }

        const rsp = await new SalesforceApi().getIntegration(
          instanceId!,
          integrationId!
        );
        return rsp.data;
      },
      {
        staleTime: 60 * 60 * 1000,
      }
    );

  // if loading
  if (isLoading) {
    return (
      <Center style={{ height: "100%" }}>
        <Loader color="teal" variant="dots" />
      </Center>
    );
  }

  console.log("isError", isError);
  console.log("isLoadingError", isLoadingError);
  console.log("isRefetchError", isRefetchError);
  console.log("error", error);

  // if error
  if (isError || isLoadingError || isRefetchError || error) {
    return (
      <Center style={{ height: "100%" }}>
        <Text>Something went wrong</Text>
      </Center>
    );
  }

  setIntegrationSelected(data?.label!);

  const openTokenDeleteModal = (token: SalesforceOauthTokenRecord) =>
    openConfirmModal({
      title: "Revoke access token?",
      radius: "md",
      closeButtonProps: { radius: "xl" },
      cancelProps: { radius: "xl" },
      centered: true,
      children: (
        <Text size="sm">
          Are you sure you want to revoke this OAuth access token?
          <br />
          <br />
          This will deactivate the token and prevent the integration from
          accessing Salesforce data on behalf of the issuing user. Other tokens
          issued to the same user will not be affected.
        </Text>
      ),
      labels: { confirm: "Revoke", cancel: "Cancel" },
      confirmProps: { color: "red", radius: "xl" },
      onConfirm: () => {
        new SalesforceApi()
          .salesforceRevokeTokens(instanceId!, {
            deleteTokens: [token.deleteToken!],
          })
          .then(() => {
            queryClient.invalidateQueries({
              queryKey: [`integration_${integrationId}`],
            });
            // setSalesforceConnected(false);
            showNotification({
              title: "Access token revoked successfully",
              message: `The access token issued by ${token.userName} has been successfully revoked. `,
              color: "green",
            });
          })
          .catch((err) => {
            showNotification({
              title: "Access token revocation failed",
              message: err.message,
              color: "red",
            });
          });
      },
      onCancel: () => {},
    });

  // Inner function for mapping fetched oauth tokens to table rows
  function mapSessionsToRows(data: SalesforceOauthTokenRecord[]) {
    return data
      ?.filter(
        (token) =>
          token.userName?.toLowerCase().includes(tokenFilter) ||
          token.userProfileName?.toLowerCase().includes(tokenFilter)
      )
      .sort((a, b) => {
        if (a.lastUsedOn && b.lastUsedOn) {
          return (
            parse(b.lastUsedOn!, VANTYR_API_DATETIME_FORMAT)!.getTime() -
            parse(a.lastUsedOn!, VANTYR_API_DATETIME_FORMAT)!.getTime()
          );
        } else {
          return 0;
        }
      })
      .map((token) => {
        return (
          <tr key={token.id} style={{ height: 60 }}>
            <td align="center">
              <Avatar
                radius="xl"
                size="sm"
                {...colorAvatar(token.userName || " ")}
              />
            </td>
            <td align="left">
              <Stack spacing={0}>
                <Text>{token.userName}</Text>
                {!token.userIsActive && (
                  <Text color="dimmed">Deactivated user</Text>
                )}
              </Stack>
            </td>
            <td align="left">{token.userProfileName}</td>
            <td align="left">
              {!token.createdOn
                ? " - "
                : format(
                    parse(token.createdOn!, VANTYR_API_DATETIME_FORMAT)!,
                    DISPLAY_DATETIME_FORMAT
                  )}
            </td>
            <td align="left">
              {!token.lastUsedOn
                ? " - "
                : format(
                    parse(token.lastUsedOn!, VANTYR_API_DATETIME_FORMAT)!,
                    DISPLAY_DATETIME_FORMAT
                  )}
            </td>
            <td align="left">{token.useCount ? token.useCount : " - "}</td>
            <td align="center">
              <Button
                variant="outline"
                color="red"
                radius="xl"
                size="xs"
                onClick={() => openTokenDeleteModal(token)}
              >
                Revoke
              </Button>
            </td>
          </tr>
        );
      });
  }

  const sessionRows = mapSessionsToRows(data?.oauthTokens!);

  const uniqueDataClassifications = data?.exposedFields!.map((field) => {
    return {
      field: field.e + " :: " + field.f,
      classification: field.s ? field.s[0] : "Unclassified",
      count: field.c,
    } as FauxDataExposureRecord;
  });

  const dataExposureRows = uniqueDataClassifications!
    .filter((r) => {
      const record = r!;
      if (record.field.split("::")[1].trim().length === 0) {
        return false;
      }
      let searchOk = true;
      if (searchCondition) {
        searchOk =
          record.field
            ?.toLowerCase()
            .replace(" :: ", " ")
            .includes(searchCondition.toLowerCase()) || false;
      }
      if (
        filterConditions.length === 0 ||
        (filterConditions.length !== 0 &&
          (!filterConditions.find((c) => c.includes("classification-")) ||
            filterConditions.includes(
              `classification-${record.classification}`
            )))
      ) {
        return searchOk;
      } else {
        return false;
      }
    })
    .sort((a, b) => {
      if (!sortBy) {
        return (
          ((b!.count! || 0) - (a!.count! || 0)) *
          (reverseSortDirection ? 1 : -1)
        );
      }

      switch (sortBy) {
        case "field":
          return (
            b!.field!.localeCompare(a!.field!, "en") *
            (reverseSortDirection ? -1 : 1)
          );
        case "count":
          return (
            ((b!.count! || 0) - (a!.count! || 0)) *
            (reverseSortDirection ? 1 : -1)
          );
        case "classification":
          return (
            b!.classification!.localeCompare(a!.classification!, "en") *
            (reverseSortDirection ? -1 : 1)
          );

        default:
          return (
            b!.classification!.localeCompare(a!.classification!, "en") *
            (reverseSortDirection ? -1 : 1)
          );
      }
    });

  const dataExposureRowsFilteredLength = dataExposureRows.length;

  const pageSizeAdjusted =
    pageSize !== 0 ? pageSize! : dataExposureRowsFilteredLength;

  const dataExposureRowsToDisplay = dataExposureRows
    .slice((activePage - 1) * pageSizeAdjusted, activePage * pageSizeAdjusted)
    .map((r) => {
      const record = r!;
      return (
        <tr key={record.field} style={{ height: "30px" }}>
          <td style={{ textAlign: "left", paddingLeft: 10 }}>
            <div style={{ width: "calc(100% - 20px)" }}>
              <Text
                ml="xs"
                size="xs"
                style={{ textOverflow: "ellipsis", overflow: "hidden" }}
                lineClamp={4}
                // weight={400}
              >
                {record.field.replace(" :: ", " ")}
              </Text>
            </div>
          </td>

          <td style={{ textAlign: "left", paddingLeft: 10, paddingRight: 20 }}>
            <Badge
              radius="xl"
              size="md"
              color={
                record.classification === "PHI"
                  ? "green.6"
                  : record.classification === "PII"
                  ? "pink.6"
                  : record.classification === "Sensitive"
                  ? "grape.6"
                  : record.classification === "PCI"
                  ? "red.6"
                  : "gray.6"
              }
            >
              {record.classification}
            </Badge>
          </td>

          <td align="left" style={{ paddingLeft: 10 }}>
            <Text
              size="xs"
              // weight={400}
              // style={{ cursor: "pointer", width: 60 }}
            >
              {record.count !== -1 ? record.count : "N/A"}
            </Text>
          </td>
        </tr>
      );
    });

  const piiCount = data!.classifiedFields?.reduce((acc, cur) => {
    return cur ? acc + (cur.split("::")[2].includes("PII") ? 1 : 0) : acc;
  }, 0);

  const phiCount = data!.classifiedFields?.reduce((acc, cur) => {
    return cur ? acc + (cur.split("::")[2].includes("PHI") ? 1 : 0) : acc;
  }, 0);

  const pciCount = data!.classifiedFields?.reduce((acc, cur) => {
    return cur ? acc + (cur.split("::")[2].includes("PCI") ? 1 : 0) : acc;
  }, 0);

  const sensitiveCount = data!.classifiedFields?.reduce((acc, cur) => {
    return cur ? acc + (cur.split("::")[2].includes("Sensitive") ? 1 : 0) : acc;
  }, 0);

  return (
    <Paper
      shadow="md"
      m="xl"
      p="xl"
      style={{ minWidth: 600, maxWidth: 1500, width: "auto" }}
    >
      <Space h="sm" />
      <Container px="md" fluid>
        <Group position="apart">
          <Group>
            <ActionIcon
              component={Link}
              to={`/applications/salesforce/${instanceId}`}
            >
              <IconArrowLeft stroke={1} />
            </ActionIcon>
            <SfIntegrationLogo integration={data!} width={24} height={24} />
            <Text>
              <strong>{data!.label}</strong> integration with{" "}
              <strong>Salesforce</strong>
            </Text>
          </Group>
          <MenuIntegrationActions
            integration={data!}
            instanceId={instanceId!}
          />
        </Group>
        <Container my="xl" p={0} fluid>
          <PillTabs
            tabs={tabs}
            activeTab={activeTab.get("t") || "summary"}
            setActiveTab={(v) => {
              setActiveTab({ t: `${v}` });
            }}
          >
            <Tabs.Panel value="summary" pt="xs">
              <Container my="xl" p={0} fluid>
                <Stack
                  my="xl"
                  spacing="xl"
                  align="flex-start"
                  justify="flex-start"
                >
                  {!data!.createdBy ? (
                    <Group>
                      <Avatar color="pink.6" radius="xl" size="md">
                        <IconExclamationMark stroke={1} />
                      </Avatar>
                      <Text size="sm">
                        <strong>Shadow integration</strong>
                        <br></br>This app has not been installed by any
                        Salesforce admin
                      </Text>
                    </Group>
                  ) : (
                    <Group>
                      <Avatar color="green.6" radius="xl" size="md">
                        <IconCheck stroke={1} />
                      </Avatar>
                      <Text size="sm">
                        <strong>Connected Application</strong>
                        <br></br>Installed by {data!.createdBy} on{" "}
                        {format(
                          parse(data!.createdOn!, VANTYR_API_DATETIME_FORMAT)!,
                          DISPLAY_DATETIME_FORMAT
                        ) ||
                          data!.createdOn ||
                          " - "}{" "}
                        <Anchor
                          span
                          size="xs"
                          onClick={() => setActiveTab({ t: "details" })}
                        >
                          » See details
                        </Anchor>
                      </Text>
                    </Group>
                  )}
                  <Group align="left">
                    {data!.label === "Vantyr" ? (
                      <Group>
                        <Avatar color="gray.6" radius="xl" size="md">
                          <IconCheck stroke={1} />
                        </Avatar>
                        <Text size="sm">
                          <strong>Not Assessed</strong>
                          <br></br>Vantyr developed integrations are not
                          assessed for risk scoring
                        </Text>
                      </Group>
                    ) : !data!.createdBy ? (
                      <Group>
                        <Avatar color="red.6" radius="xl" size="md">
                          <IconShieldX stroke={1} />
                        </Avatar>
                        <Text size="sm">
                          <strong>High Risk</strong>
                          <br></br>Visibility into shadow apps is restricted
                        </Text>
                      </Group>
                    ) : data!.riskLevel === 2 ? (
                      <Group>
                        <Avatar color="red.6" radius="xl" size="md">
                          <IconShieldX stroke={1} />
                        </Avatar>
                        <Text size="sm">
                          <strong>High Risk</strong>
                          <br></br>Based on granted permission level{" "}
                          <Anchor
                            span
                            size="xs"
                            onClick={() => setActiveTab({ t: "scopes" })}
                          >
                            » See permissions
                          </Anchor>
                        </Text>
                      </Group>
                    ) : data!.riskLevel === 1 ? (
                      <Group>
                        <Avatar color="orange.6" radius="xl" size="md">
                          <IconShieldChevron stroke={1} />
                        </Avatar>
                        <Text size="sm">
                          <strong>Medium Risk</strong>
                          <br></br>Based on granted permission level{" "}
                          <Anchor
                            span
                            size="xs"
                            onClick={() => setActiveTab({ t: "scopes" })}
                          >
                            » See permissions
                          </Anchor>
                        </Text>
                      </Group>
                    ) : (
                      <Group>
                        <Avatar color="gray.6" radius="xl" size="md">
                          <IconShieldCheck stroke={1} />
                        </Avatar>
                        <Text size="sm">
                          <strong>Low Risk</strong>
                          <br></br>Based on granted permission level{" "}
                          <Anchor
                            span
                            size="xs"
                            onClick={() => setActiveTab({ t: "scopes" })}
                          >
                            » See permissions
                          </Anchor>
                        </Text>
                      </Group>
                    )}
                  </Group>
                  <Group align="left">
                    {data!.oauthTokens!.length !== 0 ? (
                      <Group>
                        <Avatar color="orange.6" radius="xl" size="md">
                          <IconPlugConnected stroke={1} />
                        </Avatar>
                        <Text size="sm">
                          <strong>
                            {data!.oauthTokens!.length} open sessions
                          </strong>
                          <br></br>On behalf of{" "}
                          {
                            [
                              ...new Set(
                                data!
                                  .oauthTokens!.filter((x) => x.userIsActive)
                                  .map((t) => t.userName)
                              ),
                            ].length
                          }{" "}
                          active users{" "}
                          <Anchor
                            span
                            size="xs"
                            onClick={() => setActiveTab({ t: "sessions" })}
                          >
                            » See open sessions
                          </Anchor>
                        </Text>
                      </Group>
                    ) : (
                      <Group>
                        <Avatar color="gray.6" radius="xl" size="md">
                          <IconPlugConnectedX stroke={1} />
                        </Avatar>
                        <Text size="sm">
                          <strong>Unused integration</strong>
                          <br></br>No user has granted access rights to this
                          integration at the present moment
                        </Text>
                      </Group>
                    )}
                  </Group>
                  <Group align="left">
                    {!data!.usageVolumeBytes || data!.usageVolumeBytes === 0 ? (
                      <Group>
                        <Avatar color="gray.6" radius="xl" size="md">
                          <IconX stroke={1} />
                        </Avatar>
                        <Text size="sm">
                          <strong>No data flow</strong>
                          <br></br>No data movement in the observed period
                        </Text>
                      </Group>
                    ) : (
                      <>
                        <Group>
                          <Avatar color="indigo.6" radius="xl" size="md">
                            <IconPackgeExport stroke={1} />
                          </Avatar>
                          <Text size="sm">
                            <strong>
                              {formatBytes(data!.usageVolumeBytes!)} of
                              Salesforce data pulled
                            </strong>
                            <br></br>
                            Vantyr detected {piiCount! > 0 && `${piiCount} PII`}
                            {piiCount! > 0 &&
                              phiCount! + sensitiveCount! + pciCount! > 0 &&
                              ", "}
                            {phiCount! > 0 && `${phiCount} PHI`}
                            {phiCount! > 0 &&
                              sensitiveCount! + pciCount! > 0 &&
                              ", "}
                            {pciCount! > 0 && `${pciCount} PCI`}
                            {pciCount! > 0 && sensitiveCount! > 0 && ", "}
                            {sensitiveCount! > 0 &&
                              `${sensitiveCount} Business Sensitive`}{" "}
                            data types pulled{" "}
                            <Anchor
                              // span
                              size="xs"
                              weight={300}
                              onClick={() =>
                                setActiveTab({ t: "data-movement" })
                              }
                            >
                              » See data movement
                            </Anchor>
                          </Text>
                        </Group>
                      </>
                    )}
                  </Group>
                </Stack>
                <Stack spacing="xs" py="xl" justify="flex-start">
                  <Text size="sm" pb="md" italic={!data!.description}>
                    <strong>Description:</strong>
                    {data!.description
                      ? ` ${data!.description}`
                      : " No description provided by integration vendor"}
                  </Text>
                  <Grid gutter="xs">
                    <Col span={3}>
                      <Text size="xs" color="dimmed">
                        Integration type
                      </Text>
                    </Col>
                    <Col span={9}>
                      <Text size="xs">{data?.type}</Text>
                    </Col>

                    <Col span={3}>
                      <Text size="xs" color="dimmed">
                        Webpage
                      </Text>
                    </Col>
                    <Col span={9}>
                      <Anchor
                        style={{ lineHeight: 1 }}
                        size="xs"
                        href={data!.infoUrl || "#"}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {data!.infoUrl || " - "}
                      </Anchor>
                    </Col>

                    <Col span={3}>
                      <Text size="xs" color="dimmed">
                        Admin authorization only
                      </Text>
                    </Col>
                    <Col span={9}>
                      <Text size="xs">
                        {data?.isUsingAdminAuthOnly ? "Yes" : "No"}
                      </Text>
                    </Col>
                  </Grid>
                </Stack>
              </Container>
            </Tabs.Panel>
            <Tabs.Panel value="data-exposure" pt="xs">
              <Container p={0} my="xl" fluid>
                <Group>
                  <Avatar color="indigo.6" radius="xl" size="md">
                    <IconPackages stroke={1} />
                  </Avatar>
                  {data!.exposedFieldsCountClassifiedTotal! > 0 ? (
                    <Text size="sm">
                      <strong>
                        Up to{" "}
                        {data!.exposedFieldsCountClassifiedTotal!.toLocaleString()}{" "}
                        classified data points exposed
                      </strong>
                      <br></br>
                      Exposed data points:{" "}
                      {data!.exposedFieldsCountPII! > 0 &&
                        `${data!.exposedFieldsCountPII?.toLocaleString()} PII`}
                      {data!.exposedFieldsCountPII! > 0 &&
                        data!.exposedFieldsCountPHI! +
                          data!.exposedFieldsCountSensitive! +
                          data!.exposedFieldsCountPCI! >
                          0 &&
                        ", "}
                      {data!.exposedFieldsCountPHI! > 0 &&
                        `${data!.exposedFieldsCountPHI?.toLocaleString()} PHI`}
                      {data!.exposedFieldsCountPHI! > 0 &&
                        data!.exposedFieldsCountSensitive! +
                          data!.exposedFieldsCountPCI! >
                          0 &&
                        ", "}
                      {data!.exposedFieldsCountPCI! > 0 &&
                        `${data!.exposedFieldsCountPCI?.toLocaleString()} PCI`}
                      {data!.exposedFieldsCountPCI! > 0 &&
                        data!.exposedFieldsCountSensitive! > 0 &&
                        ", "}
                      {data!.exposedFieldsCountSensitive! > 0 &&
                        `${data!.exposedFieldsCountSensitive!.toLocaleString()} Business Sensitive`}{" "}
                      and{" "}
                      {data!.exposedFieldsCountUnclassified!.toLocaleString()}{" "}
                      unclassified
                    </Text>
                  ) : (
                    <Text size="sm">
                      <strong>No classified data currently exposed</strong>
                    </Text>
                  )}
                </Group>
                {data!.exposedFieldsCountClassifiedTotal! <= 0 ? null : (
                  <>
                    <Group py="xl" position="apart">
                      <TextInput
                        placeholder="Search"
                        style={{ width: "260px" }}
                        onChange={(event) => {
                          setPage(1);
                          setSearchCondition(event.currentTarget.value);
                        }}
                        radius="xl"
                        icon={<IconSearch size={16} stroke={1} />}
                      />
                      <MultiSelect
                        style={{
                          minWidth: "260px",
                          maxWidth: "360px",
                          // maxHeight: "40px",
                        }}
                        data={[
                          {
                            value: "classification-PHI",
                            label: "PHI",
                            group: "Classification",
                          },
                          {
                            value: "classification-PII",
                            label: "PII",
                            group: "Classification",
                          },
                          {
                            value: "classification-Sensitive",
                            label: "Sensitive",
                            group: "Classification",
                          },
                          {
                            value: "classification-PCI",
                            label: "PCI",
                            group: "Classification",
                          },
                          {
                            value: "classification-Unclassified",
                            label: "Unclassified",
                            group: "Classification",
                          },
                        ]}
                        placeholder="Search"
                        defaultValue={[
                          "classification-PII",
                          "classification-PHI",
                          "classification-PCI",
                        ]}
                        // clearButtonLabel="Clear selection"
                        clearable
                        maxDropdownHeight={600}
                        onChange={(value) => {
                          setPage(1);
                          setFilterConditions(value);
                        }}
                        radius="xl"
                        icon={<IconHash size={16} stroke={1} />}
                      />
                    </Group>
                    <Table
                      verticalSpacing="xs"
                      horizontalSpacing={0}
                      fontSize="xs"
                      style={{ tableLayout: "fixed" }}
                    >
                      <thead>
                        <tr className={cx({ [classes.tableHeader]: true })}>
                          <Th
                            sorted={sortBy === "field"}
                            reversed={reverseSortDirection}
                            onSort={() => setSorting("field")}
                            style={{ textAlign: "left", minWidth: 180 }}
                            pl={20}
                          >
                            Data Field
                          </Th>
                          <Th
                            sorted={sortBy === "classification"}
                            reversed={reverseSortDirection}
                            onSort={() => setSorting("classification")}
                            style={{ textAlign: "left", width: 200 }}
                          >
                            Classification
                          </Th>
                          <Th
                            sorted={sortBy === "count"}
                            reversed={reverseSortDirection}
                            onSort={() => setSorting("count")}
                            style={{ textAlign: "left", width: 200 }}
                          >
                            Count
                          </Th>
                        </tr>
                      </thead>
                      <tbody>{dataExposureRowsToDisplay}</tbody>
                    </Table>
                    <Divider />
                    <Container pl="xl" pr="xs" pt="lg" fluid>
                      <PaginationControl
                        activePage={activePage}
                        setActivePage={setPage}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        rowsNumber={dataExposureRowsFilteredLength}
                      />
                    </Container>
                  </>
                )}
              </Container>
            </Tabs.Panel>
            <Tabs.Panel value="data-movement" pt="xs">
              <Container p={0} my="xl" fluid>
                <Group align="left">
                  {!data!.usageVolumeBytes || data!.usageVolumeBytes === 0 ? (
                    <Group>
                      <Avatar color="gray.6" radius="xl" size="md">
                        <IconX stroke={1} />
                      </Avatar>
                      <Text size="sm">
                        <strong>No data movement in the observed period</strong>
                      </Text>
                    </Group>
                  ) : (
                    <Container p={0} fluid style={{ width: "100%" }}>
                      <Group position="apart">
                        <Group>
                          <Avatar color="indigo.6" radius="xl" size="md">
                            <IconPackgeExport stroke={1} />
                          </Avatar>
                          <Text size="sm">
                            <strong>
                              {formatBytes(data!.usageVolumeBytes!)} of
                              Salesforce data pulled
                            </strong>
                            <br></br>
                            Vantyr detected {piiCount! > 0 && `${piiCount} PII`}
                            {piiCount! > 0 &&
                              phiCount! + sensitiveCount! + pciCount! > 0 &&
                              ", "}
                            {phiCount! > 0 && `${phiCount} PHI`}
                            {phiCount! > 0 &&
                              sensitiveCount! + pciCount! > 0 &&
                              ", "}
                            {pciCount! > 0 && `${pciCount} PCI`}
                            {pciCount! > 0 && sensitiveCount! > 0 && ", "}
                            {sensitiveCount! > 0 &&
                              `${sensitiveCount} Business Sensitive`}{" "}
                            data types pulled
                          </Text>
                        </Group>
                        {/* Placeholder for future top right corner bit */}
                      </Group>
                    </Container>
                  )}
                </Group>
                {data?.usageVolumeBytes! > 0 && (
                  <TableDataFlowSalesforceIntegration
                    integration={data!}
                  ></TableDataFlowSalesforceIntegration>
                )}
              </Container>
            </Tabs.Panel>
            <Tabs.Panel value="sessions" pt="xs">
              <Container p={0} my="xl" fluid>
                <Group position="apart">
                  <Group align="left">
                    {data!.oauthTokens!.length !== 0 ? (
                      <Group>
                        <Avatar color="orange.6" radius="xl" size="md">
                          <IconPlugConnected stroke={1} />
                        </Avatar>
                        <Text size="sm">
                          <strong>
                            {data!.oauthTokens!.length} open sessions
                          </strong>
                          <br></br>On behalf of{" "}
                          {
                            [
                              ...new Set(
                                data!
                                  .oauthTokens!.filter((x) => x.userIsActive)
                                  .map((t) => t.userName)
                              ),
                            ].length
                          }{" "}
                          active users
                        </Text>
                      </Group>
                    ) : (
                      <Group>
                        <Avatar color="gray.6" radius="xl" size="md">
                          <IconPlugConnectedX stroke={1} />
                        </Avatar>
                        <Text size="sm">
                          <strong>Unused integration</strong>
                          <br></br>No user has granted access rights to this
                          integration at the present moment
                        </Text>
                      </Group>
                    )}
                  </Group>
                  {data!.oauthTokens!.length !== 0 && (
                    <Group>
                      <TextInput
                        placeholder="Search"
                        style={{ width: 200 }}
                        icon={<IconSearch size={16} stroke={1} />}
                        value={tokenFilter}
                        radius="xl"
                        onChange={(val) =>
                          setTokenFilter(val.currentTarget.value)
                        }
                      />
                    </Group>
                  )}
                </Group>
              </Container>
              {data!.oauthTokens!.length !== 0 && (
                <ScrollArea pb="md" pt={0}>
                  <Table
                    verticalSpacing="xs"
                    fontSize="xs"
                    style={{ tableLayout: "fixed" }}
                  >
                    <thead>
                      <tr className={cx({ [classes.tableHeader]: true })}>
                        <th style={{ width: 40, textAlign: "center" }}></th>
                        <th style={{ minWidth: 200 }}>User</th>
                        <th style={{ width: 160, textAlign: "left" }}>
                          Profile
                        </th>
                        <th style={{ width: 120, textAlign: "left" }}>
                          Created on
                        </th>
                        <th style={{ width: 120, textAlign: "left" }}>
                          Last used
                        </th>
                        <th style={{ width: 120, textAlign: "left" }}>
                          Use count
                        </th>
                        <th style={{ width: 120, textAlign: "center" }}>
                          <IconDots size={14} stroke={1} />
                        </th>
                      </tr>
                    </thead>
                    <tbody>{sessionRows}</tbody>
                  </Table>
                </ScrollArea>
              )}
            </Tabs.Panel>
            <Tabs.Panel value="scopes" pt="xs">
              {!data?.oauthScopes || data.oauthScopes.length === 0 ? (
                <Group my="xl">
                  <Avatar color="pink.6" radius="xl" size="md">
                    <IconShieldX stroke={1} />
                  </Avatar>
                  <Text size="sm">
                    <strong>Unknown permissions</strong>
                    <br></br>Salesforce can not access the details of shadow
                    integrations, until explicitly installed by an admin
                  </Text>
                </Group>
              ) : (
                <List
                  spacing={"sm"}
                  p={0}
                  my="xl"
                  defaultValue="openid"
                  style={{ listStyleType: "none" }}
                >
                  {data!.oauthScopes!.map((scope) => (
                    <List.Item key={scope.id + "aa"} value={scope.id!}>
                      <Stack spacing={0}>
                        <Group>
                          <Text size="sm">{scope.label}</Text>
                          <Badge
                            radius="xl"
                            size="xs"
                            color={badgeColorByRisk[scope.riskLevel!]}
                          >
                            {badgeLabelByRisk[scope.riskLevel!]}
                          </Badge>
                        </Group>
                        <Text color="dimmed" size="xs">
                          {scope.description}
                        </Text>
                      </Stack>
                    </List.Item>
                  ))}
                </List>
              )}
            </Tabs.Panel>
            {/* <Tabs.Panel value="details" pt="xs">
              <Text size="sm" p={0} my="xl" italic={!data!.description}>
                {data!.description ||
                  "No description provided by integration vendor"}
              </Text>
              <SimpleGrid cols={3} p={0} my="xl" mb="md">
                <Stack py="md">
                  <Text style={{ lineHeight: 1 }} size="sm">
                    Integration type
                  </Text>
                  <Text style={{ lineHeight: 1 }} size="xs" color="dimmed">
                    {data?.type}
                  </Text>
                </Stack>
                <Stack py="md">
                  <Text style={{ lineHeight: 1 }} size="sm">
                    Installed by
                  </Text>
                  <Text style={{ lineHeight: 1 }} size="xs" color="dimmed">
                    {data!.createdBy}
                  </Text>
                </Stack>
                <Stack py="md">
                  <Text style={{ lineHeight: 1 }} size="sm">
                    Installed on
                  </Text>
                  <Text style={{ lineHeight: 1 }} size="xs" color="dimmed">
                    {!data!.createdOn
                      ? " - "
                      : format(
                          parse(data!.createdOn!, VANTYR_API_DATETIME_FORMAT)!,
                          DISPLAY_DATETIME_FORMAT
                        ) ||
                        data!.createdOn ||
                        " - "}
                  </Text>
                </Stack>
                <Stack py="md">
                  <Text style={{ lineHeight: 1 }} size="sm">
                    Webpage
                  </Text>
                  <Anchor
                    style={{ lineHeight: 1 }}
                    size="xs"
                    href={data!.infoUrl || "#"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {data!.infoUrl || " - "}
                  </Anchor>
                </Stack>
                <Stack py="md">
                  <Text style={{ lineHeight: 1 }} size="sm">
                    Admin authorization only
                  </Text>
                  <Text style={{ lineHeight: 1 }} size="xs" color={"red"}>
                    {data?.isUsingAdminAuthOnly ? "Yes" : "No"}
                  </Text>
                </Stack>
                <Stack py="md">
                  <Text style={{ lineHeight: 1 }} size="sm">
                    Users granted access
                  </Text>
                  <Text style={{ lineHeight: 1 }} size="xs" color="dimmed">
                    {
                      [...new Set(data!.oauthTokens!.map((t) => t.userName))]
                        .length
                    }
                  </Text>
                </Stack>
              </SimpleGrid>
            </Tabs.Panel> */}
          </PillTabs>
        </Container>
      </Container>
    </Paper>
  );
}
