import { TextInput } from "@mantine/core";
import { IconSearch } from "@tabler/icons";

interface SearchInputProps {
  onSearch: (searchCondition: string) => void;
}

const SearchInput = ({ onSearch }: SearchInputProps) => {
  return (
    <TextInput
      placeholder="Search"
      style={{ width: "200px" }}
      onChange={(event) => {
        onSearch(event.currentTarget.value);
      }}
      radius="xl"
      icon={<IconSearch size={16} stroke={1} />}
    />
  );
};

export default SearchInput;
