import {
  ActionIcon,
  Avatar,
  Badge,
  Collapse,
  Container,
  createStyles,
  Group,
  ScrollArea,
  Stack,
  Table,
  Tabs,
  Text,
} from "@mantine/core";
import {
  IconArrowLeft,
  IconFocusCentered,
  IconPlugConnected,
  IconShieldLock,
} from "@tabler/icons";
import { useState } from "react";
import { SlackInstanceActivityRecord } from "../../api/generated";
import { colorAvatar, stringToColor } from "../../misc/mixins_mantine";
import { AppLogo, formatUnixTs } from "../../misc/toolkit_common";
import {
  SlackAppGrantEnrichedRecord,
  slackInstanceDescription,
  SlackIntegration,
} from "../../misc/toolkit_slack";
import PillTabs, { PillTabConfig } from "../common/PillTabs";
import SearchInput from "../common/SearchInput";
import ListSlackScopes from "./ListSlackScopes";
import {
  SummarySlackIntegrationActive,
  SummarySlackIntegrationApproved,
  SummarySlackIntegrationInfo,
  SummarySlackIntegrationRisk,
} from "./SummarySlackIntegration";

const useStyles = createStyles((theme) => ({
  rowSelected: {
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.fn.rgba(theme.colors[theme.primaryColor][7], 0.2)
        : theme.colors[theme.primaryColor][0],
  },

  tableHeader: {
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.fn.rgba(theme.colors["gray"][8], 0.2)
        : theme.colors["gray"][0],
  },

  th: {
    padding: "0 !important",
  },

  control: {
    width: "100%",
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    },
  },

  icon: {
    width: 21,
    height: 21,
  },
}));

const tabs = [
  {
    label: "Summary",
    value: "summary",
    icon: IconFocusCentered,
  },
  {
    label: "Events",
    value: "sessions",
    icon: IconPlugConnected,
  },
  {
    label: "Permissions",
    value: "scopes",
    icon: IconShieldLock,
  },
] as PillTabConfig[];

interface ViewIntegrationSlackProps {
  instance: SlackInstanceActivityRecord;
  integration: SlackIntegration;
  showBackButton?: boolean;
  onBackButtonClick?: () => void;
  routedTab?: string;
  setRoutedTab?: (tab: string) => void;
}

export const ViewIntegrationSlack = ({
  instance,
  integration,
  showBackButton,
  onBackButtonClick,
  routedTab,
  setRoutedTab,
}: ViewIntegrationSlackProps) => {
  const { classes, cx } = useStyles();

  const [grantFilter, setGrantFilter] = useState<string>("");

  // const [activeTab, setActiveTab] = useSearchParams({});

  const [activeTab, setActiveTab] = useState<string>("summary");

  const setTab = (tab: string) => {
    setActiveTab(tab);
    if (setRoutedTab) {
      setRoutedTab(tab);
    }
  };

  // Inner function for mapping fetched app grant records to table rows
  function mapSessionsToRows(data: SlackAppGrantEnrichedRecord[]) {
    return data
      ?.filter(
        (grant) =>
          grant.appGrant.userName?.toLowerCase().includes(grantFilter) ||
          grant.appGrant.changeType?.toLowerCase().includes(grantFilter) ||
          grant.appGrant.reason?.toLowerCase().includes(grantFilter) ||
          grant.user?.realName?.toLowerCase().includes(grantFilter) ||
          grant.team?.name?.toLowerCase().includes(grantFilter)
      )
      .sort((a, b) => {
        if (a.appGrant.date && b.appGrant.date) {
          return b.appGrant.date - a.appGrant.date;
        } else {
          return 0;
        }
      })
      .map((grant) => {
        return (
          <tr key={grant.appGrant.date} style={{ height: 60 }}>
            <td align="center">
              <Avatar
                radius="xl"
                size="sm"
                {...colorAvatar(grant.user?.realName || " ")}
              />
            </td>
            <td align="left">
              <Stack spacing={0}>
                <Group spacing={4}>
                  <Text>{grant.user?.realName ?? grant.user?.displayName}</Text>
                  {grant.user?.isBot && (
                    <Badge color="teal" variant="light">
                      Bot
                    </Badge>
                  )}
                </Group>
                {grant.user?.deleted && (
                  <Text color="dimmed">Deleted user</Text>
                )}
              </Stack>
            </td>
            <td align="left">
              <Badge radius="xl" color={stringToColor(grant.team.name!)}>
                {grant.team.name ?? " - "}
              </Badge>
            </td>
            <td align="left">
              <Badge
                radius="xl"
                color={
                  "added" === grant.appGrant.changeType
                    ? "green"
                    : "removed" === grant.appGrant.changeType
                    ? "red"
                    : "gray"
                }
              >
                {grant.appGrant.changeType ? grant.appGrant.changeType : " - "}
              </Badge>
            </td>
            <td align="left">
              {grant.appGrant.reason ? grant.appGrant.reason : " - "}
            </td>
            <td align="left">
              {grant.appGrant.date ? formatUnixTs(grant.appGrant.date) : " - "}
            </td>
          </tr>
        );
      });
  }

  const sessionRows = mapSessionsToRows(integration.allAppGrants);

  return (
    <Container px="md" fluid>
      <Group>
        <Collapse in={showBackButton || false}>
          <ActionIcon onClick={onBackButtonClick}>
            <IconArrowLeft stroke={1} />
          </ActionIcon>
        </Collapse>
        <AppLogo integration={integration} width={24} height={24} />
        <Text>
          <strong>{integration.appName}</strong> integration with{" "}
          <strong>{slackInstanceDescription(instance.instance!)}</strong>
        </Text>
      </Group>
      <Container my="xl" p={0} fluid>
        <PillTabs tabs={tabs} activeTab={activeTab} setActiveTab={setTab}>
          {/* <Divider color={theme.colors.blue[2]}/> */}
          <Tabs.Panel value="summary" pt="xs">
            <Stack spacing="xl" my="xl" align="flex-start" justify="flex-start">
              <SummarySlackIntegrationApproved integration={integration} />
              <SummarySlackIntegrationInfo integration={integration} />
              <SummarySlackIntegrationRisk
                integration={integration}
                linkAction={() => setTab("scopes")}
              />
              <SummarySlackIntegrationActive
                integration={integration}
                linkAction={() => setTab("sessions")}
              />
            </Stack>
          </Tabs.Panel>
          <Tabs.Panel value="sessions" pt="xs">
            <Stack spacing="xl" my="xl" justify="flex-start">
              <Group position="apart">
                <SummarySlackIntegrationActive integration={integration} />
                {integration.activeUsers.length !== 0 && (
                  <SearchInput onSearch={setGrantFilter} />
                )}
              </Group>
              {integration.activeUsers.length !== 0 && (
                <ScrollArea pb="md" pt={0} type="scroll">
                  <Table
                    verticalSpacing="xs"
                    fontSize="xs"
                    // style={{ tableLayout: "fixed" }}
                  >
                    <thead>
                      <tr className={cx({ [classes.tableHeader]: true })}>
                        <th style={{ width: 40, textAlign: "center" }}></th>
                        <th style={{ minWidth: 200 }}>User</th>
                        <th style={{ width: 120, textAlign: "left" }}>
                          Workspace
                        </th>
                        <th style={{ width: 200, textAlign: "left" }}>
                          Action
                        </th>
                        <th style={{ width: 200, textAlign: "left" }}>
                          Reason
                        </th>
                        <th style={{ minWidth: 100, textAlign: "left" }}>
                          Date
                        </th>
                      </tr>
                    </thead>
                    <tbody>{sessionRows}</tbody>
                  </Table>
                </ScrollArea>
              )}
            </Stack>
          </Tabs.Panel>
          <Tabs.Panel value="scopes" pt="xs">
            <Stack spacing="xl" my="xl" justify="flex-start">
              <ListSlackScopes integration={integration} />
            </Stack>
          </Tabs.Panel>
        </PillTabs>
      </Container>
    </Container>
  );
};
