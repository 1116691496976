import { Stack } from "@mantine/core";
import { IconExclamationMark } from "@tabler/icons";
import Summary from "../common/Summary";

const ScreenTest = () => {
  return (
    <Stack>
      <Summary
        icon={IconExclamationMark}
        label="Shadow integration"
        subtext="This app has not been approved by a Slack admin"
        color="pink.6"
        size="md"
      />
      <Summary
        icon={IconExclamationMark}
        label="Shadow integration"
        subtext="This app has not been approved by a Slack admin. This app has not been approved by a Slack admin. This app has not been approved by a Slack admin. This app has not been approved by a Slack admin. This app has not been approved by a Slack admin. This app has not been approved by a Slack admin. This app has not been approved by a Slack admin. This app has not been approved by a Slack admin. This app has not been approved by a Slack admin. "
        color="pink.6"
        size="md"
        maxWidth="30rem"
      />
    </Stack>
  );
};

export default ScreenTest;
