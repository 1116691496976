import { Indicator } from "@mantine/core";
import React, { memo, useMemo } from "react";
import { Handle, NodeProps, Position } from "reactflow";
import { SfIntegrationLogo } from "../../misc/toolkit_salesforce";

const nodeBorderColorByRisk = ["#cacbcc", "#ffcc33", "#FA5352", "#FF00FF"];

const nodeShadowByRisk = [
  "0 0 8px 0 rgba(0, 0, 0, 0.2)",
  "0 0 8px 0 rgba(0, 0, 0, 0.2)",
  "0 0 8px 0 rgba(0, 0, 0, 0.2)",
];

const itemStyle: React.CSSProperties = {
  verticalAlign: "top",
  display: "inline-block",
  textAlign: "center",
  width: "30px",
};

const handleStyle: React.CSSProperties = {
  transform: "translate(-50%, -50%)",
  top: "15px",
  left: "15px",
  opacity: 0,
};

const labelStyle: React.CSSProperties = {
  marginTop: "3px",
  fontSize: "0.25rem",
  backdropFilter: "blur(10px) saturate(70%)",
  borderRadius: "0.5rem",
  textAlign: "center",
  display: "block",
};

const nodeStyle: React.CSSProperties = {
  zIndex: 2,
  borderRadius: 15,
  height: 30,
  width: 30,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  border: "1px solid",
};

const GraphNode = ({ data }: NodeProps) => {
  const graphNodeStyle = useMemo(
    () => ({
      ...nodeStyle,
      backgroundColor: "#ffffff",
      borderColor: data.instanceType
        ? "#4c6ef5"
        : nodeBorderColorByRisk[data.riskLevel],
      borderWidth: 1,
      boxShadow: data.instanceType
        ? "0 0 8px 0 rgba(79, 132, 176, 0.2)"
        : nodeShadowByRisk[0],
    }),
    [data.instanceType, data.riskLevel]
  );

  return (
    <div style={{ ...itemStyle }}>
      <Indicator
        size={9}
        withBorder
        offset={4}
        color="indigo.6"
        disabled={
          data.viewDataFlow &&
          data.classifiedFields &&
          data.classifiedFields.filter((f: string) => {
            return f.includes("::PII") || f.includes("::Sensitive");
          }).length > 0
            ? // ? false
              true
            : true
        }
      >
        <div style={graphNodeStyle}>
          <SfIntegrationLogo integration={data!} width={15} height={15} />
          <Handle style={handleStyle} type="target" position={Position.Top} />
          <Handle
            style={handleStyle}
            type="source"
            position={Position.Bottom}
          />
        </div>
      </Indicator>
      {data?.label && <div style={labelStyle}>{data.label} </div>}
    </div>
  );
};

export default memo(GraphNode);
